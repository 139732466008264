import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

export type TabIndex = {
    maxHorisontal: number;
    maxVertical: number;
    horisontalTabIndex: number;
    verticalTabIndex: number;
    fteRow: boolean;
    tabGridDem: number[]
}

const initialState: TabIndex = {
    maxHorisontal: 0,
    maxVertical: 0,
    horisontalTabIndex: -1,
    verticalTabIndex: -1,
    fteRow: true,
    tabGridDem: [],
}

export const TabIndexSlice = createSlice({
    name: "tabIndex",
    initialState: initialState,
    reducers: {
        maxHorisontalChanged: (state, action: PayloadAction<number>) => {
            state.maxHorisontal = action.payload;
            initIndexArray(state.tabGridDem, state.maxVertical, state.maxHorisontal);
        },
        maxVerticalChanged: (state, action: PayloadAction<{ totalRows: number, fteRows: number }>) => {
            state.maxVertical = action.payload.totalRows;
            initIndexArray(state.tabGridDem, state.maxVertical, state.maxHorisontal);
        },
        tabIndexChanged: (state, action: PayloadAction<{ horisontalIndex: number, verticalIndex: number }>) => {
            setNewIndex(state, action.payload.horisontalIndex, action.payload.verticalIndex);
        },
        tabPressed: (state, action: PayloadAction<{forward : boolean}>) => {
            const newHIndex = action.payload.forward ? state.horisontalTabIndex + 1 : state.horisontalTabIndex - 1;
            setNewIndex(state, newHIndex, state.verticalTabIndex);
        },
        enterPressed: (state, action: PayloadAction<{down : boolean}>) => {
            const newVIndex = action.payload.down ? state.verticalTabIndex + 1 : state.verticalTabIndex - 1;
            setNewIndex(state, state.horisontalTabIndex, newVIndex);
        },

        setFteRow: (state, action: PayloadAction<{verticalIndex: number, isFteRow : boolean, numberOfVisibleFte: number}>) => {
            state.fteRow = action.payload.isFteRow;
            if(!action.payload.isFteRow) {
               var x = state.maxHorisontal - action.payload.numberOfVisibleFte;
                state.tabGridDem[action.payload.verticalIndex] = x;
            }
        }
    }
});

export const { tabIndexChanged, enterPressed, tabPressed, maxHorisontalChanged, maxVerticalChanged, setFteRow } = TabIndexSlice.actions;

export const selectTabIndex = (state: RootState): TabIndex => state.tabIndex;

export default TabIndexSlice.reducer;

const setNewIndex = (state: TabIndex, hIndex : number, vIndex : number) => {
    if(hIndex === -1) {
        state.horisontalTabIndex = -1;
        return;
    }
    var number = state.tabGridDem[vIndex];

    if (hIndex > number) {
        if (state.verticalTabIndex < state.maxVertical) { // reset horizontal as we are directing to a new line
            state.horisontalTabIndex = 0;
            state.verticalTabIndex = state.verticalTabIndex + 1;
        }
        return;
    }

    if (hIndex < 0) {
        if (state.verticalTabIndex > 0) { // set to max horizontal as we are directing to previous line
            state.horisontalTabIndex = number;
            state.verticalTabIndex = state.verticalTabIndex - 1;
        }
        return;
    }

    if (vIndex <= state.maxVertical && vIndex >= 0) {
        state.verticalTabIndex = vIndex;
    }

    state.horisontalTabIndex = hIndex;
}

const initIndexArray = (array: number[], vertical: number, horizontal: number) => {
    for (let i = 0; i < vertical; i++) {
        array[i] = horizontal;
    }
}
