import {useAppDispatch} from "app/hooks";
import { DropdownOption } from "core/dropdown/dropdown";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import { Icon } from "../icon/icon";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import {usePopper} from "react-popper";
import {useSelector} from "react-redux";
import {RootState} from "../../app/store";
import {
  GrantConstraints,
  selectGrantConstraints
} from "../../pages/dashboard/components/Grid/components/grantConstraintSlice";
import {selectTabIndex, TabIndex} from "../../infrastructure/tabIndexSlice";
import {Subcategory} from "../../services/api/responseModels/budgetPeriodResponseModel";
import {
  selectAllSubcategories,
  SubcategoryState,
  subcategoryState
} from "../../pages/dashboard/components/Grid/components/subcategorySlice";

export interface IDropdownButtonProps {
    id: string;
    btnText: string;
    btnStyling: 'btn-primary' | 'btn-default' | 'btn-link' | 'btn-success' | 'btn-warning' | 'btn-danger' | 'btn-icon';
    keepOpenOnOptionClick?: boolean
    icon?: ReactNode;
    iconRight?: boolean;
    options?: DropdownOption[];
    optionsContent?: ReactNode;
    classes?: string;
    disabled?: boolean;
    onItemClick?: (selectedKey: string) => void;
}

export const DropdownButton = (props: IDropdownButtonProps) => {
    const dispatch = useAppDispatch();
    const [isOpen, setOpen] = useState(false);
    const [referenceEl, setReferenceEl] = useState<HTMLButtonElement | null>();
    const { isMultiline } = useSelector<RootState>(state => selectGrantConstraints(state)) as GrantConstraints;
    const allSubcategories = useSelector<RootState>(state => selectAllSubcategories(state.subcategories)) as SubcategoryState[];
    const [dropdownRef, setdropdownRef] = useState< HTMLDivElement  | null>();

    useOnClickOutside(dropdownRef, () => {
        setOpen(false);
      });

    const setOpenConditionally = () => {
      if (!props.keepOpenOnOptionClick) {
        setOpen(false);
      }
    }

    const { styles: popperStyles, attributes,update } = usePopper(referenceEl, dropdownRef, {
      placement: "bottom-start",
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 0],
          },
        },
      ],
    });
    const classes = isOpen ? "show" : "";
    const iconRight = props.iconRight ?? true;
    const icon = props.icon ?? <Icon icon="arrow-down" />;

    if(props.options === null && props.onItemClick === undefined) {
      throw Error();
    }

    useEffect(() => {
      update && update()
    }, [update, isMultiline, dispatch, allSubcategories])

    return (
        <div className={`btn-group ${classes} ${props.classes}`}>
          <span>
            <button
              ref={setReferenceEl}
              id="dropdown-menu"
              type={"button"}
              tabIndex={-1}
              className={`btn ${props.btnStyling} dropdown-toggle`}
              aria-haspopup="true"
              aria-expanded={isOpen}
              aria-label="Dropdown menu"
              disabled={props.disabled}
              onClick={() => setOpen(true) }
            >
                {!iconRight &&
                  <>
                    {icon}
                    <span className={"margin-right-m"}>
                                {props.btnText}
                            </span>
                  </>
                }

              {iconRight &&
                <>
                  {props.btnText}
                  {icon}
                </>
              }
            </button>
          </span>

          {
            ReactDOM.createPortal((
              <div ref={setdropdownRef} onClick={() => setOpenConditionally()} className={`dropdown-menu ${classes}`} aria-labelledby="dropdown-menu" style={popperStyles.popper}
                   {...attributes.popper}>
                {props.optionsContent && !props.disabled &&
                  props.optionsContent
                }

                {props.options &&
                  props.options.map((o,index) => (<button className={`dropdown-item`} key={`dropdown-btn-${o.key}+${index}`} onClick={(e) => {
                    e.preventDefault();
                    if(props.onItemClick !== null && props.onItemClick !== undefined) {
                      props.onItemClick(o.key);
                      setOpenConditionally();
                    }
                  }
                  }>{o.value}</button>))}

              </div>
            ), document.body)
          }
        </div>
    )
}
