import sprite from 'content/icons/sprite.symbol.svg'
import {forwardRef} from "react";

export interface IconProps {
    icon: string;
    extraClasses?: string;
}

export const Icon = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
    return <svg ref={ref} className={"embla-icon " + props.extraClasses} version="1.1" xmlns="http://www.w3.org/2000/svg" tabIndex={-1}>
        <use href={sprite + "#" + props.icon}/>
    </svg>
});
export default Icon;
