import React, { useRef } from "react";
import { Localizer } from "infrastructure/localization/localizer";
import {
  BudgetType,
  Subcategory,
  ValueResponseModel
} from "services/api/responseModels/budgetPeriodResponseModel";
import { RootState } from "app/store";
import { useSelector } from "react-redux";
import { selectAllCells } from "./cellSlice";
import { selectShownSubcategories } from "./subcategorySlice";
import {
  selectAllYears,
  selectShownYears,
  selectSingleFinancialReportYearId,
  YearState
} from "./yearSlice";
import CellContainer from "./cellContainer";
import {
  GrantConstraints,
  selectGrantConstraints,
  showSingleFinancialReportColumn,
  showTotalColumn
} from "./grantConstraintSlice";
import { formatWithThousandSeperators } from "infrastructure/extensions/numberFormatter";
import { InstitutionPill } from "./institutionPill";
import classNames from "classnames";

interface ProjectSupplementProps {
  currency: string;
  subcategories: Subcategory[];
}

export function ProjectSupplement(props: ProjectSupplementProps) {
  const textElementRef = useRef<HTMLDivElement>(null);
  const { isMultiline, institutionCollapsed } = useSelector<RootState>(
    state => selectGrantConstraints(state)
  ) as GrantConstraints;
  const cells = useSelector<RootState>(
    state => selectAllCells(state.cells)
  ) as ValueResponseModel[];
  const shownYears = useSelector<RootState>(
    state => selectShownYears(state.years)
  ) as YearState[];
  const showSingleFinancialReport = useSelector<RootState>(
    state => showSingleFinancialReportColumn(state)
  ) as boolean;


  const amountOfDistinctCategories = props.subcategories
    .map(s => s.category.name)
    .filter((value, index, self) => self.indexOf(value) === index).length;

  const categoryNumber = amountOfDistinctCategories + 1;

  const yearsToCalculateFrom = useSelector<RootState>(state => {
    if (showSingleFinancialReport) {
      return selectAllYears(state.years).filter(y => !y.entity.removed);
    } else {
      return selectShownYears(state.years).filter(y => !y.entity.removed);
    }
  }) as YearState[];

  const { budgetType } = useSelector<RootState>(
    state => selectGrantConstraints(state)
  ) as GrantConstraints;

  const showTotal = useSelector<RootState>(
    state => showTotalColumn(state)
  ) as boolean;

  const singleFinancialReportYearId = useSelector<RootState>(
    state => selectSingleFinancialReportYearId(state)
  ) as string;


  const allSumProjectSupplementPerYear = React.useMemo(
    () =>
      yearsToCalculateFrom.flatMap(year =>
        year.columns.flatMap(column => ({
          yearId: year.entity.id,
          type: column.type,
          sum: getSumProjectSupplementPerYear(
            year.entity.id,
            column.type,
            props.subcategories
          )
        }))
      ),
    [yearsToCalculateFrom, props.subcategories]
  );

  // console.log('allSumProjectSupplementPerYear', allSumProjectSupplementPerYear)

  const totalSum = React.useMemo(
    () =>
      allSumProjectSupplementPerYear
        .filter(ps => ps.type === budgetType)
        .map(sum => sum.sum)
        .reduce((a, b) => a + b, 0),
    [allSumProjectSupplementPerYear, budgetType]
  );

  const { isReadonly } = useSelector<RootState>(
    state => selectGrantConstraints(state)
  ) as GrantConstraints;

  return (
    <>
      <div className="category-row-cell border-bottom-left sidebar-element align-items-center justify-content-between category-header-background-color sidebar-category-header-title">
        <h5
          ref={textElementRef}
          className={classNames(
            "padding-left-xs",
            isMultiline ? "" : "single-line-sidebar-header-style"
          )}
        >
          {`${categoryNumber}. ${Localizer.projectSupplement()}`}
        </h5>
      </div>

      <div className="category-row-cell border-bottom-left summation-sidebar-custom-row category-header-background-color" />

      <div className="category-row-cell border-bottom-left summation-sidebar-custom-row category-header-background-color custom-categories-sidebar-element">
        {props.subcategories[0].selectedInstitution && (
          <InstitutionPill text={props.subcategories[0].selectedInstitution.name} supplementRow={true} />
        )}
      </div>

      {shownYears.map(year => (
        <React.Fragment key={`summation-year-${year.entity.id}`}>
          {year.columns.map(column => {
            return (
              <React.Fragment
                key={`summation-year-cell-${year.entity.id}-${column.type}`}
              >
                <CellContainer summationCell={true} noFocus={true}>
                  {!column.budgetCollapsed && (
                    <div
                      className={`category-row-cell-content padding-right-m padding-xs content-align-right border-left category-header-background-color sum-cell ${
                        column.locked && "readonly"
                      }`}
                    >
                      {`${formatWithThousandSeperators(
                        allSumProjectSupplementPerYear.find(
                          sum =>
                            sum.yearId === year.entity.id && sum.type === column.type
                        )?.sum ?? 0
                      )}`}
                    </div>
                  )}
                </CellContainer>
                {column.showDifferenceColumn && (
                  <div className="category-row-cell border-bottom-left summation-sidebar-custom-row category-header-background-color" />
                )}
                {column.showFulltimeEquivalentColumn && (
                  <div className="category-row-cell border-bottom-left summation-sidebar-custom-row category-header-background-color" />
                )}
                <div className="category-row-cell border-bottom-left summation-sidebar-custom-row category-header-background-color" />
              </React.Fragment>
            );
          })}
        </React.Fragment>
      ))}

      {showTotal && (
        <CellContainer noFocus={true}>
          <div className="category-row-cell-content padding-right-m padding-xs content-align-right border-left category-header-background-color sum-cell readonly">
            {formatWithThousandSeperators(totalSum)} {props.currency}
          </div>
        </CellContainer>
      )}

      {showSingleFinancialReport && singleFinancialReportYearId && (
        <React.Fragment key={`summation-year-cell-${singleFinancialReportYearId}-${BudgetType.financial}`}>
          <CellContainer summationCell={false} noFocus={true}>
            <div className="category-row-cell-content content-align-right padding-right-m padding-xs sum-cell readonly">
              <p>
                {`${formatWithThousandSeperators(
                  totalSum)}`}
              </p>
            </div>
          </CellContainer>
          <CellContainer summationCell={false} noFocus={true}>
            <div className="category-row-cell-content content-align-right padding-right-m padding-xs sum-cell readonly">
              {formatWithThousandSeperators(
                totalSum -
                totalSum      )}
            </div>
          </CellContainer>
          <CellContainer summationCell={false} />
        </React.Fragment>
      )}
    </>
  );
}

export default ProjectSupplement;

export const getSumProjectSupplementPerYear = (
  yearId: string,
  type: number,
  subcategories: Subcategory[]
): number => {
  const allSubCategoryYears = subcategories.flatMap(subcategory =>
    subcategory.values.filter(
      (value, _, arr) => value.yearId === yearId && (arr.length === 1 || value.type === type)
    )
  );
  const sum = allSubCategoryYears.reduce((total, value) => {
    const supplement = value.projectSupplementAmount ?? 0;
    return total + supplement;
  }, 0);
  return sum;
};
