import React from "react";
import { TopMenu } from "layout/topMenu/topMenu";
import { Localizer } from "infrastructure/localization/localizer";
import { NotificationModule } from "ditmer-embla";
import { BrowserEvents } from "../browserEvents";

const showErrorToast = () => {
	NotificationModule.showError(Localizer.errorOccuredTitle(), Localizer.errorOccured());
};

// Async error handler
window.addEventListener(BrowserEvents.Unhandledrejection, function (event) {
	showErrorToast();
});

export class ErrorBoundary extends React.Component<any, { hasError: boolean }> {
	constructor(props: any) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error: any) {
		return { hasError: true };
	}

	componentDidUpdate(prevProps: any, prevState: any) {
		if (this.state.hasError && !prevState.hasError) {
			showErrorToast();
		}
	}

	componentDidMount() {
		if (this.state.hasError) {
			showErrorToast();
		}
	}

	render() {
		if (this.state.hasError) {
			return (
				<div className={"body-container"}>
					<TopMenu showActionButtons={false} projectSupplementEnabled={false} supplementChoiceEnabled={false} />

					<div className="page-content">
						<div className="main-content">
							<div className="body-content">
								<h1>{Localizer.errorOccured()}</h1>
							</div>
						</div>
					</div>
				</div>
			);
		}

		return this.props.children;
	}
}
