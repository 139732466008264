import {createEntityAdapter, createSlice, EntityState, PayloadAction} from "@reduxjs/toolkit";
import {Category} from "../../../../../services/api/responseModels/budgetPeriodResponseModel";
import {RootState} from "../../../../../app/store";

type CategoryState = {
    id: string;
    show: boolean;
}

const categoryAdapter = createEntityAdapter({
    selectId: (category: CategoryState) => category.id
})

const initialState = {
    isAllShown: true,
    categories: categoryAdapter.getInitialState(),
};

export const categorySlice = createSlice({
    name: "categories",
    initialState: initialState,
    reducers: {
        setCategories: (state, action: PayloadAction<Category[]>) => {
            action.payload.map((category) => {
                categoryAdapter.upsertOne(state.categories, {
                    id: category.id,
                    show: true
                });
            })

            state.isAllShown = true;
        },
        showCategory: (state, action: PayloadAction<CategoryState>) => {
            categoryAdapter.upsertOne(state.categories, {
                id: action.payload.id,
                show: action.payload.show
            });

            if (!action.payload.show)
                state.isAllShown = false;

            const categories = categoryAdapter.getSelectors().selectAll(state.categories);
            state.isAllShown = categories.every((c) => c.show);
        },

        showAllCategories: (state, action: PayloadAction<{show: boolean}>) => {
            const ids = state.categories.ids;

            const categories = ids.map((id) => ({
                id: id,
                changes: {
                    show: action.payload.show
                }
            }));

            categoryAdapter.updateMany(state.categories, categories);
            state.isAllShown = action.payload.show;
        },
    }
});

export const { showCategory, setCategories, showAllCategories } = categorySlice.actions;

const { selectById: selectCategory } = categoryAdapter.getSelectors();

export const isAllShownSelector = (state: RootState) => state.categories.isAllShown;

export const selectShowCategory = (state: EntityState<CategoryState, string>, categoryId: string) => {
    const category = selectCategory(state, categoryId);

    if (!category)
        return true;

    return category.show;
}

export default categorySlice.reducer;
