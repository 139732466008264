
export const IsSalaryCategory = (name: string) => name === "Salary";
export const IsProjectSupplementCategory = (name: string) => name === "Project Supplement";
export const IsCostCategory = (name: string) => name !== "Administration";
export const DirectAdministrativeExpenseCategory = "Direct administrative expenses";
export const IndirectAdministrativeExpenseCategory = "Indirect administrative expenses";
export const IsNotAdministrativeSubcategory = (name: string) => name !== DirectAdministrativeExpenseCategory && name !== IndirectAdministrativeExpenseCategory;

export const IsAdministrativeSubcategory = (name: string) => name === DirectAdministrativeExpenseCategory || name === IndirectAdministrativeExpenseCategory;


export const SalaryCategory = "Salary";
export const OperationCategory = "Operation";
export const AdministrationCategory = "Administration";

export const ProjectSupplementCategory = "Project Supplement";
export const DisseminationCategory = "Dissemination, training, education";
