import { HttpClientService } from "services/httpClient/httpClientService";
import { ExcelDownloadRequestModel } from "services/api/requestModels/ExcelDownloadRequestModel";
import config from "config/config";

export class ApiService  {
    private httpClientService : HttpClientService = new HttpClientService();
    private getLocalizationsUrl = config.api_url + "/api/grant/localization";
    private getDownloadExcelFile = config.api_url + "/api/export/exportExcel";

    public async getLocalizations(): Promise<{ [key: string]: string }> {
        var localizedTexts = this.httpClientService.get<{key: string, text: string}[]>(this.getLocalizationsUrl);

        return localizedTexts.then(x => {
            const dictionary = {} as any;
            x.forEach(entry => dictionary[entry.key] = entry.text)
            return dictionary;
        });
    }

    public async getExcel(filename: string, excelDownloadRequestModel: ExcelDownloadRequestModel) {
        this.httpClientService.DownloadExcelFileWithName(this.getDownloadExcelFile, filename, excelDownloadRequestModel);
    }
}
