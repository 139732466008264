import {UserResponseModel} from "services/api/responseModels/userResonseModel";
import { Action } from "./enums/action";
import { EditState } from "./enums/editState";
import { UserRole } from "./enums/userRole";

export class UserModel {

    constructor() {
        this.authenticated = false;
    }

    public authenticated : boolean;
    public userId: string;
    public grantId: string;
    public externalApplicationId: string;
    public recordId: string;
    public action: Action;
    public editState: EditState;
    public userRole: UserRole;
}

export const createUserFromLogin = (userResponseModel: UserResponseModel): UserModel => {
    let newUserModel = new UserModel();
    newUserModel.authenticated = true;
    newUserModel.userId = userResponseModel.userId;
    newUserModel.grantId = userResponseModel.grantId;
    newUserModel.externalApplicationId = userResponseModel.externalApplicationId;
    newUserModel.recordId = userResponseModel.recordId;
    newUserModel.action = userResponseModel.action
    newUserModel.editState = userResponseModel.editState;
    newUserModel.userRole = userResponseModel.userRole;
    return newUserModel;
};
