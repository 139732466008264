export interface IButtonProps {
    id: string;
    type: 'submit' | 'reset' | 'button';
    styling: 'btn-primary' | 'btn-default' | 'btn-link' | 'btn-success' | 'btn-warning' | 'btn-danger' | 'btn-icon';
    classes?: string;
    disabled?: boolean;
    onClick?: () => void;
}

export const Button = (props: React.PropsWithChildren<IButtonProps>) => {
    return (
        <button
            id={props.id}
            className={`btn ${props.styling} ${props.classes}`}
            type={props.type}
            disabled={props.disabled}
            onClick={props.onClick}
        >
             {props.children}
        </button>
    )
}
export default Button;
