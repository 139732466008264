import { Localizer } from 'infrastructure/localization/localizer';
import React, { Component, PropsWithChildren } from 'react';
import { loadConfig } from "./config";

interface ConfigLoaderProps {
  loading?: () => React.ReactNode;
}

interface ConfigLoaderState {
  isLoaded: boolean;
  config?: any;
}

export default class ConfigLoader extends Component<PropsWithChildren<ConfigLoaderProps>, ConfigLoaderState> {
  constructor(props: ConfigLoaderProps) {
    super(props);
    this.state = {
      isLoaded: false
    };
  }


  componentDidMount() {
    // Once the configuration is loaded set `isLoaded` to true so we know to render our component
    loadConfig()
      .then(config => this.setState({
        isLoaded: true,
        config
      })).then(() => {
        Localizer.Init();
      });
  }

  render() {
    // If we haven't yet loaded the config, show either a "splash" component provided via a `loading` props or return nothing.
    if (!this.state.isLoaded) {
      return this.props.loading ? this.props.loading() : null;
    }

    // The config is loaded so show the component set on the `ready()` props
    return this.props.children;
  }
}
