import { emptyApi } from "./emptyApi";
import { UserResponseModel } from "./responseModels/userResonseModel";

export const userApi = emptyApi.injectEndpoints({
    endpoints: (builder) => ({
      getUser: builder.query<UserResponseModel, string | null>({
        query: () => 'api/user/getInfo',
      })
    })
})
  
export const { useGetUserQuery, useLazyGetUserQuery } = userApi