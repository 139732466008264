import './App.css';
import MainRouting from "./infrastructure/routing/mainRouting";
import ConfigLoader from "./config/ConfigLoader";
import "ditmer-embla";

function App() {
    return (
        <ConfigLoader>
            <MainRouting />
        </ConfigLoader>
    );
}

export default App;
