import { useEffect } from "react";
import { useAppDispatch } from "app/hooks";
import { newBreadcrumb, setStatus, setTitle } from "layout/titleBar/titlebarSlice";
import { Localizer } from "infrastructure/localization/localizer";
import { useAuth } from "infrastructure/routing/auth";
import {
	useGetBudgetPeriodByIdQuery,
	useGetBudgetPeriodIdByExternalApplicationIdQuery
} from "services/api/budgetPeriodApi";
import {
	BudgetPeriodResponseModel,
	BudgetType,
	Category, Institution,
	Subcategory,
	ValueResponseModel
} from "services/api/responseModels/budgetPeriodResponseModel";
import { Status, statusTranslator } from "models/enums/status";
import { Grid } from "./components/Grid/grid";
import {
	addCells,
	differenceBetweenYears,
	findComparerType,
	isDescriptionRequired,
	isMaxDeviationExceeded
} from "./components/Grid/components/cellSlice";
import { setSubcategories } from "./components/Grid/components/subcategorySlice";
import { setCategories } from "./components/Grid/components/categorySlice";
import { EditState } from "models/enums/editState";
import { filterTypeForYear, setYears, showDifferenceColumn } from "./components/Grid/components/yearSlice";
import {
	constraintsUpdated,
	GrantConstraints,
	selectGrantConstraints
} from "./components/Grid/components/grantConstraintSlice";
import { Action } from "models/enums/action";
import { RootState } from "app/store";
import { useSelector } from "react-redux";
import { tagsAdded } from "./components/Grid/components/tagSlice";
import { institutionsAdded } from "./components/Grid/components/institutionSlice";
import { IsSalaryCategory } from "infrastructure/extensions/categoryNameAsserter";
import { enterPressed, tabPressed } from "infrastructure/tabIndexSlice";
import { ChangeLog } from "./components/ChangeLog/changeLog";
import { BrowserEvents } from "../../infrastructure/browserEvents";
import { setIsCurrentVersion, VersionState } from "./components/Grid/components/versionSlice";
import { BudgetPeriodVersionModel } from "../../services/api/responseModels/budgetPeriodVersionModel";
import { Spinner } from "../../core/spinner/spinner";

export const DashboardPage = () => {
	const { budgetPeriodId } = useSelector<RootState>(state => selectGrantConstraints(state)) as GrantConstraints;
	const { selectedVersion, isCurrentVersion } = useSelector<RootState>(state => state.versionSlice) as VersionState;
	const dispatch = useAppDispatch();
	const auth = useAuth();

	useEffect(() => {
		const onEnter = (ev: KeyboardEvent) => {
			if (ev.key === "ArrowDown" || ev.key === "ArrowUp") {
				ev.preventDefault();
				const goDown = ev.key === "ArrowDown";
				dispatch(enterPressed({ down: goDown }));
			} else if (ev.key === "Tab" || ev.key === "Enter") {
				ev.preventDefault();
				const goForard = ev.key === "Enter" || !ev.shiftKey;
				dispatch(tabPressed({ forward: goForard }));
			}
		};
		window.addEventListener(BrowserEvents.Keydown, onEnter, false);
		return () => {
			window.addEventListener(BrowserEvents.Keydown, onEnter, false);
		};
	}, []);

	useEffect(() => {
		dispatch(newBreadcrumb({ text: Localizer.projects(), url: "" }));
	}, [dispatch]);

	const externalApplicationId = auth.user?.externalApplicationId as string;
	const { data: latestBudgetPeriodId } = useGetBudgetPeriodIdByExternalApplicationIdQuery(externalApplicationId);

	const budgetPeriodIdToRetrieve = budgetPeriodId ? budgetPeriodId : latestBudgetPeriodId;
	const { data: budgetPeriod, isLoading, isFetching } = useGetBudgetPeriodByIdQuery({
		budgetPeriodId: budgetPeriodIdToRetrieve as string,
		order: selectedVersion?.order.toString() ?? ""
	}, {
		skip: !budgetPeriodIdToRetrieve
	});

	InitialiseBudget(budgetPeriod, auth.user?.editState, auth.user?.action, auth.user?.recordId, isCurrentVersion, selectedVersion);

	return (
		!isFetching && budgetPeriod ? <>
			<div id="modal-container"></div>
			<ChangeLog budgetPeriodId={budgetPeriod.id} />
			<Grid budgetPeriod={budgetPeriod} />
		</> : <><Spinner size={"small"} /></>
	);
}

const InitialiseBudget = (budgetPeriod: BudgetPeriodResponseModel | undefined, editState: EditState | undefined, action: Action | undefined, authRecordId: string | undefined, isCurrentVersion = true, selectedVersion: BudgetPeriodVersionModel) => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (budgetPeriod)
			dispatch(setIsCurrentVersion(budgetPeriod?.isLatestVersion))
	}, [budgetPeriod])


	useEffect(() => {
		if (!budgetPeriod || !editState || !action) return;


		const columnTypes: BudgetType[] = getColumnTypes(budgetPeriod);
		const initialCells = getInitialCells(budgetPeriod, columnTypes, budgetPeriod.subCategoryMaxDeviation, action, isCurrentVersion, selectedVersion);
		const isReadonly = !budgetPeriod.isLatestVersion || (budgetPeriod.status === Status.Approved && action !== Action.FinancialReport) || editState !== EditState.Write;

		//var listOfInstitutions = new Array<Institution>();

		//listOfInstitutions.push({id: "0", subcategoryId: "0", name: "", FTE_Types: []});
		//listOfInstitutions.push({id: "1", subcategoryId: "1", name: "Danmarks Tekniske Universitet", FTE_Types: ["STEM", "SOC/HUM", "No Project Supplement"]});
		//listOfInstitutions.push({id: "2", subcategoryId: "2", name: "Aarhus Universitet", FTE_Types: ["STEM", "SOC/HUM", "No Project Supplement"]});
		//listOfInstitutions.push({id: "3", subcategoryId: "3", name: "Syddansk Universitet", FTE_Types: ["STEM", "SOC/HUM", "No Project Supplement"]});
		//listOfInstitutions.push({id: "4", subcategoryId: "4", name: "Statens Serum Institut", FTE_Types: []});

		//listOfInstitutions.forEach(institution => {
		//  institution.isUniversity = institution.FTE_Types.length > 0;
		//});

		const removeInstitution = !budgetPeriod.projectSupplementEnabled || budgetPeriod.institutions.length === 0;

		const subs = budgetPeriod.subCategories.map(sub => {
			let institution = sub.selectedInstitution;

			if (typeof institution === 'undefined' && budgetPeriod.institutions.length === 1 && budgetPeriod.projectSupplementEnabled) {
				institution = budgetPeriod.institutions[0];
			}

			return {
				...sub,
				selectedInstitution: institution,
			}
		});

		// TODO: Lav alle disse sets om til "init budgetPeriod" eller sådan noget som samler det hele... ?
		dispatch(setSubcategories(subs));
		dispatch(setCategories(getCategoriesFromSubcategories(budgetPeriod.subCategories)))
		dispatch(tagsAdded(budgetPeriod.subCategories.flatMap(sub => sub.tags.map(t => t))));
		//dispatch(institutionsAdded(listOfInstitutions));
		dispatch(setYears({
			years: budgetPeriod.years,
			columnTypes,
			isReadonly: isReadonly,
			closedFinancialYears: budgetPeriod.closedFinancialReportYears,
			actionEnum: action,
			financialReportType: budgetPeriod.financialReportType,
			financialReportYearId: budgetPeriod.financialReportYearId,
			isCurrentVersion: isCurrentVersion,
			selectedVersion: selectedVersion,
		}));
		dispatch(addCells(initialCells));
		dispatch(constraintsUpdated({
			isReadonly: isReadonly,
			budgetPeriodStatus: budgetPeriod.status,
			budgetPeriodId: budgetPeriod.id,
			budgetType: budgetPeriod.type,
			minAmount: budgetPeriod.minAmount,
			maxAmount: budgetPeriod.maxAmount,
			maxCategoryDeviation: budgetPeriod.categoryMaxDeviation,
			maxSubcategoryDeviation: budgetPeriod.subCategoryMaxDeviation,
			financialReportYearId: budgetPeriod.financialReportYearId,
			subcategoriesForGrant: budgetPeriod.availableSubCategories,
			customSubcategoriesCollapsed: true,
			institutionCollapsed: removeInstitution,
			maxDirectAdminExpense: budgetPeriod.maxDirectAdminExpense,
			maxIndirectAdminExpense: budgetPeriod.maxIndirectAdminExpense,
			financialReportType: budgetPeriod.financialReportType,
			actionEnum: action,
			isBallpark: budgetPeriod.isBallpark,
			isMultiline: false,
			isLatestVersion: budgetPeriod.isLatestVersion
		}));
		dispatch(setTitle(budgetPeriod.title));
		//dispatch(setTitle(budgetPeriod.mortenTest));
		dispatch(setStatus(statusTranslator(budgetPeriod.status)));
	}, [dispatch, budgetPeriod, editState]);
}

const getInitialCells = (budgetPeriod: BudgetPeriodResponseModel, columnTypes: BudgetType[], maxSubcategoryDeviation: number, actionEnum: Action, isCurrentVersion: boolean = true, selectedVersion: BudgetPeriodVersionModel) => {
	return budgetPeriod.years.flatMap(year => {
		return budgetPeriod.subCategories.flatMap(sub => {
			const cellForSubcategory = columnTypes.map(columnType => {
				const cell = sub.values.find(v => v.yearId === year.id && v.type === columnType);

				if (cell)
					return { ...cell, categoryId: sub.category.id, yearNumber: year.yearNumber, categoryNumber: sub.category.orderNumber, createdAt: Number(new Date()), commentRequired: isDescriptionRequired(cell.budget, cell.description, sub) }

				const newCell: ValueResponseModel = {
					subcategoryId: sub.id,
					yearId: year.id,
					type: columnType,
					budget: undefined,
					description: undefined,
					fullTimeEquivalent: undefined,
					commentRequired: false,
					fulltimeEquivalentRequired: undefined,
					yearNumber: year.yearNumber,
					categoryNumber: sub.category.orderNumber,
					hasIssue: false,
					projectSupplementAmount: undefined
				};

				return newCell;
			})
			const cellsToReturn = cellForSubcategory.map(cell => {
				const columnTypesShownForYear = columnTypes.filter(type => filterTypeForYear(year, type, year.yearNumber, year.id, budgetPeriod.closedFinancialReportYears, actionEnum, budgetPeriod.financialReportType, budgetPeriod.financialReportYearId, isCurrentVersion, selectedVersion));
				const comparerType = findComparerType(cell.type, columnTypesShownForYear);
				const { differenceInPercent } = differenceBetweenYears(year.id, cell.type, comparerType, cellForSubcategory, [sub]);
				let newCell = cell;

				if (differenceInPercent) {
					if (maxSubcategoryDeviation) {
						const hasIssue = isMaxDeviationExceeded(differenceInPercent, maxSubcategoryDeviation, showDifferenceColumn(cell.type, columnTypesShownForYear));
						newCell = { ...newCell, hasIssue: hasIssue }
					}
					else if (cell.type === BudgetType.financial && differenceInPercent !== 0) {
						newCell = { ...newCell, hasIssue: true }
					}
				}

				if (IsSalaryCategory(sub.category.name)) {
					newCell = { ...newCell, fulltimeEquivalentRequired: typeof cell.fullTimeEquivalent === 'undefined' && cell.type === BudgetType.budget && cell.budget !== undefined && cell.budget !== null }
				}

				return newCell;
			});

			return cellsToReturn;
		});
	});
}

const getColumnTypes = (budgetPeriod?: BudgetPeriodResponseModel) => {
	//Types will be set in FilterManager.cs
	let columnTypes: BudgetType[] = [BudgetType.budget, BudgetType.financial, BudgetType.revision]; //This is just a fail safe
	if (budgetPeriod && budgetPeriod?.typesToPesentInBudget !== null) {
		columnTypes = budgetPeriod?.typesToPesentInBudget;
	}

	return columnTypes;
}

export const getCategoriesFromSubcategories = (subcategories: Subcategory[]): Category[] => {
	const categories: Category[] = [];

	subcategories.forEach((subcategory: Subcategory) => {
		const category = categories.find(c => c.name === subcategory.category.name);
		if (!category) {
			categories.push({
				id: subcategory.category.id,
				name: subcategory.category.name,
				subcategories: [subcategory],
				orderNumber: subcategory.category.orderNumber
			})
		} else {
			category.subcategories.push(subcategory);
		}
	});

	return categories;
}
