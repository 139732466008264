import { useToggle } from "app/hooks";
import Button from "core/button/button";
import Icon from "core/icon/icon";
import Modal from "core/modal/modal";
import { Localizer } from "infrastructure/localization/localizer";
import { useGetChangeLogByBudgetPeriodIdQuery } from "services/api/budgetPeriodApi";
import React, { useEffect } from "react";
import "./changeLog.scss";
import { DateParser } from "infrastructure/localization/dateParser";

interface IChangeLogProps {
    budgetPeriodId: string;
}

export function ChangeLog(props: IChangeLogProps){
    const [showModal, toggleChangeLogModal] = useToggle(false);
    const {data: changeLogData, refetch: refetchChangelog } = useGetChangeLogByBudgetPeriodIdQuery(props.budgetPeriodId, { skip: !showModal });

    useEffect(() => {
        if(showModal) {
            refetchChangelog();
        }
    }, [showModal, refetchChangelog])

    const renderChangelogData = () => {
        if(!changeLogData || changeLogData.length === 0) return <tr><td>{Localizer.noChangeLogsMessage()}</td></tr>
        return changeLogData?.map(log =>
            {
                var params = log.messageParameters.split(";; ");
                return  <React.Fragment key={log.id}>
                            <tr className="changelog-row">
                                <td className="created-at">{DateParser.toLocale(log.createdAt)}</td>
                                <td className="created-by">{log.createdByUserName}</td>
                            </tr>
                            <tr className="changelog-field-row">
                                <td className="changelog-data">{formatString(Localizer.translations[log.messageKey], params)}</td>
                            </tr>
                        </React.Fragment>
            }
        )
    }

    const formatString = (text: string, params: string[]) => {
        return text.split(/{(\d+)}/g).map((x, index) => {
            if(!isNaN(parseInt(x))) {
                return <b key={index + x}>{params[parseInt(x)]}</b>
            } else {
                return <span key={index + x}>{x}</span>
            }
        })
    };

    return (
        <>
        <div className="changelog-toggle">
            <Button
                id={"open-changelog-btn"}
                type={'button'}
                styling={'btn-default'}
                onClick={toggleChangeLogModal}
                classes={"btn-sm sidenav-trigger"}
                >
                    <Icon icon="list"/>
                    <span className="hover-text">
                        {Localizer.changeLogTitle()}
                    </span>
            </Button>
        </div>
        <Modal
            id={"changelog-modal"}
            title={Localizer.changeLogTitle()}
            showModal={showModal}
            toggleModal={toggleChangeLogModal}
            size={"modal-lg"}
            disableOk={true}
            >
                <table className="table changelog-table">
                    <tbody className="changelog-content">
                        {renderChangelogData()}
                    </tbody>
                </table>
        </Modal>
        </>
    )
}

export default ChangeLog;
