import React, {useRef} from "react";
import {Category, Institution} from "services/api/responseModels/budgetPeriodResponseModel";
import {useAppSelector} from "app/hooks";
import { filterState } from "pages/dashboard/components/Filter/filterSlice";
import { RootState } from "app/store";
import { useSelector } from "react-redux";
import { selectShownYears, YearState } from "./yearSlice";
import CellContainer from "./cellContainer";
import AddSubcategoryBtn from "./addSubcategoryBtn";
import {
  GrantConstraints,
  selectGrantConstraints,
  showSingleFinancialReportColumn,
  showTotalColumn
} from "./grantConstraintSlice";
import {Tooltip} from "core/tooltip/tooltip";
import {useTextOverflowHook} from "hooks/useTextOverflowHook";
import classNames from "classnames";

interface AvailableCategoryRowProps {
  category: Category;
  categoryNumber: number;
  currency: string;
  institutions: Institution[];
}

export function AvailableCategoryRow(props: AvailableCategoryRowProps) {
  const selectedTags = useAppSelector(filterState).selectedTags;
  const shownYears = useSelector<RootState>(state => selectShownYears(state.years)) as YearState[];
  const showTotal = useSelector<RootState>(state => showTotalColumn(state)) as boolean;
  const showSingleFinancialReport = useSelector<RootState>(state => showSingleFinancialReportColumn(state)) as boolean;
  const textElementRef = useRef<HTMLDivElement>(null);
  const isOverflow = useTextOverflowHook(textElementRef);
  const { isMultiline, institutionCollapsed } = useSelector<RootState>(state => selectGrantConstraints(state)) as GrantConstraints;

  if (selectedTags.length > 0)
    return <></>

  const renderEmptySumCell = () => showTotal && <CellContainer />
  const renderEmptySingleFinancialReportCell = () => showSingleFinancialReport && (
    <>
      <CellContainer />
      <CellContainer />
      <CellContainer />
    </>
  )

  return (
    <>
      <div
        className="category-row-cell border-bottom-left sidebar-element align-items-center justify-content-between category-header-background-color sidebar-category-header-titel">
        {isOverflow && !isMultiline ?
          <Tooltip message={`${props.categoryNumber}. ${props.category.name}`} ref={textElementRef}>
            <h5 ref={textElementRef}
                className={classNames("padding-left-xs", isMultiline ? "" : "single-line-sidebar-header-style")}>{`${props.categoryNumber}. ${props.category.name}`}</h5>
          </Tooltip>
          :
          <h5 ref={textElementRef}
              className={classNames("padding-left-xs", isMultiline ? "" : "single-line-sidebar-header-style")}>{`${props.categoryNumber}. ${props.category.name}`}</h5>
            }

        <AddSubcategoryBtn categoryNumber={props.categoryNumber} categoryId={props.category.id} institutions={props.institutions}/>
      </div>

      <div className="category-row-cell padding-xs pl-3 border-bottom-left category-header-background-color"/>
      <div className="category-row-cell padding-xs pl-3 border-bottom-left category-header-background-color"/>

      {shownYears.map(year =>
        <React.Fragment key={`category-row-year-cell-${props.category.id}-${year.entity.id}`}>
          {year.columns.map(column => (
            <React.Fragment key={`category-row-year-cell-${props.category.id}-${year.entity.id}-${column.type}`}>
              <CellContainer noFocus={true}>
                <div
                  className={`category-row-cell-content content-align-right sum-cell ${column.locked && "readonly"}`}>

                </div>
              </CellContainer>
              <CellContainer/>
              {column.showDifferenceColumn &&
                <CellContainer/>
              }
              {column.showFulltimeEquivalentColumn &&
                <CellContainer/>
              }
            </React.Fragment>
          ))}
        </React.Fragment>
      )}

      {renderEmptySumCell()}
      {renderEmptySingleFinancialReportCell()}
    </>
  );
}

export default AvailableCategoryRow;
