import {BudgetType, columnTitleTranslator, Institution, Year} from "services/api/responseModels/budgetPeriodResponseModel";
import Checkbox from "../checkbox/checkbox";
import React, {useEffect} from "react";
import {
  filterState,
  selectedYears,
  setHasYearFiltersApplied,
  setSelectedYear,
  setSelectedYears,
  setShowBudgetsOnly,
} from "../../pages/dashboard/components/Filter/filterSlice";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {useSelector} from "react-redux";
import {RootState} from "../../app/store";
import {
  GrantConstraints,
  selectGrantConstraints,
  institutionColumnToggled,
} from "../../pages/dashboard/components/Grid/components/grantConstraintSlice";
import {Localizer} from "../../infrastructure/localization/localizer";
import {FinancialReportType} from "../../models/enums/financialReportType";
import {
  collapseStateUpdated,
  ColumnTarget,
  YearState
} from "../../pages/dashboard/components/Grid/components/yearSlice";
import {VersionState} from "../../pages/dashboard/components/Grid/components/versionSlice";
import {tabIndexChanged} from "../../infrastructure/tabIndexSlice";
import {CellType} from "../../models/enums/cellType";

interface IYearButtonFilterProps {
  years: YearState[]
  institutions: Institution[];
}

export const YearColumnFilter = (props: IYearButtonFilterProps) => {
  const dispatch = useAppDispatch();
  const selectedYearIds = useSelector<RootState>(state => selectedYears(state.filters)) as string[];
  const showOnlyBudget = useAppSelector(filterState).showBudgetsOnly;
  const { financialReportYearId, financialReportType, isReadonly, institutionCollapsed } = useSelector<RootState>(state => selectGrantConstraints(state)) as GrantConstraints;
  const { isApprovedText, selectedVersion } = useSelector<RootState>(state => state.versionSlice) as VersionState;
  const hasInstitution = props.institutions.length > 0;

  const toggleInstitutionColumn = () => {
    dispatch(institutionColumnToggled());
  }

  useEffect(() => {
    let filtersApplied = false;

    props.years.map((year) =>  {
      if (!year.shown) {
        filtersApplied = true;
        return;
      }

      year.columns.map((column) => {
        if (column.budgetCollapsed || column.descriptionCollapsed || column.differenceCollapsed || column.fulltimeEquivalentCollapsed) {
          filtersApplied = true;
          return;
        }
      });
    });

    dispatch(setHasYearFiltersApplied(filtersApplied));
  }, [props.years])

  const onYearCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, year: YearState) => {
    const yearId = year.entity.id;
    const yearSelected = e.target.checked;
    dispatch(setSelectedYear({ id: yearId, changes: { selected: yearSelected }}));

    // Toggle the year columns
    year.columns.map((column) => {
      onColumnCheckboxChange(e, yearId, column.type, ColumnTarget.budget, yearSelected);
      onColumnCheckboxChange(e, yearId, column.type, ColumnTarget.description, yearSelected);

      if (column.showDifferenceColumn)
        onColumnCheckboxChange(e, yearId, column.type, ColumnTarget.difference, yearSelected);

      if (column.showFulltimeEquivalentColumn)
        onColumnCheckboxChange(e, yearId, column.type, ColumnTarget.fulltimeEquivalent, yearSelected);
    })
  }

  const onColumnCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, yearId: string, type: BudgetType, columnsTarget: ColumnTarget,  show: boolean) => {
    dispatch(collapseStateUpdated({ yearId: yearId, type: type, columnTarget: columnsTarget, collapsed: !show }));

    // Make sure no cells are in focus
    dispatch(tabIndexChanged({ horisontalIndex: -1, verticalIndex: 0}));

    if (show)
      dispatch(setSelectedYear({ id: yearId, changes: { selected: true }}));
  }

  const showBudgetsOnly = () => {
    props.years.map((year) => {
      // Show year and budget
      dispatch(setSelectedYear({ id: year.entity.id, changes: { selected: true }}));
      dispatch(collapseStateUpdated({ yearId: year.entity.id, type: BudgetType.budget, columnTarget: ColumnTarget.budget, collapsed: false }));
      dispatch(collapseStateUpdated({ yearId: year.entity.id, type: BudgetType.revision, columnTarget: ColumnTarget.budget, collapsed: false }));

      // Hide FTE, difference and description columns
      year.columns.map((column) => {
        dispatch(collapseStateUpdated({ yearId: year.entity.id, type: column.type, columnTarget: ColumnTarget.description, collapsed: !showOnlyBudget }));

        if (column.type !== BudgetType.budget && column.type !== BudgetType.revision)
          dispatch(collapseStateUpdated({ yearId: year.entity.id, type: column.type, columnTarget: ColumnTarget.budget, collapsed: !showOnlyBudget }));

        if (column.showFulltimeEquivalentColumn)
          dispatch(collapseStateUpdated({ yearId: year.entity.id, type: column.type, columnTarget: ColumnTarget.fulltimeEquivalent, collapsed: !showOnlyBudget }));

        if (column.showDifferenceColumn)
          dispatch(collapseStateUpdated({ yearId: year.entity.id, type: column.type, columnTarget: ColumnTarget.difference, collapsed: !showOnlyBudget }));
      })
    })
    toggleInstitutionColumn();
    dispatch(setShowBudgetsOnly(!showOnlyBudget));
  }

  return (
    <div className={"year-column-filter-container"}>
      <button disabled={disableShowBudgetsOnly(selectedYearIds, isReadonly, financialReportType, financialReportYearId)} className={"dropdown-item show-budgets-only-button"} onClick={() => showBudgetsOnly()}>
        {showOnlyBudget ? Localizer.showAllColumns() : Localizer.showBudgetsOnly()}
      </button>

      {hasInstitution &&
        <div className={"margin-s"}>
          <Checkbox
            id={"institution"}
            text={Localizer.institution()}
            disabled={isReadonly}
            value={!institutionCollapsed}
            onChange={(e) => toggleInstitutionColumn()}
            key={"institution"}
          />
        </div>
      }
      {props.years.map((year, index) => {
        return (
          <div key={`${index}`} className={"margin-s"}>
            <Checkbox
              id={"year"+year.entity.id}
              text={`${yearText(year.entity.id, props.years.map( function (year) {return year.entity}))}`}
              disabled={isYearDisabled(year.entity.id, selectedYearIds, isReadonly,  financialReportType, financialReportYearId)}
              value={year.shown}
              onChange={(e) => onYearCheckboxChange(e, year)}
              key={year.entity.id+index}
            />
            <div className={"margin-left-m"}>
              {year.columns.map((column, index) => (
                <React.Fragment key={"sub"+index}>
                  <Checkbox
                    id={"budget"+year.entity.id+column.type}
                    text={isApprovedText ? columnTitleTranslator(BudgetType.budget): columnTitleTranslator(column.type)}
                    disabled={isYearDisabled(year.entity.id, selectedYearIds, isReadonly, financialReportType, financialReportYearId)}
                    value={!column.budgetCollapsed && year.shown}
                    onChange={(e) => onColumnCheckboxChange(e, year.entity.id, column.type, ColumnTarget.budget, column.budgetCollapsed)}
                    key={"budget"+year.entity.id}
                  />



                  {column.showFulltimeEquivalentColumn &&
                    <Checkbox
                      id={"fte"+year.entity.id}
                      text={Localizer.FTE()}
                      disabled={isYearDisabled(year.entity.id, selectedYearIds, isReadonly, financialReportType, financialReportYearId)}
                      value={!column.fulltimeEquivalentCollapsed && year.shown}
                      onChange={(e) => onColumnCheckboxChange(e, year.entity.id, column.type, ColumnTarget.fulltimeEquivalent, column.fulltimeEquivalentCollapsed ?? false)}
                      key={"fte"+year.entity.id}
                    />
                  }

                  {column.showDifferenceColumn &&
                    <Checkbox
                      id={"difference"+year.entity.id}
                      text={Localizer.difference()}
                      disabled={isYearDisabled(year.entity.id, selectedYearIds, isReadonly, financialReportType, financialReportYearId)}
                      value={!column.differenceCollapsed && year.shown}
                      onChange={(e) => onColumnCheckboxChange(e, year.entity.id, column.type, ColumnTarget.difference, column.differenceCollapsed ?? false)}
                      key={"difference"+year.entity.id}
                    />
                  }

                  <Checkbox
                    id={"description"+year.entity.id+column.type}
                    text={Localizer.description()}
                    disabled={isYearDisabled(year.entity.id, selectedYearIds, isReadonly, financialReportType, financialReportYearId)}
                    value={!column.descriptionCollapsed && year.shown}
                    onChange={(e) => onColumnCheckboxChange(e, year.entity.id, column.type, ColumnTarget.description, column.descriptionCollapsed)}
                    key={"description"+year.entity.id}
                  />
                </React.Fragment>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  )
}

const yearText = (yearId: string, years: Year[]) => {
  const yearNumber = getYearNumber(yearId, years);

  if(!yearNumber) return `${Localizer.year()} ?`;

  return `${Localizer.year()} ${yearNumber}`;
}

const getYearNumber = (yearId: string, years: Year[]) => years.find(x => x.id === yearId)?.yearNumber;

const isYearDisabled = (yearId: string, selectedYearIds: string[], isReadonly: boolean, financialReportType: FinancialReportType, financialReportYearId?: string) => {

  if(financialReportYearId) return financialReportType !== FinancialReportType.Single;

  if(isReadonly && financialReportType === FinancialReportType.Single) return false;
}

const disableShowBudgetsOnly = (  selectedYearIds: string[], isReadonly: boolean, financialReportType: FinancialReportType, financialReportYearId?: string) => {
  if(financialReportYearId) return financialReportType === FinancialReportType.Single;

  if(financialReportType === FinancialReportType.Single) return false;
}
