import { RefObject, useEffect } from "react";
import { BrowserEvents } from "../infrastructure/browserEvents";

export const useOnClickOutside = <T extends HTMLElement  | null | undefined = HTMLElement>(ref: T, handler: () => void, enabled = true) => {
  useEffect(
    () => {
      if (!enabled) {
        return;
      }
      const listener = (event: MouseEvent | TouchEvent) => {
        // call handler if clicked element is not current element or it's descendant
        if (
          ref
          && event.target instanceof Element
          && !ref.contains(event.target)
        ) {
          handler();
        }
      };
      document.addEventListener(BrowserEvents.Pointerdown, listener);
      return () => {
        document.removeEventListener(BrowserEvents.Pointerdown, listener);
      };
    }, [ref, handler, enabled]);
};
