import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { Status, statusTranslator } from 'models/enums/status';
import { budgetSubmitted } from "pages/dashboard/components/Grid/actions";

export interface TitlebarState {
    breadcrumbs: [
        {
            text: string;
            url: string;
        }
    ],
    status?: string;
    title: string
}

const initialState: TitlebarState = {
    breadcrumbs: [{text: "", url: ""}],
    status: undefined,
    title: ""
};

export const titlebarSlice = createSlice({
    name: 'titlebar',
    initialState,
    reducers: {
        addBreadcrumb: (state, action: PayloadAction<{text : string, url: string}>) => {
            state.breadcrumbs.push({text : action.payload.text, url : action.payload.url});
        },

        newBreadcrumb: (state, action: PayloadAction<{text : string, url: string}>) => {
            state.breadcrumbs = [{text : action.payload.text, url : action.payload.url}];
        },

        setStatus: (state, action: PayloadAction<string>) => {
            state.status = action.payload;
        },

        setTitle: (state, action: PayloadAction<string>) => {
            state.title = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(budgetSubmitted, state => {
            state.status = statusTranslator(Status.Submitted);
        })
    }
});

export const { addBreadcrumb, newBreadcrumb, setStatus, setTitle } = titlebarSlice.actions;
export const titlebarState = (state: RootState) => state.titlebar;
export default titlebarSlice.reducer;
