import React, { useRef } from "react";
import { useEffect } from "react";
import ReactDOM from "react-dom";
import { Icon } from "../icon/icon";
import classNames from "classnames";

export interface IModalProps {
    id: string;
    title: string;
    showModal: boolean;
    toggleModal: () => void;
    size?: "modal-sm" | "modal-lg" | "modal-xl";
    type?: "modal-original" | "modal-message";
    original?: boolean;
    disableOk?: boolean;
    cancelText?: string;
    okText?: string;
    onOk?: () => void;
    onCancel?: () => void;
    okDisabled?: boolean;
}

export const Modal = (props: React.PropsWithChildren<IModalProps>) => {
    const modalRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if(modalRef.current === null) return;

        $(modalRef.current).modal(props.showModal ? "show" : "hide");
    }, [props.showModal, modalRef])

    return (<>
            {props.showModal && ReactDOM.createPortal(
                <div ref={modalRef} className={classNames('modal', 'fade', props.original && "modal-original", props.type ? props.type : "")} id={props.id} tabIndex={-1} role="dialog" aria-labelledby="myModalLabel">
                    <div className={`modal-dialog ${props.size ? props.size : ""}`} role="document">
                        <div className="modal-content">
                            {props.type === "modal-original" &&
                                <div className="modal-header">
                                    <button type="button" className="btn btn-default btn-icon close" data-dismiss="modal" aria-label="Close" onClick={props.toggleModal}>
                                        <Icon icon={"close"} />
                                    </button>
                                    <h4 className="modal-title" id="myModalLabel">{props.title}</h4>
                                </div>
                            }
                            <div className="modal-body">
                                {props.children}
                            </div>
                            <div className="modal-footer">
                                {!props.disableOk && props.type === "modal-original" &&
                                    <>
                                        <button type="button" className="btn btn-default" data-dismiss="modal" onClick={props.toggleModal}>{props.cancelText ?? "Cancel"}</button>
                                        <button type="button" data-dismiss="modal" className="btn btn-primary" onClick={props.onOk} disabled={props.okDisabled}>{props.okText ?? "Ok"}</button>
                                    </>
                                }

                                {props.type === "modal-message" &&
                                    <button type="button" data-dismiss="modal" className="btn btn-lg btn-primary message-button" onClick={props.onOk}>{props.okText ?? "Ok"}</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>,
                document.getElementById("modal-container") as Element
            )}
        </>
  )
}
export default Modal;
