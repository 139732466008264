import React, {useEffect, useState} from "react";
import {BrowserEvents} from "../../infrastructure/browserEvents";

export const useHideTitleBarOnScroll =() => {
    const [isVisible, setIsVisible] = useState(true);
    const heightToHideFrom = 30;

    useEffect(() => {
        window.addEventListener(BrowserEvents.Scroll, listenToScroll);
        return () =>
            window.removeEventListener(BrowserEvents.Scroll, listenToScroll);
    }, []);

    const listenToScroll = () => {
        const winScroll = document.body.scrollTop ||
            document.documentElement.scrollTop;

        if (winScroll > heightToHideFrom) {
            isVisible && setIsVisible(false);
        } else {
            setIsVisible(true);
        }
    };

    return [isVisible];
}
