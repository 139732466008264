import React, {useEffect, useMemo, useState} from "react";
import {Modifier, usePopper} from "react-popper";
import ReactDOM from "react-dom";
import styles from "./textAreaInput.module.scss";
import classNames from "classnames";

interface ITextAreaInputProps {
    textInput: string
    active: boolean |undefined
    onBlurCallback: (value: string) => void;
    element: Element | null
    isMultiline: boolean
}

export const TextAreaInput = (props : ITextAreaInputProps) => {
  const [textarea, setTextarea] = useState<HTMLElement | null>(null);
  const [divArea, setDivArea] = useState<HTMLElement | null>(null);
  const cellHeightPrecision = 3; // Is the cellheight
  const cellOffsetPercision = 11; //add 2 so that it fits more
  const paddingTopPercision = -1;
  const maxcontentHainght = 500;
  const placementOfpopperWithoutOffset = 'bottom-start';
  const computedStyle = useMemo(()=> getComputedStyle(props.element!!), [props.element]);
  const modifiers = useMemo(() => [
    {
      name: "sameWidth",
      enabled: true,
      fn: ( data ) => {
        const paddingTop = parseInt(computedStyle.paddingTop);
        const paddingBottom = computedStyle.paddingBottom;
        const lineHeight = computedStyle.lineHeight;
        const minHeight = parseInt(lineHeight) + paddingTop+ parseInt(paddingBottom)+cellHeightPrecision;
        let widthWithoutPadding = props.element?.clientWidth;
        data.state.styles.popper.width = `${widthWithoutPadding}px`;
        data.state.styles.popper.zIndex = "1";
        data.state.styles.popper.maxHeight = `${maxcontentHainght}px`;
        data.state.styles.popper.overflow = "scroll"
        data.state.styles.popper.minHeight =  `${minHeight-1}px`
        data.state.styles.popper.padding = `${computedStyle.padding}`
        data.state.styles.popper.lineHeight= `${lineHeight}`;
        data.state.styles.popper.paddingTop = `${paddingTop+paddingTopPercision}px`
      },
      phase: "beforeWrite",
      requires: ["computeStyles"],
    },
    {
      name: "offset",
      options: {
        offset: [0, (parseInt(computedStyle.lineHeight))*(-1)-cellOffsetPercision],
      },
    },
    {
      name: 'eventListeners',
      options: {scroll: false}
    },
    {
      name: 'preventOverflow',
      options: {
        mainAxis: false, // true by default
      },
    },

  ] as Partial<Modifier<string, any>>[], [props.element,computedStyle]);

  const { styles: popperStyles, attributes, update } = usePopper(props.element, textarea, {
    placement: placementOfpopperWithoutOffset,
    modifiers: modifiers,

  });

  const updatePopperAndFocus = () => {
    if(update !== null)
      update();

    setTimeout(() => {
      textarea?.focus();
    }, 20)
  }

  useEffect(()=> {
    if(props.isMultiline) {
      divArea?.focus()
    }else {
      updatePopperAndFocus();
    }
   },[textarea, update,props.active])

  const customPopperStyles = {
    zIndex: 560
  };

  // Merge the custom styles with the popperStyles object
  const combinedPopperStyles = { ...popperStyles.popper, ...customPopperStyles };

  return (
    <>
      {props.isMultiline ?
        <div ref={setDivArea}
          className={classNames(styles.textarea,
          styles.multiline,
          "padding-xs padding-left-m padding-right-m")}
          contentEditable={"true"}
          role={"textbox"}
          inputMode={"text"}
          dangerouslySetInnerHTML={{__html:props.textInput}}
          onBlur={(e)=> {
            props.onBlurCallback(e.target.innerText)
        }}/>
        :
      (ReactDOM.createPortal(
        <div ref={setTextarea}

          className={styles.textarea}
          contentEditable={"true"}
          role={"textbox"}
          inputMode={"text"}
          style={combinedPopperStyles}
          dangerouslySetInnerHTML={{__html:props.textInput}}
          {...attributes.popper}
          onBlur={(e)=> {
           props.onBlurCallback(e.target.innerText)
         }}
        />
        ,
        document.body
        )
      )
    }
    </>
  );
};
