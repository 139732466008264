import React, { useEffect, useMemo, useState } from "react";
import {
	BudgetType,
	Institution,
	Subcategory,
	Category,
	ValueResponseModel
} from "services/api/responseModels/budgetPeriodResponseModel";
import CellYearWrapper from "./cellYearWrapper";
import SubcategoryTags from "./subcategoryTags";
import { selectShownYears, selectSingleFinancialReportYearId, YearState } from "./yearSlice";
import { IsSalaryCategory, IsAdministrativeSubcategory, IsProjectSupplementCategory } from "infrastructure/extensions/categoryNameAsserter";
import CellContainer from "./cellContainer";
import SumCell from "./sumCell";
import SubcategoryHeader from "./subcategoryHeader";
import { useDispatch, useSelector } from "react-redux";
import { GrantConstraints, selectGrantConstraints, showSingleFinancialReportColumn } from "./grantConstraintSlice";
import { RootState } from "app/store";
import { selectTabIndex, setFteRow, TabIndex } from "../../../../../infrastructure/tabIndexSlice";
import { InstitutionPill } from "./institutionPill";
import { DropdownOption } from "../../../../../core/dropdown/dropdown";
import { DropdownButton } from "../../../../../core/dropdownButton/dropdownButton";
import { changeSelectedInstitution, changeSelectedSupplement } from "./subcategorySlice";
import { useUpdateInstitutionMutation, useUpdateValueMutation } from "services/api/budgetPeriodApi";
import { selectCellById } from "./cellSlice";

interface SubcategoryRowProps {
	subcategory: Subcategory;
	category: Category;
	years: YearState[];
	categoryNumber: number;
	subcategoryNumber: number;
	currency: string;
	institutions: Institution[];
	showTotal: boolean;
	verticalIndex: number;
	supplementChoiceEnabled: boolean;
	institutionReadonly: boolean;
	supplementReadonly: boolean;
}

export function SubcategoryRow(props: SubcategoryRowProps) {
	const dispatch = useDispatch();
	const [updateValue] = useUpdateValueMutation();
	const [updateInstitution] = useUpdateInstitutionMutation();

	const showSingleFinancialReport = useSelector<RootState>(state => showSingleFinancialReportColumn(state)) as boolean;
	const singleFinancialReportYearId = useSelector<RootState>(state => selectSingleFinancialReportYearId(state)) as string;
	const { isReadonly, customSubcategoriesCollapsed, institutionCollapsed, budgetType } = useSelector<RootState>(state => selectGrantConstraints(state)) as GrantConstraints;

	const { maxHorisontal, maxVertical } = useSelector<RootState>(state => selectTabIndex(state)) as TabIndex
	const shownYears = useSelector<RootState>(state => selectShownYears(state.years)) as YearState[];

	const [isFirstDropdownVisible, setFirstDropdownVisible] = useState(false);
	const [selectedInstitutionId, setSelectedInstitutionId] = useState(props.subcategory.selectedInstitution?.id ?? '');
	var [selectedSupplement, setSelectedSupplement] = useState(props.subcategory.selectedSupplement ?? '');

	var hasSupplement = useMemo(() => selectedSupplement !== '', [selectedSupplement]);
	const singleInstitutionWithNoFteTypes = useMemo(() => props.institutions.length === 1 && (props.institutions[0].ftE_Types.length === 0 || props.institutions[0].ftE_Types[0] === ''), [props.institutions]);
	const hasSupplements = useMemo(() => props.subcategory.selectedInstitution?.ftE_Types.length === 0 || props.subcategory.selectedInstitution?.ftE_Types[0] === '', [props.subcategory.selectedInstitution]);
	const firstDropdownOptions: DropdownOption[] = useMemo(() => props.institutions.filter(institution => IsAdministrativeSubcategory(props.subcategory.name) ? !institution.isEligible : true).map((ins, index) => ({ key: ins.id, value: ins.name })), [props.institutions, props.subcategory.name]);
	const secondDropdownOptions: DropdownOption[] = useMemo(() => props.institutions.find(ins => ins.id === selectedInstitutionId)?.ftE_Types.map((ftEType, index) => ({ key: index.toString(), value: ftEType })) ?? [], [selectedInstitutionId, props.institutions]);
	const handlePillClick = () => {
		setFirstDropdownVisible(true);
	};

	const handleAddInstitution = async (subcategoryId: string, institution: Institution) => {
		// API call here
		const result = await updateInstitution({
			budgetPeriodSubCategoryId: subcategoryId,
			fteType: undefined,
			institution: institution.name,
		}).unwrap();

		dispatch(changeSelectedInstitution({ subcategoryId, institution, psValues: result.projectSupplements }));
	};

	const handleAddSupplement = async (subcategoryId: string, institution: Institution, supplement: string) => {
		// API call here
		const result = await updateInstitution({
			budgetPeriodSubCategoryId: subcategoryId,
			fteType: supplement,
			institution: institution.name,
		}).unwrap();
		dispatch(changeSelectedSupplement({ subcategoryId, supplement, psValues: result.projectSupplements }));
	};

	const addSelectedInstitution = async (selectedKey: string) => {
		const selectedInstitution = props.institutions.find(ins => ins.id === selectedKey);
		if (selectedInstitution) {
			setSelectedInstitutionId(selectedInstitution.id);
			await handleAddInstitution(props.subcategory.id, selectedInstitution);
			setSelectedSupplement('');
			setFirstDropdownVisible(false);
		}
	};

	const addSelectedSupplement = async (institutionId: string, selectedKey: string) => {
		const selectedInstitution = props.institutions.find(ins => ins.id === institutionId);
		const selectedOption = secondDropdownOptions.find(option => option.key === selectedKey);
		if (selectedOption && selectedInstitution) {
			setSelectedSupplement(selectedOption.value);
			await handleAddSupplement(props.subcategory.id, selectedInstitution, selectedOption.value);
		}
	};

	useEffect(() => {

		const numberOfVisibleFte = budgetType === BudgetType.budget ? shownYears.filter(y => y.columns.find(c => !c.fulltimeEquivalentCollapsed)).length : 0;
		dispatch(setFteRow({
			verticalIndex: props.verticalIndex,
			isFteRow: IsSalaryCategory(props.subcategory.category.name),
			numberOfVisibleFte: numberOfVisibleFte
		}));
	}, [dispatch, maxHorisontal, maxVertical]);

	return (
		<>
			<SubcategoryHeader subcategory={props.subcategory} category={props.category} categoryNumber={props.categoryNumber}
				subcategoryNumber={props.subcategoryNumber} />
			<div
				className={`category-row-cell border-bottom-left custom-categories-sidebar-element ${isReadonly ? 'readonly-single-column' : ''} ${!customSubcategoriesCollapsed ? 'open-costom-sidebar-element' : ''}`}>
				{!customSubcategoriesCollapsed &&
					<SubcategoryTags subcategoryId={props.subcategory.id} />
				}
			</div>

			<div
				className={`pill-first-dropdown-container institution-pill-text category-row-cell border-bottom-left custom-categories-sidebar-element ${isReadonly ? 'readonly-single-column' : ''}`}>
				{!institutionCollapsed && (
					<>
						{singleInstitutionWithNoFteTypes ? (
							<InstitutionPill text={props.subcategory.selectedInstitution ? props.subcategory.selectedInstitution?.name : props.institutions[0].name} />
						) : (
							<div onClick={handlePillClick}>
								{props.institutions.length === 1 && props.subcategory.selectedInstitution
									? <InstitutionPill text={props.subcategory.selectedInstitution.name} />
									:
									<DropdownButton
										id="institution-dropdown"
										btnStyling={"btn-default"}
										btnText={props.subcategory.selectedInstitution ? props.subcategory.selectedInstitution.name : "Select Institution"}
										options={firstDropdownOptions}
										onItemClick={addSelectedInstitution}
										classes={"institution-dropdown"}
										disabled={props.institutionReadonly || props.subcategory.institutionsReadOnly || isReadonly}
									/>
								}
								{/* {selectedInstitution !== '' && !hasSupplements && (IsSalaryCategory(props.category.name) || IsProjectSupplementCategory(props.category.name)) && (*/}
								{selectedInstitutionId !== '' && props.supplementChoiceEnabled && !hasSupplements && (IsSalaryCategory(props.category.name)) && (
									<DropdownButton
										id="supplement-dropdown"
										btnStyling={"btn-default"}
										btnText={hasSupplement ? selectedSupplement : "Select supplement"}
										options={secondDropdownOptions}
										onItemClick={(e) => addSelectedSupplement(selectedInstitutionId, e)}
										classes={"supplement-dropdown"}
										disabled={props.supplementReadonly || props.subcategory.institutionsReadOnly || isReadonly}
									/>
								)}
							</div>
						)}
					</>
				)}
			</div>



			{props.years.map((year, index) => (
				<React.Fragment key={`category-${props.subcategory.id}-row-${year.entity.id}`}>
					{year.columns.map((yearColumn, index) => {
						let columnStartIndex = 0;
						const canEnterFte = IsSalaryCategory(props.subcategory.category.name);

						const previousYearColumns = props.years
							.filter(x => x.entity.yearNumber < year.entity.yearNumber)
							.flatMap(year => year.columns)
							.filter(col => !col.locked);

						previousYearColumns.forEach(yearColumn => {
							columnStartIndex = !yearColumn.budgetCollapsed ? columnStartIndex + 1 : columnStartIndex;
							columnStartIndex = !yearColumn.descriptionCollapsed ? columnStartIndex + 1 : columnStartIndex;
							columnStartIndex = !yearColumn.fulltimeEquivalentCollapsed && yearColumn.showFulltimeEquivalentColumn && canEnterFte ? columnStartIndex + 1 : columnStartIndex;
						});

						const isProjectSupplement = props.subcategory.category.name === "Project Supplement";

						return (
							<CellYearWrapper
								columnIndex={yearColumn.locked ? -1000 : columnStartIndex}
								verticalIndex={props.verticalIndex}
								subcategory={props.subcategory}
								type={yearColumn.type}
								isReadonly={isProjectSupplement || yearColumn.locked}
								subcategoryId={props.subcategory.id}
								yearId={year.entity.id}
								currency={props.currency}
								showDifferenceCell={yearColumn.showDifferenceColumn}
								showFulltimeEquivalentCell={yearColumn.showFulltimeEquivalentColumn}
								canEnterFulltimeEquivalent={canEnterFte}
								key={`category-${props.subcategory.id}-row-${year.entity.id}-${yearColumn.type}`}
								budgetCollapsed={yearColumn.budgetCollapsed}
								descriptionCollapsed={yearColumn.descriptionCollapsed}
								differenceCollapsed={yearColumn.differenceCollapsed}
								fulltimeEquivalentCollapsed={yearColumn.fulltimeEquivalentCollapsed}
							/>
						)
					})}
				</React.Fragment>
			))}

			{props.showTotal && <CellContainer noFocus={true} explicitReadOnly={true}>
				<SumCell subcategories={[props.subcategory]} currency={props.currency} />
			</CellContainer>}

			{showSingleFinancialReport && singleFinancialReportYearId &&
				<CellYearWrapper
					columnIndex={20}
					verticalIndex={props.verticalIndex}
					subcategory={props.subcategory}
					type={BudgetType.financial}
					isReadonly={IsProjectSupplementCategory(props.subcategory.category.name)}
					subcategoryId={props.subcategory.id}
					yearId={singleFinancialReportYearId}
					currency={props.currency}
					showDifferenceCell={true}
					showFulltimeEquivalentCell={false}
					canEnterFulltimeEquivalent={IsSalaryCategory(props.subcategory.category.name)}
					key={`category-${props.subcategory.id}-row-${singleFinancialReportYearId}-${BudgetType.financial}`}
					budgetCollapsed={false}
					descriptionCollapsed={false}
					differenceCollapsed={false}
					fulltimeEquivalentCollapsed={false}
				/>}
		</>
	);
}
export default SubcategoryRow;
