import {    
    isRejectedWithValue,
    Middleware
  } from "@reduxjs/toolkit"
import { NotificationModule } from "ditmer-embla";
import { Localizer } from "infrastructure/localization/localizer";

  export const apiErrorLog: Middleware =
    () => (next) => (action: any) => {
      if (isRejectedWithValue(action)) {        
        if(action.payload && action.payload.status === 401) {          
          NotificationModule.showErrorSmall(Localizer.unauthorizedError());
        } else {
          NotificationModule.showErrorSmall(Localizer.errorOccured());          
        }
        
      }

      return next(action)
    }
