import React, { useEffect, useState } from "react";
import { ActionMeta, StylesConfig } from "react-select";
import AsyncCreatableSelect from "react-select/async-creatable";
import CreatableSelect from "react-select/creatable";

export interface Option {
  value: string;
  label: string;
  isFixed: boolean;
  selected: boolean;
}

interface SelectComponentProps {
  shouldTruncate : boolean;
  handleRemove? : (name: Option) => void,
  handleAdd? : (name: Option) => void,
  defaultSelectedOptions? : Option[],
  avaiableOptionsPromise? : () => Promise<Option[]>,
  avaiableOptionsSearchPromise? : (inputValue: string) => Promise<Option[]>
  asActionButton: boolean
}


const selectComponentStylesActionBar: StylesConfig<Option, true> = {
  multiValue: (base, state) => {
    return { ...base, borderRadius: "10px", fontSize: "12px",
        paddingLeft: 5}

  },
  multiValueLabel: (base, state) => {
    return state.data.isFixed
      ? { ...base, paddingRight: 6, paddingLeft: 2}
      : {base, height: "20px", minHeight: "20px"};
  },
  multiValueRemove: (base, state) => {
    return state.data.isFixed ? { ...base, display: 'none' } : base;
  },
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  container:  (base, state) => ({...base, border: 'none'}),
  valueContainer: (base,state) => ({
    ...base,
    minHeight: "32px",
    paddingTop: 0
  }),
  control: (base,state) => (
    {...base,
      height: state.isFocused ? "" : "32px",
      minHeight: "32px",
      boxShadow: 'none',
      borderRadius: '6px',
      border: "1px solid #b8b8b8",
      "&:hover": {
        borderColor: "black"
      }
    }),
  indicatorsContainer: (prevStyle, state) => ({
    ...prevStyle,
    display: 'none'
  }),

};


const selectComponentStyles = (minHeight: string, borderRadius: string, fontSize: string, shouldTruncate: boolean ): StylesConfig<Option, true> => {


  const selectComponentStyles: StylesConfig<Option, true> = {
    multiValue: (base, state) => {
      return { ...base, borderRadius: borderRadius,
          fontSize: fontSize,
          paddingLeft: 5}
    },
    multiValueLabel: (base, state) => {
      return state.data.isFixed
        ? { ...base, paddingRight: 6, paddingLeft: 2}
        : {base, height: minHeight, minHeight: minHeight};
    },
    multiValueRemove: (base, state) => {
      return state.data.isFixed ? { ...base, display: 'none' } : base;
    },
    menuPortal: base => ({ ...base, zIndex: 9999 }),
    container:  (base, state) => ({...base, border: 'none'}),
    control: (base,state) => (
      {...base,
        borderRadius: '0px',
        border: 'none !important',
        height: state.isFocused || !shouldTruncate ? "" : minHeight,
        minHeight: state.isFocused || !shouldTruncate ? "" : minHeight,
        boxShadow: 'none',
      }),
    valueContainer: (base, state) => ({
      ...base, paddingLeft: 3
    }),
    indicatorsContainer: (prevStyle, state) => ({
      ...prevStyle,
      display: 'none'
    }),
  };

  return selectComponentStyles;
}



const SelectComponent = (props : SelectComponentProps) => {
  const FONT_SIZE = "12px";
  const BORDER_RADIUS = "10px";
  const MIN_HEIGHT = "18px";


  const getShownOptions = (options : readonly Option[]) => {
    if (!props.shouldTruncate) {
      return options;
    }

    if (isInEditMode) {
      return options;
    }

    const limit = props.asActionButton ? 1 : 2;
    if (options.length > limit) {
      const shown = options.slice(0, limit);
      shown.push({label: "+"+(options.length - limit).toString(), value: "", isFixed: true, selected: true});
      return shown;
    }

    return options;
  }

  const [isInEditMode, setIsInEditMode] = useState(false);
  const [availableOptions, setAvailableOptions] = useState<readonly Option[]>([]);
  const [allselectedOptions, setAllSelectedOptions] = useState<readonly Option[]>(props.defaultSelectedOptions ?? []);
  const [shownOptions, setShownOptions] = useState<readonly Option[]>([]);

  useEffect(() => {
    setShownOptions(getShownOptions(allselectedOptions));
  }, [])

  useEffect(() => {
    setShownOptions(getShownOptions(allselectedOptions));
  }, [props.shouldTruncate])

  useEffect(() => {
    setShownOptions(getShownOptions(allselectedOptions));

    if (isInEditMode) {
      if (props.avaiableOptionsSearchPromise) {
        return;
      }

      if (props.avaiableOptionsPromise) {
        props.avaiableOptionsPromise().then(result => {
          setAvailableOptions(result);
        })
      }
    }
  }, [isInEditMode])

  const handleChange = (option: readonly Option[], actionMeta: ActionMeta<Option>) => {
    const newShownOptions = option.filter(x => x.value);

    const removedOption = shownOptions.find((item) => !newShownOptions.includes(item));
    const addedOption = newShownOptions.find((item) => !shownOptions.includes(item));

    if (removedOption) {
      const newAllSelectedOptions = allselectedOptions.filter(value => value !== removedOption);
      setAllSelectedOptions(newAllSelectedOptions);
      setShownOptions(getShownOptions(newAllSelectedOptions));
    }

    if (addedOption) {
      const newAllSelectedOptions = allselectedOptions.concat(addedOption);
      setAllSelectedOptions(newAllSelectedOptions);
      setShownOptions(getShownOptions(newAllSelectedOptions));
    }

    if (props.handleRemove && removedOption) {
      props.handleRemove(removedOption);
    }

    if (props.handleAdd && addedOption) {
      props.handleAdd(addedOption);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      event.stopPropagation();
    }
  };

  return (
    <>
      {props.avaiableOptionsSearchPromise ?
        <AsyncCreatableSelect
          placeholder={props.asActionButton ? "Select tags" : ""}
          onMenuOpen={() => setIsInEditMode(true)}
          onBlur={() => setIsInEditMode(false)}
          styles={props.asActionButton ? selectComponentStylesActionBar : selectComponentStyles(MIN_HEIGHT, BORDER_RADIUS,FONT_SIZE, props.shouldTruncate)}
          isMulti
          value={shownOptions}
          onChange={handleChange}
          menuPortalTarget={document.body}
          loadOptions={props.avaiableOptionsSearchPromise}
        />
        :
        <CreatableSelect
          placeholder={props.asActionButton ? "Select tags" : ""}
          onMenuOpen={() => setIsInEditMode(true)}
          onBlur={() => setIsInEditMode(false)}
          styles={props.asActionButton ? selectComponentStylesActionBar : selectComponentStyles(MIN_HEIGHT, BORDER_RADIUS,FONT_SIZE, props.shouldTruncate)}
          options={availableOptions}
          isMulti
          value={shownOptions}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          menuPortalTarget={document.body}
          isClearable={false}
        />
      }
    </>
  );
};

export default SelectComponent;
