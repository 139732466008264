import { createContext, useContext, useState, useEffect, PropsWithChildren } from "react";
import { createUserFromLogin, UserModel } from "models/userModel";
import { StorageKeys } from "infrastructure/storageKeys";
import { useGetUserQuery } from "services/api/userApi";
import { useNavigate } from "react-router-dom";
import { Routes } from "./routes";

const authContext = createContext<ReturnType<typeof useProvideAuth>>(undefined as any);

export const useProvideAuth = () => {
  const [user, setUser] = useState<UserModel | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const { data: userInfo, isLoading, isError } = useGetUserQuery(token, { skip: !token });
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const [loginFailed, setLoginFailed] = useState(false);
  const navigate = useNavigate();

  const getUserInformation = async () => {
    setIsLoadingUser(true);
    if(isLoading) return;

    if(!userInfo) {
      setIsLoadingUser(false);
      setLoginFailed(true);
      return;
    }

    const userModel = createUserFromLogin(userInfo);
    setUser(userModel);
    setIsLoadingUser(false);
    setLoginFailed(false);
  }

  useEffect(() => {
    const fetchUserInformation = async () => {
      try {
        await getUserInformation();
      } catch (error) {
        await signOut();
      }
    }

    fetchUserInformation();
  }, [token, userInfo])

  useEffect(() => {
    if(!isError) return;

    setLoginFailed(true);
    signOut();
    navigate(Routes.LoggedOut.path);
  }, [isError]);

  const signInToken = async (token: string) => {
    if(!token) {
      await signOut();
      return false;
    }

    setToken(token);

    try {
      await getUserInformation();
    } catch (error) {
      await signOut();
      return false;
    }

    return true;
  }

  const signOut = async () => {
    localStorage.removeItem(StorageKeys.token);
    setUser(new UserModel());
  };

  return {
    user,
    isLoadingUser,
    loginFailed,
    getUserInformation,
    signOut,
    signInToken
  };
}

export const ProvideAuth = (props: PropsWithChildren<{}>) => {
  const auth = useProvideAuth();

  return (
    <authContext.Provider value={auth}>
      { !auth.loginFailed && auth.isLoadingUser ?
        <div></div> :
        props.children }
    </authContext.Provider>
  );
}


export const useAuth = () => {
  return useContext(authContext);
};

