import React, {useEffect, useMemo} from "react";
import {
  BudgetPeriodResponseModel,
  Category,
  Institution,
  Subcategory
} from "services/api/responseModels/budgetPeriodResponseModel";
import { CategoryRow } from "./components/categoryRow";
import { BudgetHeader } from "./components/budgetHeader";
import { SummationRow } from "./components/summationRow";
import AvailableCategoryRow from "./components/availableCategoryRow";
import { selectShownSubcategories } from "./components/subcategorySlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/store";
import { selectAmountOfColumnsShown, selectColumnsShown, selectNavigatableColumns, YearColumn } from "./components/yearSlice";
import { GrantConstraints, selectGrantConstraints, showSingleFinancialReportColumn, showTotalColumn } from "./components/grantConstraintSlice";
import { CellContext } from "./cellContext";
import { AdministrationCategory, DisseminationCategory, IsSalaryCategory, OperationCategory, SalaryCategory, ProjectSupplementCategory } from "infrastructure/extensions/categoryNameAsserter";
import { maxHorisontalChanged, maxVerticalChanged } from "infrastructure/tabIndexSlice";
import ProjectSupplement from "./components/projectSupplement";

const collapsedColumnWidth = "0px ";
const defaultColumnWidth = "minmax(160px, 160px) ";
const differenceColumnWidth = "minmax(195px, auto) ";
const fullTimeEquivalentColumnWidth = "minmax(71px, 71px) ";
const descriptionColumnWidth = "minmax(296px, 100vw) ";
const sumColumnWidth = " minmax(200px, auto)";

console.log("Hello from Morten");

interface IGridProps {
  budgetPeriod: BudgetPeriodResponseModel,
}

export function idLabelIsDefined<T>(value: T | undefined): value is T {
  return value !== undefined;
}
export function Grid(props: IGridProps) {
  const navigatableColumns = useSelector<RootState>(state => selectNavigatableColumns(state)) as number;
  const columns = useSelector<RootState>(state => selectColumnsShown(state.years)) as YearColumn[];
  const shownSubcategories = useSelector<RootState>(state => selectShownSubcategories(state)) as Subcategory[];
  const { customSubcategoriesCollapsed, institutionCollapsed } = useSelector<RootState>(state => selectGrantConstraints(state)) as GrantConstraints;
  const showTotal = useSelector<RootState>(state => showTotalColumn(state)) as boolean;
  const showSingleFinancialReport = useSelector<RootState>(state => showSingleFinancialReportColumn(state)) as boolean;
  const amountOfColumnsShown = useSelector<RootState>(state => selectAmountOfColumnsShown(state.years, showTotal, showSingleFinancialReport)) as number;
  const dispatch = useDispatch();
  const subcategories = shownSubcategories.filter(s => IsSalaryCategory(s.category.name) && s.selectedSupplement && s.values.some(v => v.fullTimeEquivalent !== null));
  const institutionIds = subcategories.map(s => s.selectedInstitution?.id).filter(idLabelIsDefined).filter((value, index, self) => self.indexOf(value) === index);
  const oldGrant = useMemo(() => !props.budgetPeriod.supplementChoiceEnabled && !props.budgetPeriod.projectSupplementEnabled, [props.budgetPeriod.supplementChoiceEnabled, props.budgetPeriod.projectSupplementEnabled]);
  const newGrantWithPSEnabled = useMemo(() => props.budgetPeriod.projectSupplementEnabled && props.budgetPeriod.supplementChoiceEnabled, [props.budgetPeriod.projectSupplementEnabled, props.budgetPeriod.supplementChoiceEnabled])
  const newGrantWithPSDisabled = useMemo(() => props.budgetPeriod.projectSupplementEnabled && !props.budgetPeriod.supplementChoiceEnabled, [props.budgetPeriod.supplementChoiceEnabled, props.budgetPeriod.projectSupplementEnabled]);
  const institutionsWithFteTypes = props.budgetPeriod.institutions.some(i => i.ftE_Types.length > 0 && i.ftE_Types[0] !== '');

  
  useEffect(() => {
    const fteRows = shownSubcategories.filter(s => IsSalaryCategory(s.category.name)).length;
    dispatch(maxVerticalChanged({ totalRows: shownSubcategories.length - 1, fteRows: fteRows })) // -1 = to index
  }, [dispatch, shownSubcategories.length])

  useEffect(() => {
    dispatch(maxHorisontalChanged(navigatableColumns - 1)) // -1 = to index
  }, [dispatch, navigatableColumns])

  const widthForColumns = columns.map(c => {
    let columns = columnWidth(c.budgetCollapsed);

    if (c.fulltimeEquivalentCollapsed !== undefined)
      columns += columnWidth(c.fulltimeEquivalentCollapsed, fullTimeEquivalentColumnWidth)

    if (c.differenceCollapsed !== undefined)
      columns += columnWidth(c.differenceCollapsed, differenceColumnWidth)

    columns += columnWidth(c.descriptionCollapsed, descriptionColumnWidth);
    return columns;
  }).join(' ');

  const gridStyle = {
    "--totalColumns": amountOfColumnsShown,
    "--customCategoriesWidth": customSubcategoriesCollapsed ? "0px" : "300px",
    "--institutionWidth": institutionCollapsed ? "0px" : "350px",
    "--yearColumnns": showTotal ? widthForColumns + sumColumnWidth : widthForColumns
  } as React.CSSProperties;


  const updatedInstitutions = useMemo(() => props.budgetPeriod.institutions.map(institution => {
    return {
      ...institution,
      isEligible: (oldGrant || newGrantWithPSDisabled) ? false : !(institution.ftE_Types.length === 1 && institution.ftE_Types[0] === '')
    };
  }), [oldGrant, newGrantWithPSDisabled, props.budgetPeriod.institutions]);


  const allIsEligible = useMemo(() => {
    if (oldGrant) return false;
    if (newGrantWithPSDisabled) return false;
    return updatedInstitutions && updatedInstitutions.every(i => i.isEligible)
  }, [oldGrant, newGrantWithPSDisabled, updatedInstitutions]);


  const categories = groupedCategories(shownSubcategories);
  const availableCategories = getAvailableCategories(props.budgetPeriod.availableSubCategories, categories, updatedInstitutions, allIsEligible);
  return (
    <CellContext.Provider value={true}>
      <div className="budget-container" style={gridStyle}>
        <BudgetHeader currency={props.budgetPeriod.currency} />
        {renderCategories(categories, availableCategories, props.budgetPeriod.currency, updatedInstitutions, props.budgetPeriod.supplementChoiceEnabled, props.budgetPeriod.institutionReadonly, props.budgetPeriod.supplementReadonly)}
        {institutionsWithFteTypes && newGrantWithPSEnabled && !oldGrant && !newGrantWithPSDisabled &&
          renderProjectSupplements(subcategories, institutionIds, props.budgetPeriod.currency)}
        <SummationRow currency={props.budgetPeriod.currency} />
      </div>
    </CellContext.Provider>
  );
}

export default Grid;

export const groupedCategories = (subcategories: Subcategory[]): Category[] => {
  const categories: Category[] = [];

  subcategories.forEach((subcategory: Subcategory) => {
    const category = categories.find(c => c.name === subcategory.category.name);
    if (!category) {
      categories.push({
        id: subcategory.category.id,
        name: subcategory.category.name,
        subcategories: [subcategory],
        orderNumber: subcategory.category.orderNumber
      })
    } else {
      category.subcategories.push(subcategory);
    }
  });
  return categories;
}

const getAvailableCategories = (availableSubcategories: Subcategory[], alreadyAddedCategories: Category[], institutions: Institution[], allIsEligible: boolean): Category[] => {
  let availableCategoriesForGrant = groupedCategories(availableSubcategories);

  const alreadyAddedCategoryNames = alreadyAddedCategories.map(cat => cat.name);

  let availableCategoriesForBudgetPeriod = availableCategoriesForGrant.filter(c => !alreadyAddedCategoryNames.includes(c.name));

  if (allIsEligible) {
    availableCategoriesForBudgetPeriod = availableCategoriesForBudgetPeriod.filter(c => c.name !== AdministrationCategory);
  }

  if (!institutions) {
    availableCategoriesForBudgetPeriod = availableCategoriesForBudgetPeriod.filter(c => c.name !== ProjectSupplementCategory);
  }
  return availableCategoriesForBudgetPeriod;
}

const renderProjectSupplements = (subcategories: Subcategory[], institutionIds: string[], currency: string) => {
  return institutionIds.map((institution, i) => {
    const filteredSubcategories = subcategories.filter(s => s.selectedInstitution?.id === institution);
    return <ProjectSupplement subcategories={filteredSubcategories} currency={currency} key={`project-supplement-${institution}-${i}`} />
  })
}
const renderCategories = (chosenCategories: Category[], availableCategories: Category[], currency: string, institutions: Institution[], supplementChoiceEnabled: boolean, institutionReadonly: boolean, supplementReadonly: boolean) => {
  const allCategories = chosenCategories.concat(availableCategories);

  sortCategories(allCategories);
  sortCategories(chosenCategories);

  return allCategories.map((cat, i) => {
      if (availableCategories.indexOf(cat) !== -1) {
        return <AvailableCategoryRow category={cat} categoryNumber={i + 1} currency={currency} key={`available-category-row-${cat.id}-${i}`} institutions={institutions} />
      }
      else
        return <CategoryRow category={cat} categoryNumber={i + 1} currency={currency} institutions={institutions} key={`category-row-${cat.id}-${i}`} verticalIndex={getVertical(cat, chosenCategories)} supplementChoiceEnabled={supplementChoiceEnabled} institutionReadonly={institutionReadonly} supplementReadonly={supplementReadonly} />
    }
  )
}

const columnWidth = (collapsed: boolean, expandedColumnWidth?: string) => collapsed ? collapsedColumnWidth : expandedColumnWidth ?? defaultColumnWidth;

const getVertical = (cat: Category, categories: Category[]) => {
  const position = categories.indexOf(cat);
  if (position === 0) return 0;

  const previousCategories = categories.filter(c => categories.indexOf(c) < position);
  const amountOfSubcategoriesBelowCurrent = previousCategories.map(c => c.subcategories.length).reduce((a, b) => a + b, 0);

  return amountOfSubcategoriesBelowCurrent;
}

const sortCategories = (categories: Category[]) => {
  categories.sort((a, b) => {
    const order = [SalaryCategory, OperationCategory, DisseminationCategory, AdministrationCategory, ProjectSupplementCategory];

    return order.indexOf(a.name) - order.indexOf(b.name);
  });
}
