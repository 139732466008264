import { useAppDispatch } from "app/hooks";
import Icon from "core/icon/icon";
import { useState } from "react";
import { RootState } from "app/store";
import { useSelector } from "react-redux";
import {
	selectGrantConstraints,
	GrantConstraints,
} from "./grantConstraintSlice";
import { selectAllYears, YearState } from "./yearSlice";
import { subcategoriesAdded } from "./subcategorySlice";
import { Institution, Subcategory, ValueResponseModel } from "services/api/responseModels/budgetPeriodResponseModel";
import { useAddSubcategoriesMutation } from "services/api/budgetPeriodApi";
import { DropdownButton } from "../../../../../core/dropdownButton/dropdownButton";
import { SubCategoryChooseItem } from "./subCategoryChooseItem";
import { Spinner } from "../../../../../core/spinner/spinner";

interface AddSubcategoryBtnProps {
	categoryId: string;
	categoryNumber: number;
	institutions: Institution[]
}

export function AddSubcategoryBtn(props: AddSubcategoryBtnProps) {
	const dispatch = useAppDispatch();
	const [postSubcategory] = useAddSubcategoriesMutation();
	const { isReadonly, budgetPeriodId, subcategoriesForGrant, budgetType } = useSelector<RootState>((state) => selectGrantConstraints(state)) as GrantConstraints;
	const years = useSelector<RootState>((state) => selectAllYears(state.years)) as YearState[];
	const [isAdding, setIsAdding] = useState(false)

	const subcategoriesForCategory = subcategoriesForGrant
		.filter((sub) => sub.category.id === props.categoryId)
		.sort((a, b) => a.name.localeCompare(b.name))
    .filter((sub) => sub.name.trim() !== '')
		.map((sub) => ({ key: sub.id, value: sub.name, note: sub.scopeNote }));

	const addSubcategory = async (selectedItem: string) => {
		setIsAdding(true)

		const subCArray = [];

		const subcategory = subcategoriesForGrant.find(sub => sub.id === selectedItem) as Subcategory;
		subCArray.push(subcategory);

		const subcategoryIds = subCArray.map((s) => s.id);

		const subCategory = await postSubcategory({
			budgetPeriodId: budgetPeriodId,
			subcategories: subcategoryIds,
			categoryId: props.categoryId,
		}).unwrap()

		const subcategoriesToAdd = subCategory.map(x => {
			const subcategory = subcategoriesForGrant.find(sub => sub.id === x.subcategoryId) as Subcategory;

			const selectedInstitution = props.institutions.length === 1 ? props.institutions[0] : undefined;

			const values: ValueResponseModel[] = years.map<ValueResponseModel>(year => {
				return {
					yearId: year.entity.id,
					yearNumber: year.entity.yearNumber,
					subcategoryId: subcategory.id,
					type: budgetType,
					budget: undefined,
					description: undefined,
					fullTimeEquivalent: undefined,
					commentRequired: false,
					fulltimeEquivalentRequired: undefined,
					categoryNumber: subcategory.category.orderNumber,
					hasIssue: false,
					projectSupplementAmount: undefined
				}
			})
			return { ...subcategory, id: x.id, order: x.order, values: values, selectedInstitution };
		});
		setTimeout(() => {
			dispatch(subcategoriesAdded({ subcategories: subcategoriesToAdd, years: years, categoryNumber: props.categoryNumber }));
			setIsAdding(false);
		}, 550);
	}

	if (isReadonly) return <></>;

	if (subcategoriesForCategory.length === 0) return <></>;

	return (
		<DropdownButton
			id="version-dropdown"
			btnStyling={"btn-icon"}
			icon={isAdding ? <Spinner size={"extra-tiny-small"} className={"margin-xs"} /> : <Icon extraClasses={"embla-icon"} icon={"plus"} />}
			btnText={""}
			optionsContent={<SubCategoryChooseItem onItemClicked={async (selectedKey) => await addSubcategory(selectedKey)} items={subcategoriesForCategory} />}
			disabled={subcategoriesForCategory.length === 0 || isAdding}
		/>
	);
}
export default AddSubcategoryBtn;
