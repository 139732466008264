import { useAppDispatch } from "app/hooks";
import { selectedTagsUpdated } from "pages/dashboard/components/Grid/actions";
import { useEffect, useState } from "react";
import SelectComponent, { Option } from "../../../../../../core/dropdown/selectComponent";
import { mapToOption, useLazyGetTagsQuery } from "../../../../../../services/api/tagApi";

interface CustomCategoriesFilterProps {
  asActionButton: boolean
}

export const CustomCategoriesFilter = (props: CustomCategoriesFilterProps) => {
  const dispatch = useAppDispatch();
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [getTagsLazy] = useLazyGetTagsQuery();

  useEffect(() => {
    dispatch(selectedTagsUpdated(selectedFilters));
  }, [selectedFilters])

  const onAdd = (option: Option) => {
    const newFilters = selectedFilters.concat(option.label);
    setSelectedFilters(newFilters);
  }

  const onRemove = (option: Option) => {
    const newFilters = selectedFilters.filter(x => x !== option.label);
    setSelectedFilters(newFilters);
    dispatch(selectedTagsUpdated(selectedFilters.filter(x => x !== option.label)))
  }

  const getAllAvailableTags = () : Promise<Option[]> => {
    return new Promise<Option[]>(resolve => {
      getTagsLazy("").unwrap().then(results => {
        const tags = results.map((result => {
          return mapToOption(result);
        }));

        resolve(tags);
      });
    });
  }

  return (
    <div className="categories-dropdown">
      <SelectComponent
        avaiableOptionsPromise={getAllAvailableTags}
        shouldTruncate={true}
        handleAdd={onAdd}
        handleRemove={onRemove}
        asActionButton={props.asActionButton}
      />
    </div>
  )
}
export default CustomCategoriesFilter;
