import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { Institution } from "services/api/responseModels/budgetPeriodResponseModel";
import { subcategoryRemoved } from '../actions';

const institutionAdapter = createEntityAdapter({
    selectId: (institution: Institution) => institution.id
})

export interface InstitutionState {
    ids: string[];
    entities: { [key: string]: Institution };
}

export const institutionSlice = createSlice({
    name: "institutions",
    initialState: institutionAdapter.getInitialState(),
    reducers: {
        institutionsAdded: (state, action: PayloadAction<Institution[]>) => {
            institutionAdapter.addMany(state, action.payload);
        },
        institutionAddedToSubcategory: (state, action: PayloadAction<Institution>) => {
            institutionAdapter.addOne(state, action.payload);
        },
        institutionRemovedFromSubcategory: (state, action: PayloadAction<{institutionId: string, subcategoryId: string}>) => {
            institutionAdapter.removeOne(state, action.payload.institutionId);
        }
    },
    extraReducers: (builder) => {
        builder.addCase(subcategoryRemoved, (state, action) => {
            const institutionsToRemove = selectInstitutionsForSubcategory(state, action.payload);
            institutionAdapter.removeMany(state, institutionsToRemove.map(t => t.id));
        })
    }
});

export const { institutionAddedToSubcategory, institutionRemovedFromSubcategory, institutionsAdded } = institutionSlice.actions;
export const institutionState = (state: RootState) => state.institutions;
export default institutionSlice.reducer;
export const { selectAll: selectAllInstitutions } = institutionAdapter.getSelectors();
export const selectInstitutionsForSubcategory = (state: EntityState<Institution, string>, subcategoryId: string) => selectAllInstitutions(state).filter(institution => institution.subcategoryId === subcategoryId);
