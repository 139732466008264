import { Localizer } from "infrastructure/localization/localizer"

export enum Status {
    Draft = 1,
    Submitted = 2,
    Approved = 3
}

export const statusTranslator = (status: Status) : string => {
    switch (status) {
        case Status.Draft:
            return Localizer.draft();
        case Status.Submitted:
            return Localizer.submitted();
        case Status.Approved:
            return Localizer.approved();
        default:
            return "";
    }
}
