import React from 'react';
import {Localizer} from "infrastructure/localization/localizer";

export function NotFoundPage() {

    return (
        <div className={"body-container"}>
            <div className="page-content">
                <div className="main-content">
                    <div className="body-content">
                        <h1>{Localizer.notFound()}</h1>
                    </div>
                </div>
            </div>
        </div>
    );
}
