import classNames from "classnames";
import React, {MutableRefObject, Ref, RefAttributes, useState} from "react";
import ReactDOM from "react-dom";
import { usePopper } from "react-popper";
import tooltipStyles from "./tooltip.module.scss";
import {Placement} from "@popperjs/core";

interface ITooltipProps {
  children: JSX.Element;
  message?: string | false | null;
  placement?: Placement;
  distance?: number;
  className?: string;
}

export const Tooltip = React.forwardRef(({children, message, placement = "auto", distance = 5, className}: ITooltipProps,ref) => {
  // popper intentionally uses state, not ref, to update when dom node changes
  const [arrow, setArrow] = useState<HTMLDivElement | null>();
  const [referenceEl, setReferenceEl] = useState<HTMLElement | null>();
  const [tooltip, setTooltip] = useState<HTMLDivElement | null>();
  const [isHover, setIsHover] = useState(false);

  const { styles: popperStyles, attributes } = usePopper(referenceEl, tooltip, {
    placement: placement,
    modifiers: [
      {
        name: "arrow",
        options: {
          element: arrow,
        },
      },
      {
        name: "offset",
        options: {
          offset: [0, distance],
        },
      },
    ],
  });

  return (
    <>
      {React.cloneElement(children, {
        onPointerEnter: () => setIsHover(true),
        onPointerLeave: () => setIsHover(false),
        ref: mergeRefs(children, setReferenceEl),
      })}

      {isHover && message &&
        ReactDOM.createPortal((
          <div
            ref={setTooltip}
            style={popperStyles.popper}
            className={classNames(tooltipStyles.tooltip,tooltipStyles.tooltipInner,  className,"d-inline-block tooltip-wrapper")}
            {...attributes.popper}
          >
            {message}
            <div
              ref={setArrow}
              className={tooltipStyles.arrow}
              style={popperStyles.arrow}
            />
          </div>
        ), document.body)
      }
    </>
  );
});


const mergeRefs = <T extends HTMLElement, C extends RefAttributes<T>>(child: C, ...refs: Ref<T>[]) =>
  (instance: T) => [child.ref, ...refs].forEach((ref) => {
    if (typeof ref === "function") {
      ref(instance);
    } else if (ref) {
      (ref as MutableRefObject<T>).current = instance;
    }
  });
