import { TopMenu } from "../topMenu/topMenu";
import { TitleBar } from "../titleBar/titleBar";
import { useAppSelector } from "../../app/hooks";
import { TitlebarState, titlebarState } from "../titleBar/titlebarSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { GrantConstraints, selectGrantConstraints } from "../../pages/dashboard/components/Grid/components/grantConstraintSlice";
import { useGetBudgetPeriodByIdQuery, useGetBudgetPeriodIdByExternalApplicationIdQuery } from "../../services/api/budgetPeriodApi";
import { useAuth } from "../../infrastructure/routing/auth";
import { useHideTitleBarOnScroll } from "./hideTitleBarOnScroll";
import { BudgetPeriodVersionModel } from "../../services/api/responseModels/budgetPeriodVersionModel";
import React, { useEffect, useState } from "react";
import { Spinner } from "../../core/spinner/spinner";
import { Localizer } from "../../infrastructure/localization/localizer";
import config from "../../config/config";
import { UserRole } from "../../models/enums/userRole";

interface IPageWrapperProps {
	children: any
}

export const PageWrapper = (props: IPageWrapperProps) => {
	const { budgetPeriodId } = useSelector<RootState>(state => selectGrantConstraints(state)) as GrantConstraints;
	const titleBar = useAppSelector(titlebarState) as TitlebarState;
	const auth = useAuth();
	const externalApplicationId = auth.user?.externalApplicationId as string;
	const { data: latestBudgetPeriodId, isError: hasGrantError, error: grantError } = useGetBudgetPeriodIdByExternalApplicationIdQuery(externalApplicationId);
	const budgetPeriodIdToRetrieve = budgetPeriodId ? budgetPeriodId : latestBudgetPeriodId;
	const selectedVersion = useSelector<RootState>(state => state.versionSlice.selectedVersion) as BudgetPeriodVersionModel;
	const [titleBarWrapperDiv, setTitleBarWrapperDiv] = useState<HTMLDivElement | null>(null)
	const [showTitleBar] = useHideTitleBarOnScroll();
	const [marginTop, setMarginTop] = useState(0);
	const marginTopStyle = {
		"--margin-top": `${marginTop}px`
	} as React.CSSProperties

	const { data: budgetPeriod, isLoading, isFetching, isError: hasBudgetPeriodError, error: budgetPeriodError } = useGetBudgetPeriodByIdQuery({
		budgetPeriodId: budgetPeriodIdToRetrieve as string,
		order: selectedVersion?.order.toString() ?? ""
	}, {
		skip: !budgetPeriodIdToRetrieve
	});

	const showErrors = config.environment !== "Produktion" || auth.user?.userRole === UserRole.Administrator;


	useEffect(() => {
		setMarginTop((titleBarWrapperDiv?.offsetHeight ?? 0) + 30);
	}, [titleBarWrapperDiv])

	if (hasGrantError || hasBudgetPeriodError)
		return <>
			<div className={"body-container"}>
				<TopMenu showActionButtons={!showTitleBar} projectTitle={titleBar.title} status={titleBar.status} instructions={budgetPeriod?.comment} budgetPeriod_Currency={budgetPeriod?.currency} projectSupplementEnabled={budgetPeriod?.projectSupplementEnabled ?? false} supplementChoiceEnabled={budgetPeriod?.supplementChoiceEnabled ?? false} />
				<div className="error-message">
					<h4>{Localizer.errorOccured()}</h4>
					{showErrors && <p>{hasGrantError ? JSON.stringify(grantError) : JSON.stringify(budgetPeriodError)}</p>}
				</div>
			</div>

		</>

	return (
		<div className={"body-container"}>
			<TopMenu showActionButtons={!showTitleBar} projectTitle={titleBar.title} status={titleBar.status} instructions={budgetPeriod?.comment} budgetPeriod_Currency={budgetPeriod?.currency} projectSupplementEnabled={budgetPeriod?.projectSupplementEnabled ?? false} supplementChoiceEnabled={budgetPeriod?.supplementChoiceEnabled ?? false} />
			<div className="page-content">
				<div className="main-content">
					{!isFetching && budgetPeriod ? <>
						{/* The z-index is needed to make the text go behind the table  but only after the actions buttons move to the titlebar */}
						<div ref={setTitleBarWrapperDiv} className="title-bar-wrapper" style={{ zIndex: showTitleBar ? 1 : -1 }}>
							<TitleBar budgetPeriod={budgetPeriod} showActionButtons={showTitleBar} />
						</div>
					</> :
						<>
							<Spinner size={"large"} className={"loading-spinner"} />
						</>}

					<div className="body-content" style={marginTopStyle}>
						{props.children}
					</div>

				</div>
			</div>
		</div>
	);
}
