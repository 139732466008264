import React, { useRef } from "react";
import {
	BudgetType,
	Category,
	Institution,
	Subcategory,
	ValueResponseModel
} from "services/api/responseModels/budgetPeriodResponseModel";
import { SubcategoryRow } from "./subcategoryRow";
import { useDispatch, useSelector } from "react-redux";
import { selectCellsInCategory, sumOfYear } from "./cellSlice";
import { RootState } from "app/store";
import { selectShownYears, selectSingleFinancialReportYearId, YearState } from "./yearSlice";
import ComparerCell from "./comparerCell";
import CellContainer from "./cellContainer";
import AddSubcategoryBtn from "./addSubcategoryBtn";
import { GrantConstraints, selectGrantConstraints, showSingleFinancialReportColumn, showTotalColumn } from "./grantConstraintSlice";
import SumCell from "./sumCell";
import { CellContext } from "../cellContext";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Icon from "../../../../../core/icon/icon";
import classNames from "classnames";
import { Tooltip } from "core/tooltip/tooltip";
import { useTextOverflowHook } from "hooks/useTextOverflowHook";
import { selectShowCategory, showCategory } from "./categorySlice";

interface CategoryRowProps {
	category: Category;
	categoryNumber: number;
	currency: string;
	institutions: Institution[];
	verticalIndex: number;
	supplementChoiceEnabled: boolean;
	institutionReadonly: boolean;
	supplementReadonly: boolean;
}

export function CategoryRow(props: CategoryRowProps) {
	const dispatch = useDispatch();
	const cells = useSelector<RootState>(state => selectCellsInCategory(state.cells, props.category.subcategories.map(sub => sub.id))) as ValueResponseModel[];
	const shownYears = useSelector<RootState>(state => selectShownYears(state.years)) as YearState[];
	const showTotal = useSelector<RootState>(state => showTotalColumn(state)) as boolean;
	const showSingleFinancialReport = useSelector<RootState>(state => showSingleFinancialReportColumn(state)) as boolean;
	const singleFinancialReportYearId = useSelector<RootState>(state => selectSingleFinancialReportYearId(state)) as string;
	const { isReadonly, isMultiline, customSubcategoriesCollapsed, institutionCollapsed } = useSelector<RootState>(state => selectGrantConstraints(state)) as GrantConstraints;
	const textElementRef = useRef<HTMLDivElement>(null);
	const isOverflow = useTextOverflowHook(textElementRef);


	const showSubCategories = useSelector<RootState>(state => selectShowCategory(state.categories.categories, props.category.id)) as boolean;

	if (!props.category.subcategories.length)
		return <></>

	return (
		<>
			<div
				className="category-row-cell border-bottom-left sidebar-element align-items-center justify-content-between category-header-background-color sidebar-category-header-titel">
				<div className="d-flex">
					<span className={"toggle-subcategories"}
						onClick={() => dispatch(showCategory({ id: props.category.id, show: !showSubCategories }))}><Icon
							icon={showSubCategories ? "arrow-down" : "arrow-right"} /></span>

					{isOverflow && !isMultiline ?
						<Tooltip message={`${props.categoryNumber}. ${props.category.name}`} ref={textElementRef}>
							<h5 ref={textElementRef}
								className={classNames("padding-left-xs", isMultiline ? "" : "single-line-sidebar-header-style")}>{`${props.categoryNumber}. ${props.category.name}`}</h5>
						</Tooltip>
						:
						<h5 ref={textElementRef}
							className={classNames("padding-left-xs", isMultiline ? "" : "single-line-sidebar-header-style")}>{`${props.categoryNumber}. ${props.category.name}`}</h5>
					}
				</div>

				<AddSubcategoryBtn categoryNumber={props.categoryNumber} categoryId={props.category.id} institutions={props.institutions} />
			</div>

			<div className={`category-row-cell border-bottom-left custom-categories-sidebar-element category-header-background-color ${!customSubcategoriesCollapsed ? 'open-costom-sidebar-element' : ''}`} />
			<div className={`category-row-cell border-bottom-left custom-categories-sidebar-element category-header-background-color`} />

			{shownYears.map(year =>
				<React.Fragment key={`category-row-year-cell-${props.category.id}-${year.entity.id}`}>
					{year.columns.map((column) => {
						return (
							<React.Fragment key={`category-row-year-cell-${props.category.id}-${year.entity.id}-${column.type}`}>
								<CellContainer noFocus={true}>
									{!column.budgetCollapsed && <div
										className={`category-row-cell-content padding-right-m padding-xs content-align-right sum-cell ${column.locked && "readonly"}`}>
										{renderSumOfYear(year.entity.id, column.type, cells, props.category.subcategories, props.currency)}
									</div>}
								</CellContainer>
								{column.showFulltimeEquivalentColumn && <CellContainer />}
								{column.showDifferenceColumn &&
									<CellContainer noFocus={true}>
										{column.differenceCollapsed !== undefined && !column.differenceCollapsed &&
											<ComparerCell
												yearId={year.entity.id}
												columnType={column.type}
												currency={props.currency}
												sumCell={true}
												readonly={column.locked}
												subcategories={props.category.subcategories}
											/>
										}
									</CellContainer>
								}
								<CellContainer />
							</React.Fragment>
						)
					})}
				</React.Fragment>
			)}

			{showTotal && <CellContainer noFocus={true}>
				<SumCell subcategories={props.category.subcategories} currency={props.currency} />
			</CellContainer>}

			{showSingleFinancialReport &&
				<React.Fragment
					key={`category-row-year-cell-${props.category.id}-${singleFinancialReportYearId}-${BudgetType.financial}`}>
					<CellContainer noFocus={true}>
						<div
							className={`category-row-cell-content content-align-right padding-right-m padding-xs sum-cell ${isReadonly && "readonly"}`}>
							{renderSumOfYear(singleFinancialReportYearId, BudgetType.financial, cells, props.category.subcategories, props.currency)}
						</div>
					</CellContainer>
					<CellContainer noFocus={true}>
						<ComparerCell
							yearId={singleFinancialReportYearId}
							columnType={BudgetType.financial}
							currency={props.currency}
							sumCell={true}
							readonly={isReadonly}
							subcategories={props.category.subcategories}
						/>
					</CellContainer>
					<CellContainer />
				</React.Fragment>
			}

			<DndProvider backend={HTML5Backend}>
				<CellContext.Provider value={false}>
					{showSubCategories && props.category.subcategories.sort((a, b) => a.order - b.order).map((sub, subcategoryIndex) =>
						<SubcategoryRow
							subcategory={sub}
							category={props.category}
							years={shownYears}
							categoryNumber={props.categoryNumber}
							subcategoryNumber={sub.order + 1}
							key={`subcategory-row-${sub.id}`}
							currency={props.currency}
							institutions={props.institutions}
							showTotal={showTotal}
							verticalIndex={props.verticalIndex + subcategoryIndex}
							supplementChoiceEnabled={props.supplementChoiceEnabled}
							institutionReadonly={props.institutionReadonly}
							supplementReadonly={props.supplementReadonly}
						/>
					)}
				</CellContext.Provider>
			</DndProvider>
		</>
	);
}

export default CategoryRow;

const renderSumOfYear = (yearId: string, type: BudgetType, cells: ValueResponseModel[], subcategories: Subcategory[], currency: string) => {
	const sum = sumOfYear(yearId, type, cells, subcategories);

	if (sum === undefined) return "";

	return `${sum}`;
}
