interface IInstitutionPillProps {
  text: string;
  supplementRow?: boolean;
}

export const InstitutionPill = (props: IInstitutionPillProps) => {
  return (
    <>
      {props.text ? (
        <div className="pill-container">
          <div className={`badge-pill ${props.supplementRow ? "institution-pill-supplementrow" : "institution-pill"}`}>
            <h4 className="institution-pill-text">{props.text}</h4>
          </div>
        </div>
      ) : null}
    </>
  );
};
