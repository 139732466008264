import { BudgetPeriodResponseModel } from "./responseModels/budgetPeriodResponseModel";
import { BudgetPeriodVersionModel } from "./responseModels/budgetPeriodVersionModel";
import { ValueRequestModel } from "./requestModels/valueRequestModel";
import { SubcategoryRequestModel } from "./requestModels/subcategoryRequestModel";
import { AddRemoveTagRequestModel } from "./requestModels/addRemoveTagRequestModel";
import { BudgetPeriodSubmitRequest } from "./requestModels/budgetPeriodSubmitRequest";
import { SubcategoryResponseModel } from "./responseModels/subcategoryResponseModel";
import { MoveSubcategoryRequestModel } from "./requestModels/moveSubcategoryRequestModel";
import { ChangeLogResponseModel } from "./responseModels/changelogResponseModel";
import { UpdateInstitutionRequestModel } from "./requestModels/UpdateInstitutionRequestModel";

import { apiTags, emptyApi } from "./emptyApi";
import { UpdateInstitutionResponseModel } from "./responseModels/updateInstitutionResponseModel";

export const budgetPeriodApi = emptyApi.injectEndpoints({
  endpoints: (builder) => ({
    getBudgetPeriodIdByExternalApplicationId: builder.query<string, string>({
      query: (externalApplicationId) =>
        `api/grant?externalApplicationId=${externalApplicationId}`,
    }),
    getBudgetPeriodById: builder.query<
      BudgetPeriodResponseModel,
      { budgetPeriodId: string; order: string }
    >({
      query: ({ budgetPeriodId, order }) =>
        `api/grant/budgetPeriod?budgetPeriodId=${budgetPeriodId}&order=${order}`,
      keepUnusedDataFor: 0.0001,
    }),
    getVersions: builder.query<BudgetPeriodVersionModel[], string>({
      query: () => `api/grant/versions`,
    }),
    updateValue: builder.mutation<ValueRequestModel, ValueRequestModel>({
      query: ({ id, ...value }) => ({
        url: "api/BudgetPeriodValue",
        method: "PUT",
        body: value,
      }),
    }),
    addSubcategories: builder.mutation<
      SubcategoryResponseModel[],
      SubcategoryRequestModel
    >({
      query: ({ ...value }) => ({
        url: "api/BudgetPeriodSubCategory",
        method: "POST",
        body: value,
      }),
    }),
    deleteSubcategory: builder.mutation<string, string>({
      query: (value) => ({
        url: "api/BudgetPeriodSubCategory",
        method: "DELETE",
        body: { budgetPeriodSubCategoryId: value },
      }),
    }),
    addTagToSubcategory: builder.mutation<string, AddRemoveTagRequestModel>({
      query: ({ ...value }) => ({
        url: "api/BudgetPeriodSubCategory/addTag",
        method: "POST",
        body: value,
      }),
      invalidatesTags: () => [apiTags.tags],
    }),
    removeTagToSubcategory: builder.mutation<string, AddRemoveTagRequestModel>({
      query: ({ ...value }) => ({
        url: "api/BudgetPeriodSubCategory/removeTag",
        method: "POST",
        body: value,
      }),
      invalidatesTags: () => [apiTags.tags],
    }),
    moveSubcategory: builder.mutation<string, MoveSubcategoryRequestModel>({
      query: ({ ...value }) => ({
        url: "api/BudgetPeriodSubCategory/move",
        method: "POST",
        body: value,
      }),
    }),
    updateInstitution: builder.mutation<
      UpdateInstitutionResponseModel,
      UpdateInstitutionRequestModel
    >({
      query: ({ ...value }) => ({
        url: "api/BudgetPeriodSubCategory/updateInstitution",
        method: "PUT",
        body: value,
      }),
    }),
    submitBudget: builder.mutation<string, BudgetPeriodSubmitRequest>({
      query: ({ ...value }) => ({
        url: "api/BudgetPeriodSubmit/submit",
        method: "POST",
        body: value,
      }),
    }),
    getChangeLogByBudgetPeriodId: builder.query<
      ChangeLogResponseModel[],
      string
    >({
      query: (budgetPeriodId) =>
        `api/grant/changelog?budgetPeriodId=${budgetPeriodId}`,
    }),
  }),
});

export const {
  useGetBudgetPeriodIdByExternalApplicationIdQuery,
  useGetBudgetPeriodByIdQuery,
  useGetVersionsQuery,
  useUpdateValueMutation,
  useAddSubcategoriesMutation,
  useDeleteSubcategoryMutation,
  useAddTagToSubcategoryMutation,
  useRemoveTagToSubcategoryMutation,
  useMoveSubcategoryMutation,
  useUpdateInstitutionMutation,
  useSubmitBudgetMutation,
  useGetChangeLogByBudgetPeriodIdQuery,
} = budgetPeriodApi;

