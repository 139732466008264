import { useToggle } from "app/hooks";
import React, {useEffect} from "react";

export interface ICheckbox {
    id: string;
    text: string;
    value?: boolean;
    disabled?: boolean;
    onChange?: React.ChangeEventHandler<HTMLInputElement>
}

export const Checkbox = (props: ICheckbox) => {
    const [checked, toggleChecked] = useToggle(props.value === true);

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        toggleChecked(e.currentTarget.checked);
        if(props.onChange)
            props.onChange(e);
    }

    useEffect(() => {
        toggleChecked(props.value ?? false)
    }, [props.value]);

    return (
        <>
            <div className="form-group">
                <input id={props.id} type="checkbox" onChange={handleChange} checked={checked} disabled={props.disabled}/>
                <label htmlFor={props.id}>{props.text}</label>
            </div>
        </>
    )
}
export default Checkbox;
