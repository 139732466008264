import {StorageKeys} from "infrastructure/storageKeys";
import {ResponseResult } from "./responseResult";
import { ExcelDownloadRequestModel } from "services/api/requestModels/ExcelDownloadRequestModel";

export class HttpClientService  {
    async postUrlencoded(url: string) {
        const headers = new Headers();
        const token = localStorage.getItem(StorageKeys.token);

        headers.append('cache-control', 'no-cache');
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', 'Bearer ' + token);

        const response = await fetch(url, {
            headers,
            method: "post",
        });

        if (!response.ok){
            return new ResponseResult<string>(response);
        }

        return new ResponseResult<string>(response, await response.json() as string);
    }

    async get<T>(url: string): Promise<T> {
        const token = localStorage.getItem(StorageKeys.token);
        let headers = new Headers();
        headers.append('pragma', 'no-cache');
        headers.append('Authorization', 'Bearer ' + token);

        const response = await fetch(url, {
            method: "get",
            headers
        });

        return response.json();
    }

    async DownloadExcelFileWithName(url: string, fileName: string, requestModel: ExcelDownloadRequestModel) {
        const headers = new Headers();
        const token = localStorage.getItem(StorageKeys.token);

        headers.append('cache-control', 'no-cache');
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', 'Bearer ' + token);

        await fetch(url, {
            headers,
            method: "post",
            body: JSON.stringify(requestModel)
        }).then((res) => {
            return res.blob();
        })
        .then((blob) => {
            const href = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })

        return;
    }
}
