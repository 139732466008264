import React from "react";
import { Localizer } from "infrastructure/localization/localizer";
import { BudgetType, Subcategory, ValueResponseModel } from "services/api/responseModels/budgetPeriodResponseModel";
import { RootState } from "app/store";
import { useSelector } from "react-redux";
import { selectAllCells, sumOfYear, sumOfYearInt } from "./cellSlice";
import { selectShownSubcategories, SubcategoryState } from "./subcategorySlice";
import { selectAllYears, selectShownYears, selectSingleFinancialReportYearId, YearState } from "./yearSlice";
import ComparerCell from "./comparerCell";
import CellContainer from "./cellContainer";
import SumCell from "./sumCell";
import { GrantConstraints, selectGrantConstraints, showSingleFinancialReportColumn, showTotalColumn } from "./grantConstraintSlice";
import { getSumProjectSupplementPerYear } from "./projectSupplement";
import { formatWithThousandSeperators } from "../../../../../infrastructure/extensions/numberFormatter";

interface SummationRowProps {
  currency: string;
}

export function SummationRow(props: SummationRowProps) {
  const cells = useSelector<RootState>(state => selectAllCells(state.cells)) as ValueResponseModel[];
  const shownSubcategories = useSelector<RootState>(state => selectShownSubcategories(state)) as Subcategory[];
  const shownYears = useSelector<RootState>(state => selectShownYears(state.years)) as YearState[];
  const showTotal = useSelector<RootState>(state => showTotalColumn(state)) as boolean;
  const showSingleFinancialReport = useSelector<RootState>(state => showSingleFinancialReportColumn(state)) as boolean;
  const singleFinancialReportYearId = useSelector<RootState>(state => selectSingleFinancialReportYearId(state)) as string;
  const { isReadonly, budgetType } = useSelector<RootState>(state => selectGrantConstraints(state)) as GrantConstraints;

  const yearsToCalculateFrom = useSelector<RootState>(state => {
    if (showSingleFinancialReport) {
      const allYears = selectAllYears(state.years);
      return allYears.filter(y => !y.entity.removed);
    } else {
      return selectShownYears(state.years).filter(y => !y.entity.removed);
    }
  }) as YearState[];

  const allProjectSupplementSums = React.useMemo(
    () =>
      yearsToCalculateFrom.flatMap(year =>
        year.columns.flatMap(column => ({
          yearId: year.entity.id,
          type: column.type,
          sum: getSumProjectSupplementPerYear(year.entity.id, column.type, shownSubcategories)
        }))
      ),
    [yearsToCalculateFrom, shownSubcategories]
  );

  const totalProjectSupplementSum = React.useMemo(
    () =>
      allProjectSupplementSums
        .filter(ps => ps.type === budgetType)
        .map(sum => sum.sum)
        .reduce((a, b) => a + b, 0),
    [allProjectSupplementSums, budgetType]
  );

  return (
    <>
      <div className="category-row-cell padding-xs pl-3 border-left border-bottom sidebar-element summation-sidebar-row">
        <h5>{Localizer.total()}</h5>
      </div>
      <div className="category-row-cell border-bottom-left summation-sidebar-custom-row" />
      <div className="category-row-cell border-bottom-left summation-sidebar-custom-row" />
      {shownYears.map(year =>
        <React.Fragment key={`summation-year-${year.entity.id}`}>
          {year.columns.map(column => (
            <React.Fragment key={`summation-year-cell-${year.entity.id}-${column.type}`}>
              <CellContainer summationCell={true} noFocus={true}>
                {!column.budgetCollapsed &&
                  <div className={`category-row-cell-content padding-right-m padding-xs content-align-right sum-cell ${column.locked && "readonly"}`}>
                    {renderYearSum(year.entity.id, column.type, cells, shownSubcategories)}
                  </div>}
              </CellContainer>
              {column.showFulltimeEquivalentColumn && <CellContainer summationCell={true} />}
              {column.showDifferenceColumn &&
                <CellContainer summationCell={true} noFocus={true}>
                  {column.differenceCollapsed !== undefined && !column.differenceCollapsed &&
                    <ComparerCell
                      yearId={year.entity.id}
                      columnType={column.type}
                      currency={props.currency}
                      subcategories={shownSubcategories}
                      sumCell={true}
                      readonly={column.locked}
                    />
                  }
                </CellContainer>}
              <CellContainer summationCell={true} />
            </React.Fragment>
          ))}
        </React.Fragment>
      )}
      {showTotal && <CellContainer summationCell={true} noFocus={true}>
        <SumCell subcategories={shownSubcategories} currency={props.currency} isTotalRow={true} />
      </CellContainer>}
      {showSingleFinancialReport && singleFinancialReportYearId &&
        <React.Fragment key={`summation-year-cell-${singleFinancialReportYearId}-${BudgetType.financial}`}>
          <CellContainer summationCell={true} noFocus={true}>
            <div className={`category-row-cell-content content-align-right padding-right-m padding-xs sum-cell ${isReadonly && "readonly"}`}>
              {formatWithThousandSeperators(getYearSumWithoutSupplement(singleFinancialReportYearId, BudgetType.financial, cells, shownSubcategories) + totalProjectSupplementSum)}
            </div>
          </CellContainer>
          <CellContainer summationCell={true} noFocus={true}>
            <ComparerCell
              yearId={singleFinancialReportYearId}
              columnType={BudgetType.financial}
              currency={props.currency}
              subcategories={shownSubcategories}
              sumCell={true}
              readonly={isReadonly}
            />
          </CellContainer>
          <CellContainer summationCell={true} />
        </React.Fragment>
      }
    </>
  );
}

export default SummationRow;

const renderYearSum = (yearId: string, type: BudgetType, cells: ValueResponseModel[], subcategories: Subcategory[]) => {
  const sum = sumOfYearInt(yearId, type, cells, subcategories);

  if (sum === undefined) return "";

  const projectSupplementSum = getSumProjectSupplementPerYear(yearId, type, subcategories);
  const totalSum = sum + projectSupplementSum;
  const formattedSum = formatWithThousandSeperators(totalSum);

  return `${formattedSum}`;
}

const getYearSumWithoutSupplement = (yearId: string, type: BudgetType, cells: ValueResponseModel[], subcategories: Subcategory[]) => {
  const sum = sumOfYearInt(yearId, type, cells, subcategories);

  if (sum === undefined) return 0;

  return sum;
}
