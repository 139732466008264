import { Option } from "../../core/dropdown/selectComponent";
import { apiTags, emptyApi } from "./emptyApi";
import { TagResponseModel } from "./responseModels/tagResponseModel";

export const tagApi = emptyApi.injectEndpoints({
    endpoints: (builder) => ({
        getTags: builder.query<TagResponseModel[], string>({
            query: (search ) => `api/Tag?search=${search}`,
            providesTags: [apiTags.tags]
        }),
    })
})

export const {
    useGetTagsQuery,
    useLazyGetTagsQuery,
} = tagApi

export const mapToOption = (tag : TagResponseModel) : Option => {
    return {
        value: tag.id,
        label: tag.text,
        isFixed: false,
        selected: false
    }
}

