import { RootState } from "app/store";
import { useDispatch, useSelector } from "react-redux";
import SelectComponent, { Option } from "../../../../../core/dropdown/selectComponent";
import { mapToOption, useGetTagsQuery, useLazyGetTagsQuery } from "../../../../../services/api/tagApi";
import { useAddTagToSubcategoryMutation, useRemoveTagToSubcategoryMutation } from "services/api/budgetPeriodApi";
import { GrantConstraints, selectGrantConstraints } from "./grantConstraintSlice";
import { tagAddedToSubcategory, tagRemovedFromSubcategory } from "./tagSlice";

interface SubcategoryTagsProps {
  subcategoryId: string;
}

export const SubcategoryTags = (props: SubcategoryTagsProps) => {
  const dispatch = useDispatch();
  const [removeTagToSubcategory] = useRemoveTagToSubcategoryMutation();
  const [addTagToSubcategory] = useAddTagToSubcategoryMutation();
  const { customSubcategoriesCollapsed, isReadonly, isMultiline } = useSelector<RootState>(state => selectGrantConstraints(state)) as GrantConstraints;
  const [getTagsLazy] = useLazyGetTagsQuery();
  const { data: tags } = useGetTagsQuery("");

  const getAllAvailableTags = () : Promise<Option[]> => {
    return new Promise<Option[]>(resolve => {
      getTagsLazy("").unwrap().then(results => {
        const tags = results.map((result => {
          return mapToOption(result);
        }));

        resolve(tags);
      });
    });
  }

  const handleRemove = async (option: Option) => {
    await removeTagToSubcategory({
      tagName: option.label,
      budgetPeriodSubCategoryId: props.subcategoryId,
    }).unwrap();

    dispatch(tagRemovedFromSubcategory({ tagId: option.value, subcategoryId: props.subcategoryId }));
  };

  const handleAdd = async (option: Option) => {
    await addTagToSubcategory({
      tagName: option.label,
      budgetPeriodSubCategoryId: props.subcategoryId,
    }).unwrap();

    dispatch(tagAddedToSubcategory({ id: option.value, subcategoryId: props.subcategoryId, name: option.label }));
  };

  const renderDropdown = () => {
    if (!tags) return;
    if (isReadonly)  return tags.filter(t => t.subcategoryIds.includes(props.subcategoryId)).map(o => o.text).join(", ");
    return (
      <SelectComponent
        defaultSelectedOptions={tags.filter(t => t.subcategoryIds.includes(props.subcategoryId)).map(tag => mapToOption(tag))}
        avaiableOptionsPromise={getAllAvailableTags}
        handleAdd={handleAdd}
        handleRemove={handleRemove}
        shouldTruncate={!isMultiline}
        asActionButton={false}
      />
    );
  };

  const extraClasses = customSubcategoriesCollapsed ? "d-flex justify-content-center" : "";
  return <div className={`subcategory-tags ${extraClasses}`}>
    {renderDropdown()}
  </div>;
}

export default SubcategoryTags;
