import {useCallback, useState} from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from './store';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useToggle = (initialState: boolean): [boolean, (value?: boolean) => void] => {
    const [isToggled, setIsToggled] = useState(initialState);
    const toggle = useCallback((value?: boolean) => setIsToggled(typeof value === "boolean" ? value : !isToggled), [isToggled]);
    return [isToggled, toggle];
};
