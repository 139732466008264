import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import {RootState} from "../../../../../app/store";
import {BudgetPeriodVersionModel} from "../../../../../services/api/responseModels/budgetPeriodVersionModel";
import {BudgetType} from "../../../../../services/api/responseModels/budgetPeriodResponseModel";
import {Status} from "../../../../../models/enums/status";

export interface VersionState {
  selectedVersion: BudgetPeriodVersionModel
  isCurrentVersion: boolean,
  versions:  BudgetPeriodVersionModel[] | undefined
  isApprovedText: boolean;
}

const initialState = { isApprovedText: false } as VersionState

const versionSlice = createSlice({
  name: 'versionSlice',
  initialState,
  reducers: {
    setIsCurrentVersion(state, action: PayloadAction<boolean>) {
      state.isCurrentVersion = action.payload;
    },
    setOrder(state, action: PayloadAction<BudgetPeriodVersionModel>) {
      state.selectedVersion = action.payload

      state.isApprovedText = state.selectedVersion.status === Status.Approved;

      if(state.versions) {
        state.versions = state.versions.sort(x=>x.order);
        state.isCurrentVersion = state.versions[state.versions.length-1].order === state.selectedVersion.order;
      }
    },
    setVersions(state, action: PayloadAction<BudgetPeriodVersionModel[] | undefined>) {
      state.versions = action.payload;
    }
  },
})

export const {setOrder,setVersions, setIsCurrentVersion } = versionSlice.actions
export default versionSlice.reducer
export const versionState = (state: RootState) => state;

