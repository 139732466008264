import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import {
  DirectAdministrativeExpenseCategory,
  IndirectAdministrativeExpenseCategory,
} from "infrastructure/extensions/categoryNameAsserter";
import { Status } from "models/enums/status";
import { selectAllCells } from "./cellSlice";
import {
  BudgetType,
  Institution,
  Subcategory,
  ValueResponseModel,
  YearStatus,
} from "services/api/responseModels/budgetPeriodResponseModel";
import {
  selectSubcategoriesByName,
  selectCostSubcategories,
} from "./subcategorySlice";
import { budgetSubmitted } from "pages/dashboard/components/Grid/actions";
import { FinancialReportType } from "models/enums/financialReportType";
import { Action } from "models/enums/action";
import { selectAllYears, YearState } from "./yearSlice";
import { idLabelIsDefined } from "../grid";

export type GrantConstraints = {
  isReadonly: boolean;
  budgetPeriodId: string;
  budgetPeriodStatus: Status;
  budgetType: BudgetType;
  minAmount: number;
  maxAmount: number;
  maxCategoryDeviation: number;
  maxSubcategoryDeviation: number;
  financialReportYearId?: string;
  subcategoriesForGrant: Subcategory[];
  customSubcategoriesCollapsed: boolean;
  institutionCollapsed: boolean;
  maxDirectAdminExpense: number;
  maxIndirectAdminExpense: number;
  financialReportType: FinancialReportType;
  actionEnum: Action;
  isBallpark: boolean;
  isMultiline: boolean;
  isLatestVersion: boolean;
};

export type AdministrationExpense = {
  institution: Institution | undefined;
  budgetExceededBy: number;
};

const initialState: GrantConstraints = {
  isReadonly: true,
  budgetPeriodId: "",
  budgetPeriodStatus: Status.Draft,
  budgetType: BudgetType.budget,
  minAmount: 0,
  maxAmount: 0,
  maxCategoryDeviation: 0,
  maxSubcategoryDeviation: 0,
  subcategoriesForGrant: [],
  customSubcategoriesCollapsed: true,
  institutionCollapsed: false,
  maxDirectAdminExpense: 0,
  maxIndirectAdminExpense: 0,
  financialReportType: FinancialReportType.Annual,
  actionEnum: Action.View,
  isBallpark: false,
  isMultiline: false,
  isLatestVersion: true,
};

const sumBudgetReducer = (
  accumulator: number,
  currentValue: ValueResponseModel
) => accumulator + (currentValue.budget ?? 0);

export const GrantConstraintSlice = createSlice({
  name: "grantConstraints",
  initialState: initialState,
  reducers: {
    constraintsUpdated: (state, action: PayloadAction<GrantConstraints>) => {
      const {
        isReadonly,
        budgetPeriodId,
        budgetType,
        minAmount,
        maxAmount,
        maxCategoryDeviation,
        maxSubcategoryDeviation,
        financialReportYearId,
        subcategoriesForGrant,
        budgetPeriodStatus,
        customSubcategoriesCollapsed,
        institutionCollapsed,
        maxDirectAdminExpense,
        maxIndirectAdminExpense,
        financialReportType,
        actionEnum,
        isBallpark,
        isMultiline,
        isLatestVersion,
      } = action.payload;
      state.isReadonly = isReadonly;
      state.budgetPeriodStatus = budgetPeriodStatus;
      state.budgetPeriodId = budgetPeriodId;
      state.budgetType = budgetType;
      state.minAmount = minAmount;
      state.maxAmount = maxAmount;
      state.maxCategoryDeviation = maxCategoryDeviation;
      state.maxSubcategoryDeviation = maxSubcategoryDeviation;
      state.financialReportYearId = financialReportYearId;
      state.subcategoriesForGrant = subcategoriesForGrant;
      state.customSubcategoriesCollapsed = customSubcategoriesCollapsed;
      state.institutionCollapsed = institutionCollapsed;
      state.maxDirectAdminExpense = maxDirectAdminExpense;
      state.maxIndirectAdminExpense = maxIndirectAdminExpense;
      state.financialReportType = financialReportType;
      state.actionEnum = actionEnum;
      state.isBallpark = isBallpark;
      state.isMultiline = isMultiline;
      state.isLatestVersion = isLatestVersion;
    },
    budgetPeriodIdUpdated: (state, action: PayloadAction<string>) => {
      state.budgetPeriodId = action.payload;
    },
    customCategoriesToggled: (state) => {
      state.customSubcategoriesCollapsed = !state.customSubcategoriesCollapsed;
    },
    institutionColumnToggled: (state) => {
      state.institutionCollapsed = !state.institutionCollapsed;
    },
    isMultilineUpdate: (state, action: PayloadAction<boolean>) => {
      state.isMultiline = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(budgetSubmitted, (state) => {
      state.budgetPeriodStatus = Status.Submitted;
      state.isReadonly = true;
    });
  },
});

export const {
  constraintsUpdated,
  budgetPeriodIdUpdated,
  customCategoriesToggled,
  institutionColumnToggled,
  isMultilineUpdate,
} = GrantConstraintSlice.actions;
export const grantConstraintsState = (state: RootState) =>
  state.grantConstraints;
export default GrantConstraintSlice.reducer;
export const selectGrantConstraints = (state: RootState) =>
  state.grantConstraints;
export const selectIsBallPark = (state: RootState) =>
  state.grantConstraints.isBallpark;
export const showTotalColumn = (state: RootState) =>
  state.grantConstraints.budgetType === 1 || 
  state.grantConstraints.budgetType === 3 || 
  // !state.grantConstraints.financialReportYearId ||
  state.grantConstraints.financialReportType === FinancialReportType.Single;
export const showSingleFinancialReportColumn = (state: RootState) =>
  state.grantConstraints.financialReportType === FinancialReportType.Single &&
  (state.grantConstraints.actionEnum === Action.FinancialReport ||
    selectAllYears(state.years).some(
      (y) => y.entity.status === YearStatus.closed
    ));

export const isMaxDirectAdminExpenseExceeded = (
  state: RootState,
  budgetType: BudgetType,
  addFakeInstitution: boolean
): AdministrationExpense[] => {
  const directAdminExpenseSubcategory = selectSubcategoriesByName(
    state.subcategories,
    DirectAdministrativeExpenseCategory
  );
  const indirectAdminExpenseSubcategory = selectSubcategoriesByName(
    state.subcategories,
    IndirectAdministrativeExpenseCategory
  );

  if (!directAdminExpenseSubcategory) return [];

  const { maxDirectAdminExpense, maxIndirectAdminExpense } =
    selectGrantConstraints(state) as GrantConstraints;
  const cells = selectAllCells(state.cells);
  const costSubcategories = selectCostSubcategories(state.subcategories);
  const allYears = selectAllYears(state.years) as YearState[];

  return doesBudgetExceedMaxDirectAdminExpense(
    cells,
    costSubcategories,
    directAdminExpenseSubcategory,
    indirectAdminExpenseSubcategory,
    maxDirectAdminExpense,
    maxIndirectAdminExpense,
    budgetType,
    addFakeInstitution,
    allYears
  );
};

export const isMaxIndirectAdminExpenseExceeded = (
  state: RootState,
  budgetType: BudgetType,
  addFakeInstitution: boolean
) => {
  const indirectAdminExpenseSubcategory = selectSubcategoriesByName(
    state.subcategories,
    IndirectAdministrativeExpenseCategory
  );

  if (!indirectAdminExpenseSubcategory) return false;

  const { maxIndirectAdminExpense } = selectGrantConstraints(
    state
  ) as GrantConstraints;
  const cells = selectAllCells(state.cells);
  const costSubcategories = selectCostSubcategories(state.subcategories);
  const allYears = selectAllYears(state.years) as YearState[];

  return doesBudgetExceedMaxIndirectAdminExpense(
    cells,
    costSubcategories,
    indirectAdminExpenseSubcategory,
    maxIndirectAdminExpense,
    budgetType,
    addFakeInstitution,
    allYears
  );
};

const doesBudgetExceedMaxDirectAdminExpense = (
  cells: ValueResponseModel[],
  costSubcategories: Subcategory[],
  directAdminExpenseSubcategories: Subcategory[],
  indirectAdminExpenseSubcategories: Subcategory[],
  maxDirectAdminExpense: number,
  maxIndirectAdminExpense: number,
  budgetType: BudgetType,
  addFakeInstitution: boolean,
  allYears: YearState[]
): AdministrationExpense[] => {
  const institutions = [
    ...costSubcategories,
    ...directAdminExpenseSubcategories,
    ...indirectAdminExpenseSubcategories,
  ]
    .map((s) => s.selectedInstitution)
    .filter(idLabelIsDefined)
    .filter((s) => s !== null)
    .filter((value, index, self) => self.indexOf(value) === index);

  let institutionIds = institutions
    .map((s) => s.id)
    .filter(idLabelIsDefined)
    .filter((value, index, self) => self.indexOf(value) === index);

  if (addFakeInstitution) {
    institutionIds = ["FAKE"];
  }
  return institutionIds.map((id) => {
    const addAll = id === "FAKE";
    const subcategories = costSubcategories.filter(
      (s) => addAll || s.selectedInstitution?.id === id
    );

    const institution = institutions.find((s) => s.id === id);
    const directAdminSubcategories = directAdminExpenseSubcategories.filter(
      (s) => addAll || s.selectedInstitution?.id === id
    );
    const indirectAdminSubcategories = indirectAdminExpenseSubcategories.filter(
      (s) => addAll || s.selectedInstitution?.id === id
    );

    const subcategoriesYears = subcategories.flatMap((s) => s.values);

    const directAdminSubcategoriesYears = directAdminSubcategories
      .flatMap((s) => s.values)
      .filter((v, _, arr) => arr.length === 1 || v.type === budgetType);
    const indirectAdminSubcategoriesYears = indirectAdminSubcategories
      .flatMap((s) => s.values)
      .filter((v, _, arr) => arr.length === 1 || v.type === budgetType);

    const sumOfDirectAdminExpense = directAdminSubcategoriesYears.reduce(
      sumBudgetReducer,
      0
    );

    const sumOfIndirectAdminExpense = indirectAdminSubcategoriesYears.reduce(
      sumBudgetReducer,
      0
    );

    const totalBudget = subcategoriesYears.reduce(sumBudgetReducer, 0);
    const adminExpenses =
      ((totalBudget + sumOfIndirectAdminExpense) /
        (100 - maxDirectAdminExpense)) *
      maxDirectAdminExpense;
    const budgetExceededBy = sumOfDirectAdminExpense - adminExpenses;

    return { institution, budgetExceededBy };
  });
};
const doesBudgetExceedMaxIndirectAdminExpense = (
  cells: ValueResponseModel[],
  costSubcategories: Subcategory[],
  indirectAdminExpenseSubcategory: Subcategory[],
  maxIndirectAdminExpense: number,
  budgetType: BudgetType,
  addFakeInstitution: boolean,
  allYears: YearState[]
) => {
  const institutions = [
    ...costSubcategories,
    ...indirectAdminExpenseSubcategory,
  ]
    .map((s) => s.selectedInstitution)
    .filter(idLabelIsDefined)
    .filter((s) => s !== null)
    .filter((value, index, self) => self.indexOf(value) === index);

  let institutionIds = institutions
    .map((s) => s.id)
    .filter(idLabelIsDefined)
    .filter((value, index, self) => self.indexOf(value) === index);


  if (addFakeInstitution) {
    institutionIds = ["FAKE"];
  }
  return institutionIds.map((id) => {
    const addAll = id === "FAKE";
    const subcategories = costSubcategories.filter(
      (s) => addAll || s.selectedInstitution?.id === id
    );
    const institution = institutions.find((s) => s.id === id);
    const indirectAdminSubcategories = indirectAdminExpenseSubcategory.filter(
      (s) => addAll || s.selectedInstitution?.id === id
    );

    const subcategoriesYears = subcategories
      .flatMap((s) => s.values)
      .filter((v, _, arr) => arr.length === 1 || v.type === budgetType);

    const indirectAdminSubcategoriesYears = indirectAdminSubcategories
      .flatMap((s) => s.values)
      .filter((v, _, arr) => arr.length === 1 || v.type === budgetType);

    const sumOfIndirectAdminExpense = indirectAdminSubcategoriesYears.reduce(
      sumBudgetReducer,
      0
    );

    const totalBudget = subcategoriesYears.reduce(sumBudgetReducer, 0);

    const adminExpenses =
      (totalBudget * (maxIndirectAdminExpense / 100))

    const budgetExceededBy = sumOfIndirectAdminExpense - adminExpenses;

    return { institution, budgetExceededBy };
  });
};

