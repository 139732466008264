import { Action } from "../../models/enums/action";
import { ApiService } from "../../services/api/apiService";
import { Button } from "core/button/button";
import { DropdownButton } from "../dropdownButton/dropdownButton";
import { budgetSubmitted } from "../../pages/dashboard/components/Grid/actions";
import { Popover } from "core/popover/popover";
import { Localizer } from "../../infrastructure/localization/localizer";
import { ExcelDownloadRequestModel } from "../../services/api/requestModels/ExcelDownloadRequestModel";
import { Icon } from "../icon/icon";
import { ReactComponent as ColumnIcon } from "content/icons/column.svg";
import Modal from "core/modal/modal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import {
	budgetPeriodIdUpdated,
	AdministrationExpense,
	GrantConstraints,
	isMaxIndirectAdminExpenseExceeded,
	isMaxDirectAdminExpenseExceeded,
	isMultilineUpdate,
	selectGrantConstraints,
	showSingleFinancialReportColumn
} from "../../pages/dashboard/components/Grid/components/grantConstraintSlice";
import { useAuth } from "../../infrastructure/routing/auth";
import { filterState, selectedFilterTags, selectedYears } from "../../pages/dashboard/components/Filter/filterSlice";
import { useGetVersionsQuery, useSubmitBudgetMutation } from "../../services/api/budgetPeriodApi";
import { useEffect, useMemo } from "react";
import { getYearsToDelete, getCellsToSave, selectAllYears, YearState } from "../../pages/dashboard/components/Grid/components/yearSlice";
import {
	anyCategoriesWithIssues,
	anyCellsWithFteAbove100,
	anyCellsWithIssues,
	anyCellsWithRequired,
	selectBudgetTotal
} from "../../pages/dashboard/components/Grid/components/cellSlice";
import { UserModel } from "../../models/userModel";
import {
	anySubcategoriesBallpark,
	selectAllSubcategories,
	SubcategoryState
} from "../../pages/dashboard/components/Grid/components/subcategorySlice";
import { formatWithThousandSeperators } from "../../infrastructure/extensions/numberFormatter";
import ReactDOMServer from "react-dom/server";
import { useAppSelector, useToggle } from "../../app/hooks";
import sprite from "../../content/illustrations/sprite.symbol.svg";
import {
	CustomCategoriesFilter
} from "../../pages/dashboard/components/Filter/components/customCategoriesFilter/customCategoriesFilter";
import { YearColumnFilter } from "./yearColumnFilter";
import { setOrder, setVersions, VersionState } from "../../pages/dashboard/components/Grid/components/versionSlice";
import { Institution } from "../../services/api/responseModels/budgetPeriodResponseModel";
import {
	AdministrationCategory,
	DisseminationCategory,
	OperationCategory, SalaryCategory
} from "../../infrastructure/extensions/categoryNameAsserter";
import { getSumProjectSupplementPerYear } from "pages/dashboard/components/Grid/components/projectSupplement";
import { BudgetType, Subcategory, ValueResponseModel } from "services/api/responseModels/budgetPeriodResponseModel";
import { ValueRequestModel } from 'services/api/requestModels/valueRequestModel';

interface IActionButtonsProps {
	projectTitle: string;
	budgetPeriod_Currency: string;
	projectSupplementEnabled: boolean;
	supplementChoiceEnabled: boolean;
	institutions?: Institution[];
}

export const ActionButtons = (props: IActionButtonsProps) => {
	const dispatch = useDispatch();
	const [submitBudget] = useSubmitBudgetMutation();
	const [showModal, toggleModal] = useToggle(false);
	const [showWarning, toggleWarning] = useToggle(false);
	const { user } = useAuth();
	const action = (user as UserModel).action;
	const { budgetPeriodId, budgetType, isReadonly, minAmount, maxAmount, financialReportYearId, maxCategoryDeviation, isMultiline } = useSelector<RootState>(state => selectGrantConstraints(state)) as GrantConstraints;
	const selectedYearIds = useSelector<RootState>((state: RootState) => selectedYears(state.filters)) as string[];
	const selectedTags = useSelector<RootState>((state) => selectedFilterTags(state)) as string[];
	const { data: versions, isLoading } = useGetVersionsQuery(budgetPeriodId, { skip: !budgetPeriodId });
	const allYears = useSelector<RootState>(state => selectAllYears(state.years)) as YearState[];
	const { selectedVersion, isCurrentVersion } = useSelector<RootState>(state => state.versionSlice) as VersionState;
	const totalNoSP = useSelector<RootState>(state => selectBudgetTotal(state, isCurrentVersion)) as number;
	const allSubcategories = useSelector<RootState>(state => selectAllSubcategories(state.subcategories)) as SubcategoryState[];
	const allPSOnBudget = useMemo(() => allYears.map(year => ({ yearId: year.entity.id, sum: getSumProjectSupplementPerYear(year.entity.id, budgetType, allSubcategories.map(sub => sub.entity)) })).map(sum => sum.sum).reduce((a, b) => a + b, 0), [allYears, budgetType, allSubcategories]);
  const budgetTotal = useMemo(() => totalNoSP + allPSOnBudget, [allPSOnBudget, totalNoSP]);
	const belowMinimumAmount = budgetTotal < minAmount;
	const belowMinimumAmountBy = minAmount - budgetTotal;
	const allSubcategoriesInSalary = allSubcategories.filter((s) => s.entity.category.name === SalaryCategory);
	const institutionsSelectedInSalary = useMemo(() => allSubcategoriesInSalary.every(subcategory => subcategory.entity.selectedInstitution), [allSubcategoriesInSalary]);
	const supplementsSelectedInSalary = useMemo(() => {
		// If supplement choice is not enabled, we don't need to check if supplements are selected
		if (!props.supplementChoiceEnabled) return true;
		return allSubcategoriesInSalary.every(subcategory => {
			const { selectedSupplement, selectedInstitution } = subcategory.entity;
			const institutionHasSingleEmptyFteType = selectedInstitution?.ftE_Types.length === 1 && selectedInstitution.ftE_Types[0] === '';
			return selectedSupplement || institutionHasSingleEmptyFteType;
		});
	}, [allSubcategoriesInSalary, props.supplementChoiceEnabled]);
	const hasNoInstitutions = useMemo(() => props.institutions?.length === 0, [props.institutions?.length]);
	const oldGrant = useMemo(() => !props.supplementChoiceEnabled && !props.projectSupplementEnabled, [props.supplementChoiceEnabled, props.projectSupplementEnabled]);
	console.log("oldGrant value " + oldGrant);
	const institutionsSelectedInAdministration = useMemo(() => oldGrant || hasNoInstitutions || allSubcategories.filter((s) => s.entity.category.name === AdministrationCategory).every(subcategory => subcategory.entity.selectedInstitution), [allSubcategories, hasNoInstitutions, oldGrant]);
	const institutionsSelectedInOperation = useMemo(() => oldGrant || hasNoInstitutions || allSubcategories.filter((s) => s.entity.category.name === OperationCategory).every(subcategory => subcategory.entity.selectedInstitution), [allSubcategories, hasNoInstitutions, oldGrant]);
	const institutionsSelectedInDissemination = useMemo(() => oldGrant || hasNoInstitutions || allSubcategories.filter((s) => s.entity.category.name === DisseminationCategory).every(subcategory => subcategory.entity.selectedInstitution), [allSubcategories, hasNoInstitutions, oldGrant]);


	const hasInstitutionsWithSupplementInSalary = (institutionsSelectedInSalary && supplementsSelectedInSalary) || !props.projectSupplementEnabled;
	const institutionsAndSupplementsSelectedInSalary = hasNoInstitutions || hasInstitutionsWithSupplementInSalary;
	const removedYearIds = useSelector<RootState>(state => getYearsToDelete(state)) as string[];
	const valueCells = useSelector<RootState>(state => getCellsToSave(state)) as ValueRequestModel[];
	const hasTagFilters = useAppSelector(filterState).hasTagFiltersApplied as boolean;
	const hasYearFilters = useAppSelector(filterState).hasYearFiltersApplied as boolean;
	const hasSubcategoryIssues = useSelector<RootState>((state) => anyCellsWithIssues(state)) as boolean;
	const hasCategoriesWithIssues = useSelector<RootState>(state => anyCategoriesWithIssues(state, allSubcategories.map(s => s.entity), allYears, maxCategoryDeviation)) as boolean;
	const hasEmptyRequiredCells = useSelector<RootState>((state) => anyCellsWithRequired(state, action)) as boolean;
	const hasBallparkSubcategories = useSelector<RootState>((state) => anySubcategoriesBallpark(state)) as boolean;
	const hasFteValuesAbove100 = useSelector<RootState>((state) => anyCellsWithFteAbove100(state, action)) as boolean;
	const maxAmountExceeded = budgetTotal > maxAmount;
	const maxAmountExceededBy = budgetTotal - maxAmount;
	const maxIndirectAdminExpenseExceededBy = useSelector<RootState>((state) => isMaxIndirectAdminExpenseExceeded(state, budgetType, oldGrant)) as AdministrationExpense[];
	const maxDirectAdminExpenseExceededBy = useSelector<RootState>((state) => isMaxDirectAdminExpenseExceeded(state, budgetType, oldGrant)) as AdministrationExpense[];
	const brokeConstraints = useMemo(() => {
		return maxAmountExceeded ||
			belowMinimumAmount ||
			hasEmptyRequiredCells ||
			maxIndirectAdminExpenseExceededBy.some(s => s.budgetExceededBy > 0) ||
			maxDirectAdminExpenseExceededBy.some(s => s.budgetExceededBy > 0) ||
			belowMinimumAmountBy > 0 ||
			maxAmountExceededBy > 0 ||
			hasFteValuesAbove100 ||
			!institutionsAndSupplementsSelectedInSalary ||
			!institutionsSelectedInAdministration ||
			!institutionsSelectedInOperation ||
			!institutionsSelectedInDissemination;
	}, [maxAmountExceeded, belowMinimumAmount, hasEmptyRequiredCells, maxIndirectAdminExpenseExceededBy, maxDirectAdminExpenseExceededBy, belowMinimumAmountBy, maxAmountExceededBy, hasFteValuesAbove100, institutionsAndSupplementsSelectedInSalary, institutionsSelectedInAdministration, institutionsSelectedInOperation, institutionsSelectedInDissemination]);
	const showSingleFinancialReport = useSelector<RootState>(state => showSingleFinancialReportColumn(state)) as boolean;

  useEffect(() => {
    if (selectedVersion === null || selectedVersion === undefined) {
      var newSelectedVersion = versions?.filter(v => budgetPeriodId === v.budgetPeriodId);
      var selectedVersionSorted = newSelectedVersion?.sort(x => x.order);
      var selected = undefined;
      if (selectedVersionSorted) {
        selected = selectedVersionSorted.length === 1 || user?.action === Action.BudgetRevision ? selectedVersionSorted[0] : selectedVersionSorted[selectedVersionSorted.length - 1];
      }
      if (selected) {
        dispatch(setVersions(versions));
        dispatch(setOrder(selected));
      }
    }
    return disposePopover();
  }, [budgetPeriodId, versions, selectedVersion, user, dispatch]);



	const downloadExcel = async () => {
		const apiService = new ApiService();
		const requestModel: ExcelDownloadRequestModel = {
			budgetPeriodId: budgetPeriodId,
			selectedYearIds: selectedYearIds,
			selectedTagNames: selectedTags
		}

		await apiService.getExcel(`${props.projectTitle}.xlsx`, requestModel);
	};

	 



	// var test5 = valueCells;

	// const test = useSelector<RootState>(state => state.cells) as EntityState<ValueResponseModel, string>[];

	const sendToNorma = async () => {
		await submitBudget({ budgetPeriodId: budgetPeriodId, financialReportYearId: financialReportYearId, removedYearIds: removedYearIds, hasIssue: hasSubcategoryIssues || hasCategoriesWithIssues, isSubmittedWithBallPark: hasBallparkSubcategories, value: valueCells }).unwrap();
		toggleModal();
		var test5 = valueCells;
		console.log("length is: " + test5.length);
		// const test3 = valueCells;
		dispatch(budgetSubmitted());
	}

	const renderDisabledBtnWithPopover = () => {
		const popoverContent = getPopoverContent({
			hasAnyFilters: hasTagFilters || hasYearFilters,
			maxAmountExceeded,
			maxAmountExceededTotal: maxAmountExceededBy,
			belowMinimumAmount,
			minAmountExceededTotal: belowMinimumAmountBy,
			hasEmptyRequiredCells,
			maxIndirectAdminExpenseExceededBy,
			maxDirectAdminExpenseExceededBy,
			hasFteValuesAbove100,
			hasInstitutionsInSalary: institutionsAndSupplementsSelectedInSalary,
			hasInstitutionsInAdministration: institutionsSelectedInAdministration,
			hasInstitutionsInOperation: institutionsSelectedInOperation,
			hasInstitutionsInDissemination: institutionsSelectedInDissemination,
			currency: props.budgetPeriod_Currency

		});

		if (!popoverContent) {
			return (
				<Button id="send-to-norma-btn" classes="action-button margin-left-s submit-button" styling="btn-primary" type="button" disabled={true}>
					{Localizer.submit()}
				</Button>
			);
		}

		return (
			<Popover classes="btn-group" content={popoverContent}>
				<Button id="send-to-norma-btn" classes="action-button margin-left-s submit-button" styling="btn-primary" type="button" disabled={true}>
					{Localizer.submit()}
				</Button>
			</Popover>
		);
	};

	const handleRowClick = () => {
		dispatch(isMultilineUpdate(!isMultiline))
	}

	const renderSendToNormaBtn = () => {
		//if (isReadonly) return <></>;

		// return (!financialReportYearId && brokeConstraints) ||
		// 	(!financialReportYearId && hasTagFilters) ||
		// 	(!financialReportYearId && hasYearFilters) ||
		// 	(financialReportYearId && hasEmptyRequiredCells) ||
		// 	hasFteValuesAbove100
		// 	? 
		// 	renderDisabledBtnWithPopover()
		// 	: 
			return (brokeConstraints || hasEmptyRequiredCells)
			?
			renderDisabledBtnWithPopover()
			:
				(<>
					<Button id="send-to-norma-btn" classes="action-button margin-left-s submit-button" styling="btn-primary" type="button" onClick={(removedYearIds.length > 0) ? toggleWarning : sendToNorma}>
						{Localizer.submit()}
					</Button>
					<Modal
						id={"submitModal"}
						title={Localizer.budgetSubmitted()}
						onOk={toggleModal}
						showModal={showModal}
						original={true}
						type={"modal-message"}
						toggleModal={toggleModal}
						okText={Localizer.closeModal()}
					>
						<svg className={"embla-illustration primary-color"} aria-hidden="true" version="1.1" xmlns="http://www.w3.org/2000/svg" tabIndex={-1}>
							<use href={sprite + "#success-mark"} />
						</svg>
						<h3 className={"submit-modal-title"}>{Localizer.budgetSubmitted()}</h3>
						<p className={"submit-modal-text"}>{Localizer.budgetSubmittedMessage()}</p>
					</Modal>

					<Modal
						id={"warningModal"}
						title={Localizer.deleteYearWarningHeader()}
						showModal={showWarning}
						toggleModal={toggleWarning}
						onOk={sendToNorma}
						onCancel={toggleWarning}
						type={"modal-original"}
						okText={Localizer.submit()}
					>
						<p>{Localizer.deleteYearWarningMessage()}</p>
					</Modal>
				</>
			);
	};

	const addSelectedVersion = (order: string) => {
		const selectedVersion = versions?.find(x => x.order === parseInt(order))
		if (selectedVersion) {
			dispatch(budgetPeriodIdUpdated(selectedVersion?.budgetPeriodId));
			dispatch(setOrder(selectedVersion));
		}
	}

	return (
		<>
			<CustomCategoriesFilter asActionButton={true} />
			{!isLoading && versions && versions.length > 0 && selectedVersion && user?.action !== Action.FinancialReport &&
				<DropdownButton
					id="version-dropdown"
					btnStyling={"btn-default"}
					btnText={selectedVersion.textValue}
					options={versions.map(v => ({ key: v.order.toString(), value: versionText(v.textValue) }))}
					onItemClick={addSelectedVersion}
					classes={"margin-left-s"}
				/>
			}

			{allYears.length > 0 && !showSingleFinancialReport
				&&
				<DropdownButton
					btnStyling={"btn-default"}
					optionsContent={<YearColumnFilter years={allYears} institutions={props.institutions != null ? props.institutions : []} />}
					icon={<ColumnIcon />}
					iconRight={false}
					keepOpenOnOptionClick={true}
					btnText={Localizer.columns()}
					id={"columns-dropdown"}
					classes={"margin-left-s"}
				/>
			}

			<Button id="multiline-state" classes="action-button margin-left-s line-state-button" styling="btn-default" type="button" onClick={() => handleRowClick()}><Icon icon="list" />{isMultiline ? Localizer.rowSingleHeight() : Localizer.rowMultiHeight()}</Button>
			<Button id="download-excel-btn" classes="action-button margin-left-s download-excel-button" onClick={downloadExcel} styling="btn-default" type="button"><Icon icon="incoming" />{Localizer.downloadExcel()}</Button>
			{renderSendToNormaBtn()}
		</>
	);
}

const versionText = (version: string) => `${version}`;

type PopoverContentProps = {
	hasAnyFilters: boolean;
	maxAmountExceeded: boolean;
	maxAmountExceededTotal: number;
	belowMinimumAmount: boolean;
	minAmountExceededTotal: number;
	hasEmptyRequiredCells: boolean;
	maxIndirectAdminExpenseExceededBy: AdministrationExpense[];
	maxDirectAdminExpenseExceededBy: AdministrationExpense[];
	hasFteValuesAbove100: boolean;
	hasInstitutionsInSalary: boolean;
	hasInstitutionsInAdministration: boolean;
	hasInstitutionsInOperation: boolean;
	hasInstitutionsInDissemination: boolean;
	currency: string;
}


const getPopoverContent = ({ hasAnyFilters, maxAmountExceeded, maxAmountExceededTotal, belowMinimumAmount, minAmountExceededTotal, hasEmptyRequiredCells, maxIndirectAdminExpenseExceededBy, maxDirectAdminExpenseExceededBy, hasFteValuesAbove100, hasInstitutionsInSalary, hasInstitutionsInAdministration, hasInstitutionsInOperation, hasInstitutionsInDissemination, currency }: PopoverContentProps) => {
	if (hasAnyFilters) return Localizer.sendToNormaDisabledFilters();
	const brokenConstraints: string[] = [];
	if (!hasInstitutionsInSalary)
		brokenConstraints.push(Localizer.institutionNotSelectedInSalary());
	if (!hasInstitutionsInAdministration)
		brokenConstraints.push(Localizer.institutionNotSelectedInAdministration());
	if (!hasInstitutionsInOperation)
		brokenConstraints.push(Localizer.institutionNotSelectedInOperation());
	if (!hasInstitutionsInDissemination)
		brokenConstraints.push(Localizer.institutionNotSelectedInDissemination());
	if (maxAmountExceeded)
		brokenConstraints.push(`${Localizer.maxAmountExceeded()} ${formatWithThousandSeperators(maxAmountExceededTotal)} ${currency}`);
	if (belowMinimumAmount)
		brokenConstraints.push(`${Localizer.belowMinimumAmount()} ${formatWithThousandSeperators(minAmountExceededTotal)} ${currency}`);
	if (hasEmptyRequiredCells)
		brokenConstraints.push(Localizer.requiredCellsAreEmpty());
	if (hasFteValuesAbove100)
		brokenConstraints.push(Localizer.fteAbove100Error());
	if (maxIndirectAdminExpenseExceededBy.some(s => s.budgetExceededBy > 0)) {
		maxIndirectAdminExpenseExceededBy.forEach(expense => {
			if (expense.budgetExceededBy > 0) {
				brokenConstraints.push(`${Localizer.maxIndirectAdminExpenseExceeded()} ${formatWithThousandSeperators(expense.budgetExceededBy)} ${currency} ${expense.institution ? `for institution ${expense.institution?.name}` : ''}`);
			}
		});
	}
	if (maxDirectAdminExpenseExceededBy.some(s => s.budgetExceededBy > 0)) {
		maxDirectAdminExpenseExceededBy.forEach(expense => {
			if (expense.budgetExceededBy > 0) {
				brokenConstraints.push(`${Localizer.maxDirectAdminExpenseExceeded()} ${formatWithThousandSeperators(expense.budgetExceededBy)} ${currency} ${expense.institution ? `for institution ${expense.institution?.name}` : ''}`);
			}
		});
	}
	const tooltipHtml = <>{Localizer.sendToNormaDisabledBudgetConstraints()}:
		<ul>
			{brokenConstraints.map((brokenConstraint, i) => <li key={`constraint-${i}-${brokenConstraint}`}>{brokenConstraint}</li>)}
		</ul>
	</>;

	return ReactDOMServer.renderToString(tooltipHtml);
}

function disposePopover() {
  return () => {
    const popoverElement = document.querySelector('.popover');
    if (popoverElement) {
      popoverElement.remove();
    }
  };
}
