import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import titlebarReducer from "layout/titleBar/titlebarSlice";
import filterReducer from "pages/dashboard/components/Filter/filterSlice";
import cellReducer from "pages/dashboard/components/Grid/components/cellSlice";
import subcategoryReducer from "pages/dashboard/components/Grid/components/subcategorySlice";
import yearReducer from "pages/dashboard/components/Grid/components/yearSlice";
import grantConstraintsReducer from "pages/dashboard/components/Grid/components/grantConstraintSlice";
import tagReducer from "pages/dashboard/components/Grid/components/tagSlice";
import institutionReducer from "pages/dashboard/components/Grid/components/institutionSlice";
import categoryReducer from "pages/dashboard/components/Grid/components/categorySlice";
import tabIndexReducer from "infrastructure/tabIndexSlice";
import { emptyApi } from "services/api/emptyApi";
import { apiErrorLog } from "services/api/apiErrorLog";
import versionSlice from "../pages/dashboard/components/Grid/components/versionSlice";
import { setupListeners } from '@reduxjs/toolkit/query';

export const store = configureStore({
    reducer: {
        versionSlice: versionSlice,
        titlebar : titlebarReducer,
        filters: filterReducer,
        cells: cellReducer,
        subcategories: subcategoryReducer,
        years: yearReducer,
        grantConstraints: grantConstraintsReducer,
        tags: tagReducer,
        institutions: institutionReducer,
        categories: categoryReducer,
        tabIndex: tabIndexReducer,
        [emptyApi.reducerPath]: emptyApi.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(emptyApi.middleware).concat(apiErrorLog)    
});

setupListeners(store.dispatch);

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
