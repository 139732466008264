import Icon from "core/icon/icon";
import React from "react";
import {Tooltip} from "core/tooltip/tooltip";
import styles from "./subCategoryChooseItem.module.scss";
import classNames from "classnames";

interface ISubCategoryChooseItemProps {
  onItemClicked: (id:string) => void;
  items: {key: string, value: string, note: string}[]
}

export const SubCategoryChooseItem = (props: ISubCategoryChooseItemProps) => {

  const renderScopenote = (scopenote: string) => {
    const showInfo = scopenote !== "" && scopenote !== null;
    return (
      <Tooltip className={"align-items-center subtle"} message={scopenote} placement={"top-start"}>
        <div className={classNames("align-items-center", showInfo ? "" : styles.hideInformation)}>
          <Icon icon={"info"} extraClasses="margin-left-s" />
        </div>
      </Tooltip>
    )
  }

  const render = () => {
    return (
      <>
        {props.items.map((x,index) => {
          return (
            <div className={classNames("d-flex", "justify-content-between", "align-items-center", "padding-m", styles.selectable)} key={`${index}` } onClick={(e) => {
              props.onItemClicked(x.key);
              e.preventDefault();}}>

              <div>{x.value}</div>
              {renderScopenote(x.note)}
            </div>
          );
        })}
      </>
    )
  }

  return <div className={styles.addSubcategoryContainer}>{render()}</div>;
}
