import React from "react";
import {BudgetType, columnTitleTranslator} from "services/api/responseModels/budgetPeriodResponseModel";
import {Localizer} from "infrastructure/localization/localizer";
import {RootState} from "app/store";
import {useDispatch, useSelector} from "react-redux";
import {selectShownYears, singleFinancialReportColumns, YearState} from "./yearSlice";
import {
  customCategoriesToggled,
  GrantConstraints,
  selectGrantConstraints,
  showSingleFinancialReportColumn,
  showTotalColumn
} from "./grantConstraintSlice";
import Icon from "core/icon/icon";
import {FinancialReportType} from "models/enums/financialReportType";
import {isAllShownSelector, showAllCategories} from "./categorySlice";
import {Tooltip} from "../../../../../core/tooltip/tooltip";
import {VersionState} from "./versionSlice";
import {useAuth} from "../../../../../infrastructure/routing/auth";
import {Action} from "../../../../../models/enums/action";
import {Status} from "../../../../../models/enums/status";

interface IBudgetHeaderProps {
  currency: string;
}

export function BudgetHeader(props: IBudgetHeaderProps) {
  const dispatch = useDispatch();
  const shownYears = useSelector<RootState>(state => selectShownYears(state.years)) as YearState[];
  const { budgetType, financialReportYearId, customSubcategoriesCollapsed, institutionCollapsed, isReadonly, financialReportType } = useSelector<RootState>(state => selectGrantConstraints(state)) as GrantConstraints;
  const showTotal = useSelector<RootState>(state => showTotalColumn(state)) as boolean;
  const showSingleFinancialReport = useSelector<RootState>(state => showSingleFinancialReportColumn(state)) as boolean;
  const isAllCategoriesShown: boolean = useSelector<RootState>(state => isAllShownSelector(state)) as boolean;
  const { isApprovedText, isCurrentVersion, selectedVersion  }  = useSelector<RootState>(state => state.versionSlice) as VersionState;
  const auth = useAuth();

  const toggleCustomCategories = () => {
    dispatch(customCategoriesToggled());
  }

  const headerClass = (collapsed?: boolean) => collapsed ? "justify-content-center" : "justify-content-between";

  return (
    <>
      <div
        className="header-title padding-xs padding-left-s border-bottom-left border-top sidebar-element justify-content-between">
        <div className="d-flex">
          <span className={"toggle-subcategories"}
                onClick={() => dispatch(showAllCategories({show: !isAllCategoriesShown}))}><Icon
            extraClasses={"margin-top-l"} icon={isAllCategoriesShown ? "arrow-down" : "arrow-right"}/></span>
          <h5 className={"padding-left-s"}>{Localizer.applicationBudget()}</h5>
        </div>
        <span onClick={toggleCustomCategories}>
              <Icon extraClasses={"margin-top-l"} icon={"tag"}/>
          </span>
      </div>

      <div
        className={`header-title justify-content-between border-bottom-left border-top custom-categories-sidebar-element ${!customSubcategoriesCollapsed ? 'open-costom-sidebar-element' : ''}`}>
        {!customSubcategoriesCollapsed &&
          <h5 className="custom-categories-title padding-xs padding-left-m align-self-end">{Localizer.tags()}</h5>}
      </div>


        <div
          className={`header-title justify-content-between border-bottom border-top custom-categories-sidebar-element`} >
          {!institutionCollapsed &&
          <h5 className="custom-categories-title padding-xs padding-left-m align-self-end">{Localizer.institution()}</h5>
        }
        </div>



      {shownYears.map(year =>
        <React.Fragment key={`header-year-cell-${year.entity.id}`}>
          {year.columns.map(column => {
            let columnCount = 2;

            if (column.showDifferenceColumn) columnCount++;
            if (column.showFulltimeEquivalentColumn) columnCount++;

            const columnStyle = {"--columnSpan": columnCount} as React.CSSProperties;

            return (
              <h5 className="header-year-cell padding-xs padding-left-m border-left border-top" style={columnStyle}
                  key={`header-year-${year.entity.id}-${column.type}`}>
                {getYearHeader(year.entity.yearNumber, year.entity.removed, column.type, financialReportType)}
              </h5>
            )
          })}
        </React.Fragment>
      )}

      {shownYears.map(year =>
        <React.Fragment key={`header-cell-${year.entity.id}`}>
          {year.columns.map((column) => (
            <React.Fragment key={`header-cell-${year.entity.id}-${column.type}`}>
              <div
                className={`header-cell padding-xs padding-left-m border-bottom-left d-flex align-items-center ${headerClass(column.budgetCollapsed)} ${column.locked && "readonly"}`}>
                {!column.budgetCollapsed && <h5
                  //</div>className="margin-right-m">{(auth?.user?.action === Action.View || !isCurrentVersion || selectedVersion?.status == Status.Approved) && isApprovedText && column.type !== BudgetType.financial ? columnTitleTranslator(BudgetType.budget) : columnTitleTranslator(column.type)} {props.currency}</h5>}
                  className="margin-right-m">{(auth?.user?.action === Action.View || !isCurrentVersion || selectedVersion?.status == Status.Approved) && isApprovedText && column.type !== BudgetType.financial ? columnTitleTranslator(column.type) : columnTitleTranslator(column.type)} {props.currency}</h5>}
              </div>

              {column.showFulltimeEquivalentColumn &&
                <div
                  className={`header-cell padding-xs padding-left-m border-bottom d-flex align-items-center justify-content-center ${headerClass(column.fulltimeEquivalentCollapsed)} ${column.locked && "readonly"} ${column.budgetCollapsed && "border-left"}`}>
                  {!column.fulltimeEquivalentCollapsed &&
                    <Tooltip message={Localizer.FTEExplainer()} placement={"bottom"}>
                      <h5>{Localizer.FTE()}</h5>
                    </Tooltip>
                  }
                </div>
              }

              {column.showDifferenceColumn &&
                <div
                  className={`header-cell padding-xs padding-left-m border-bottom d-flex align-items-center ${headerClass(column.differenceCollapsed)} ${column.locked && "readonly"} ${column.budgetCollapsed && (column.fulltimeEquivalentCollapsed == undefined || column.fulltimeEquivalentCollapsed) && "border-left"}`}>
                  {!column.differenceCollapsed &&
                    <h5 className="margin-right-m">{Localizer.difference()} {props.currency}</h5>}
                </div>
              }

              <div
                className={`header-cell padding-xs padding-left-m border-bottom d-flex align-items-center ${headerClass(column.descriptionCollapsed)} ${column.locked && "readonly"} ${column.budgetCollapsed && (column.fulltimeEquivalentCollapsed == undefined || column.fulltimeEquivalentCollapsed) && (column.differenceCollapsed == undefined || column.differenceCollapsed) && "border-left"}`}>
                {!column.descriptionCollapsed && <h5 className="margin-right-m">{Localizer.description()}</h5>}
              </div>
            </React.Fragment>
          ))}
        </React.Fragment>
      )}

      {showTotal && <div id="total-header" className="border-bottom-left border-top">
        <h3 className={`${isReadonly && 'subtle'}`}>{Localizer.total()}</h3>
      </div>}

      {showSingleFinancialReport &&
        <React.Fragment key={`header-cell-${financialReportYearId}`}>
          <h5 className="header-year-cell padding-xs border-bottom-left border-top subtle"
              style={{"--columnSpan": singleFinancialReportColumns} as React.CSSProperties}
              key={`header-year-${financialReportYearId}-${BudgetType.financial}`}>
            {Localizer.reportForTotal()}
          </h5>
          <div
            className={`header-cell padding-xs border-bottom-left d-flex justify-content-between ${isReadonly && "readonly"}`}>
            <h4 className="margin-right-m">{columnTitleTranslator(BudgetType.financial)}</h4>
          </div>
          <div
            className={`header-cell padding-xs border-bottom-left d-flex justify-content-between ${isReadonly && "readonly"}`}>
            <h4 className="margin-right-m">{Localizer.difference()}</h4>
          </div>
          <div
            className={`header-cell padding-xs border-bottom-left d-flex justify-content-between ${isReadonly && "readonly"}`}>
            <h4 className="margin-right-m">{Localizer.description()}</h4>
          </div>
        </React.Fragment>
      }
    </>
  );
}

export default BudgetHeader;

const getYearHeader = (yearNumber: number, removed: boolean, type: BudgetType, financialReportType: FinancialReportType) => {
  if (type === BudgetType.financial && financialReportType === FinancialReportType.Single) return Localizer.reportForTotal()

  const removedText = removed ? `(${Localizer.removed()})` : "";
  return `${Localizer.year()} ${yearNumber} ${removedText}`;
}
