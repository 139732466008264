import React, {useContext, useState} from "react";
import { CellContext } from "../cellContext";
import classNames from "classnames";

interface ICellContainerProps {
  noFocus?: boolean;
  summationCell? : boolean;
  explicitReadOnly?: boolean
  error?: boolean
}

export const CellContainer= (props: React.PropsWithChildren<ICellContainerProps>) => {
  const isReadonly = useContext(CellContext);
  const [hasFocus, toggleHasFocus] = useState(false);

  const renderChildren = () => {
    if(props.noFocus) {
      return props.children;
    }
    else {
      return React.cloneElement(props.children as React.ReactElement<any>, { hasFocus: hasFocus, focusStatusCallback: toggleHasFocus });
    }
  }

  return (
    <div className={classNames("category-row-cell",
      props.error && "error",
      "border-bottom-left",
      isReadonly && "readonly",
      props.explicitReadOnly && "readonly-single-column",
      !props.noFocus && !isReadonly && "editable",
      props.summationCell ? "summation-content-row" : "")}
    >
      {props.children && renderChildren()}
    </div>
  );
}

export default CellContainer;
