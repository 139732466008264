import { FinancialReportType } from "models/enums/financialReportType";
import { Status } from "models/enums/status";
import { Localizer } from "../../../infrastructure/localization/localizer";

export class BudgetPeriodResponseModel {
  public institutions: Institution[];
  public grantId: string;
  public id: string;
  public recordId: string;
  public minAmount: number;
  public maxAmount: number;
  public currency: string;
  public categoryMaxDeviation: number;
  public subCategoryMaxDeviation: number;
  public maxDirectAdminExpense: number;
  public maxIndirectAdminExpense: number;
  public status: Status;
  public start: string;
  public end: string;
  public subCategories: Subcategory[];
  public availableSubCategories: Subcategory[];
  public typesToPesentInBudget: BudgetType[];
  public years: Year[];
  public comment: string;
  public title: string;
  public financialReportType: FinancialReportType;
  public type: BudgetType;
  public financialReportYearId?: string;
  public closedFinancialReportYears: number[];
  public version: number;
  public isLatestVersion: boolean;
  public isBallpark: boolean;
  public mortenTest: string;
  public projectSupplementEnabled: boolean;
  public supplementChoiceEnabled: boolean;
  public institutionReadonly: boolean;
  public supplementReadonly: boolean;
}

export class Subcategory {
  public institutions: Institution[];
  public id: string;
  public name: string;
  public scopeNote: string;
  public category: Category;
  public order: number;
  public tags: Tag[];
  public selectedInstitution: Institution | undefined;
  public selectedSupplement: string | undefined;
  public values: ValueResponseModel[];
  public institutionsReadOnly: boolean = false;
}

export class ValueResponseModel {
  public yearId: string;
  public yearNumber: number;
  public subcategoryId: string;
  public type: BudgetType;
  public budget: number | undefined;
  public description: string | undefined;
  public fullTimeEquivalent: number | undefined;
  public commentRequired: boolean;
  public fulltimeEquivalentRequired: boolean | undefined;
  public categoryNumber: number;
  public hasIssue: boolean;
  public projectSupplementAmount: number | undefined;
}

export class Category {
  public id: string;
  public name: string;
  public orderNumber: number;
  public subcategories: Subcategory[];
}

export class Year {
  public id: string;
  public yearNumber: number;
  public status: YearStatus;
  public removed: boolean;
  public columnsDataTypes: number[];
}

export class Tag {
  public id: string;
  public subcategoryId: string;
  public name: string;
}

export class Institution {
  public isEligible?: boolean;
  public id: string;
  public name: string;
  public subcategoryId: string;
  public ftE_Types: string[];
  constructor() {
    this.isEligible = false; // Default value
  }
}

export enum YearStatus {
  open = 1,
  closed = 2,
}

export enum BudgetType {
  budget = 1,
  financial = 2,
  revision = 3,
}

export const columnTitleTranslator = (type: BudgetType): string => {
  switch (type) {
    case BudgetType.budget:
      return Localizer.budget();
    case BudgetType.revision:
      return Localizer.revised();
    case BudgetType.financial:
      return Localizer.report();
    default:
      return Localizer.budget();
  }
};

