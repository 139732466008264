import sprite from 'content/icons/sprite.symbol.svg'
import {forwardRef} from "react";

export interface IBadgeProps {
    text: string;
    type: 'normal' | 'pill';
    styling: 'badge-warning' | 'badge-info';
    icon?: string;
    classes?: string;
    disabled?: boolean;
}

export const Badge = forwardRef<SVGSVGElement, IBadgeProps>((props, ref) => {
    const pillClass = props.type === 'pill' ? "badge-pill" : "";

    return (
         <span className={`badge ${pillClass} ${props.classes} ${props.styling} padding-xs`}>
            {props.icon &&
            <svg className="embla-icon margin-right-s" aria-hidden="true" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <use href={sprite + "#" + props.icon}/>
            </svg>}
            {props.text && props.text.length > 0 && <span className="badge-text">{props.text}</span>}
        </span>
    )
});
export default Badge;
