import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import { StorageKeys } from "infrastructure/storageKeys";
import config, { loadConfig } from "config/config";

const rawBaseQuery = fetchBaseQuery({
  baseUrl: config.api_url,
  prepareHeaders: headers => {
      const token = localStorage.getItem(StorageKeys.token);
      if (token) {
        headers.set('Authorization', `bearer ${token}`)
      }
      return headers;
    }
});

export const apiTags = {
    tags: "Tags" as const
};

const dynamicBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
  const urlEnd = typeof args === 'string' ? args : args.url

  let baseUrl = config?.api_url;
  if(!baseUrl) {
    await loadConfig().then(config => baseUrl = config.api_url);
  }

  const adjustedUrl = `${baseUrl}/${urlEnd}`;
  const adjustedArgs = typeof args === 'string' ? adjustedUrl : { ...args, url: adjustedUrl };
  return rawBaseQuery(adjustedArgs, api, extraOptions);
}

export const emptyApi = createApi({
  reducerPath: 'api',
  baseQuery: dynamicBaseQuery,
  endpoints: () => ({}),
    tagTypes: Object.values(apiTags),
});

