import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { IsCostCategory } from "infrastructure/extensions/categoryNameAsserter";
import {
  Institution,
  Subcategory,
  ValueResponseModel,
} from "services/api/responseModels/budgetPeriodResponseModel";
import {
  subcategoryRemoved,
  selectedTagsUpdated,
  budgetSubmitted,
} from "../actions";
import { tagAddedToSubcategory, tagRemovedFromSubcategory } from "./tagSlice";
import {
  institutionAddedToSubcategory,
  institutionRemovedFromSubcategory,
} from "./institutionSlice";
import { YearState } from "./yearSlice";
import { ProjectSupplement } from "services/api/responseModels/updateInstitutionResponseModel";

export type SubcategoryState = {
  shown: boolean;
  entity: Subcategory;
};

const subcategoryAdapter = createEntityAdapter({
  selectId: (subcategory: SubcategoryState) => subcategory.entity.id,
});

export const subcategorySlice = createSlice({
  name: "subcategories",
  initialState: subcategoryAdapter.getInitialState(),
  reducers: {
    setSubcategories: (state, action: PayloadAction<Subcategory[]>) => {
      subcategoryAdapter.setAll(
        state,
        action.payload.map((sub) => ({ shown: true, entity: sub }))
      );
    },
    subcategoriesAdded: (
      state,
      action: PayloadAction<{
        subcategories: Subcategory[];
        years: YearState[];
        categoryNumber: number;
      }>
    ) => {
      const subcategories = action.payload.subcategories.map((sub) => ({
        shown: true,
        entity: sub,
      }));
      subcategoryAdapter.addMany(state, subcategories);
    },
    subcategoryMoved: (
      state,
      action: PayloadAction<{
        draggedSubcategoryId: string;
        replacesSubcategoryId: string;
      }>
    ) => {
      const draggedSubcategory = selectSubcategoryById(
        state,
        action.payload.draggedSubcategoryId
      );
      const replaceSubcategory = selectSubcategoryById(
        state,
        action.payload.replacesSubcategoryId
      );

      if (!draggedSubcategory || !replaceSubcategory) return;

      const subcategories = selectByCategoryId(
        state,
        draggedSubcategory.entity.category.id
      );
      const changes = subcategories.map((x) => {
        let position = x.entity.order;

        if (x.entity.id === draggedSubcategory.entity.id) {
          position = replaceSubcategory.entity.order;
        }
        // if item is below dragged subcategory AND above or equal to replaced it should move up
        else if (
          x.entity.order < draggedSubcategory.entity.order &&
          x.entity.order >= replaceSubcategory.entity.order
        ) {
          position++;
        }
        // if item is above dragged subcategory AND below or equal to replaced it should move down
        else if (
          x.entity.order > draggedSubcategory.entity.order &&
          x.entity.order <= replaceSubcategory.entity.order
        ) {
          position--;
        }

        return {
          id: x.entity.id,
          changes: {
            entity: {
              ...x.entity,
              order: position,
            },
          },
        };
      });

      subcategoryAdapter.updateMany(state, changes);
    },
    changeSelectedInstitution: (
      state,
      action: PayloadAction<{
        subcategoryId: string;
        institution: Institution;
        psValues: ProjectSupplement[];
      }>
    ) => {
      const subcategory = selectSubcategoryById(
        state,
        action.payload.subcategoryId
      );
      if (!subcategory) return;
      const copyValues = subcategory.entity.values.map((v) => {
        return {
          ...v,
          projectSupplementAmount: action.payload.psValues.find(
            (ps) => ps.yearId === v.yearId
          )?.projectSupplementAmount,
        };
      });

      subcategoryAdapter.updateOne(state, {
        id: action.payload.subcategoryId,
        changes: {
          entity: {
            ...subcategory.entity,
            selectedInstitution: action.payload.institution,
            selectedSupplement: undefined,
            values: copyValues,
          },
        },
      });
    },
    changeSelectedSupplement: (
      state,
      action: PayloadAction<{
        subcategoryId: string;
        supplement: string;
        psValues: ProjectSupplement[];
      }>
    ) => {
      const subcategory = selectSubcategoryById(
        state,
        action.payload.subcategoryId
      );

      if (!subcategory) return;
      const copyValues = subcategory.entity.values.map((v) => {
        return {
          ...v,
          projectSupplementAmount: action.payload.psValues.find(
            (ps) => ps.yearId === v.yearId
          )?.projectSupplementAmount,
        };
      });
      subcategoryAdapter.updateOne(state, {
        id: action.payload.subcategoryId,
        changes: {
          entity: {
            ...subcategory.entity,
            selectedSupplement: action.payload.supplement,
            values: copyValues,
          },
        },
      });
    },
    updateFteAndBudgetOnSubcategory: (
      state,
      action: PayloadAction<{
        subcategoryId: string;
        yearId: string;
        response: Partial<ValueResponseModel>;
      }>
    ) => {
      const subcategory = selectSubcategoryById(
        state,
        action.payload.subcategoryId
      );
      if (!subcategory) return;
      const copyValues = subcategory.entity.values;
      const index = copyValues.findIndex(
        (x) => x.yearId === action.payload.yearId
      );

      const values = [
        ...copyValues.slice(0, index),
        {
          ...copyValues[index],
          ...action.payload.response,
        },
        ...copyValues.slice(index + 1),
      ];

      subcategoryAdapter.updateOne(state, {
        id: action.payload.subcategoryId,
        changes: {
          entity: {
            ...subcategory.entity,
            values: values,
          },
        },
      });
    },
    updateProjectSupplementOnSubcategoryValue: (
      state,
      action: PayloadAction<{
        subcategoryId: string;
        yearId: string;
        type: number;
        projectSupplementAmount: number | undefined;
        fullTimeEquivalent: number | undefined;
      }>
    ) => {
      const subcategory = selectSubcategoryById(
        state,
        action.payload.subcategoryId
      );
      if (!subcategory) return;
      const copyValues = subcategory.entity.values;
      const index = copyValues.findIndex(
        (x) =>
          x.yearId === action.payload.yearId && x.type === action.payload.type
      );

      const values = [
        ...copyValues.slice(0, index),
        {
          ...copyValues[index],
          projectSupplementAmount: action.payload.projectSupplementAmount,
          fullTimeEquivalent: action.payload.fullTimeEquivalent,
        },
        ...copyValues.slice(index + 1),
      ];

      subcategoryAdapter.updateOne(state, {
        id: action.payload.subcategoryId,
        changes: {
          entity: {
            ...subcategory.entity,
            values: values,
          },
        },
      });
    },
    updateBudgetOnSubcategoryValue: (
      state,
      action: PayloadAction<{
        subcategoryId: string;
        yearId: string;
        type: number;
        budget: number | undefined;
      }>
    ) => {
      const subcategory = selectSubcategoryById(
        state,
        action.payload.subcategoryId
      );
      if (!subcategory) return;
      const copyValues = subcategory.entity.values;
      const index = copyValues.findIndex(
        (x) =>
          x.yearId === action.payload.yearId && x.type === action.payload.type
      );

      const values = [
        ...copyValues.slice(0, index),
        {
          ...copyValues[index],
          budget: action.payload.budget,
        },
        ...copyValues.slice(index + 1),
      ];

      subcategoryAdapter.updateOne(state, {
        id: action.payload.subcategoryId,
        changes: {
          entity: {
            ...subcategory.entity,
            values: values,
          },
        },
      });
    },
    updateDescriptionOnSubcategoryValue: (
      state,
      action: PayloadAction<{
        subcategoryId: string;
        yearId: string;
        type: number;
        description: string | undefined;
      }>
    ) => {
      const subcategory = selectSubcategoryById(
        state,
        action.payload.subcategoryId
      );
      if (!subcategory) return;
      const copyValues = subcategory.entity.values;
      const index = copyValues.findIndex(
        (x) =>
          x.yearId === action.payload.yearId && x.type === action.payload.type
      );

      const values = [
        ...copyValues.slice(0, index),
        {
          ...copyValues[index],
          description: action.payload.description,
        },
        ...copyValues.slice(index + 1),
      ];

      subcategoryAdapter.updateOne(state, {
        id: action.payload.subcategoryId,
        changes: {
          entity: {
            ...subcategory.entity,
            values: values,
          },
        },
      });
    },
  },

  extraReducers: (builder) => {
    builder.addCase(subcategoryRemoved, (state, action) => {
      subcategoryAdapter.removeOne(state, action.payload);
    });
    builder.addCase(selectedTagsUpdated, (state, action) => {
      const newSubcategories = selectAllSubcategories(state).map((sub) => ({
        shown:
          action.payload.length === 0 ||
          sub.entity.tags.some((tag) =>
            action.payload.some((st) => st === tag.name)
          ),
        entity: sub.entity,
      }));
      subcategoryAdapter.setAll(state, newSubcategories);
    });
    builder.addCase(tagAddedToSubcategory, (state, action) => {
      const subcategory = selectSubcategoryById(
        state,
        action.payload.subcategoryId
      );
      if (!subcategory) return;

      const newTags = subcategory.entity.tags.slice();
      newTags.splice(newTags.length, 0, action.payload);
      subcategoryAdapter.updateOne(state, {
        id: action.payload.subcategoryId,
        changes: { entity: { ...subcategory.entity, tags: newTags } },
      });
    });
    builder.addCase(tagRemovedFromSubcategory, (state, action) => {
      const subcategory = selectSubcategoryById(
        state,
        action.payload.subcategoryId
      );
      if (!subcategory) return;

      const newTags = subcategory.entity.tags.slice();
      newTags.splice(
        newTags.findIndex((t) => t.id === action.payload.tagId),
        1
      );
      subcategoryAdapter.updateOne(state, {
        id: action.payload.subcategoryId,
        changes: { entity: { ...subcategory.entity, tags: newTags } },
      });
    });
    builder.addCase(institutionAddedToSubcategory, (state, action) => {
      const subcategory = selectSubcategoryById(
        state,
        action.payload.subcategoryId
      );
      if (!subcategory) return;

      const newInstitutions = subcategory.entity.institutions.slice();
      newInstitutions.splice(newInstitutions.length, 0, action.payload);

      subcategoryAdapter.updateOne(state, {
        id: action.payload.subcategoryId,
        changes: {
          entity: { ...subcategory.entity, institutions: newInstitutions },
        },
      });
    });
    builder.addCase(institutionRemovedFromSubcategory, (state, action) => {
      const subcategory = selectSubcategoryById(
        state,
        action.payload.subcategoryId
      );
      if (!subcategory) return;

      const newInstitutions = subcategory.entity.institutions.slice();
      newInstitutions.splice(
        newInstitutions.findIndex((t) => t.id === action.payload.institutionId),
        1
      );
      subcategoryAdapter.updateOne(state, {
        id: action.payload.subcategoryId,
        changes: {
          entity: { ...subcategory.entity, institutions: newInstitutions },
        },
      });
    });
    builder.addCase(budgetSubmitted, (state, action) => {
      const allSubs = selectAllSubcategories(state);

      const updates = allSubs.map((subCategory) => {
        return {
          id: subCategory.entity.id,
          changes: {
            entity: { ...subCategory.entity, institutionsReadOnly: true },
          },
        };
      });

      subcategoryAdapter.updateMany(state, updates);
    });
  },
});

export const {
  setSubcategories,
  subcategoriesAdded,
  subcategoryMoved,
  changeSelectedInstitution,
  changeSelectedSupplement,
  updateProjectSupplementOnSubcategoryValue,
  updateBudgetOnSubcategoryValue,
  updateDescriptionOnSubcategoryValue,
} = subcategorySlice.actions;
export const subcategoryState = (state: RootState) => state.subcategories;
export default subcategorySlice.reducer;
export const {
  selectAll: selectAllSubcategories,
  selectById: selectSubcategoryById,
  selectEntities: selectSubcategories,
} = subcategoryAdapter.getSelectors();
export const selectShownSubcategories = (state: RootState) =>
  selectAllSubcategories(state.subcategories)
    .filter((sub) => sub.shown)
    .map((sub) => sub.entity);
export const selectByCategoryId = (
  state: EntityState<SubcategoryState, string>,
  categoryId: string
) =>
  selectAllSubcategories(state).filter(
    (sub) => sub.entity.category.id === categoryId
  );
export const selectSubcategoriesByName = (
  state: EntityState<SubcategoryState, string>,
  name: string
) =>
  selectAllSubcategories(state)
    .map((sub) => sub.entity)
    .filter((sub) => sub.name === name);
export const selectCostSubcategories = (state: EntityState<SubcategoryState, string>) =>
  selectAllSubcategories(state)
    .filter((sub) => IsCostCategory(sub.entity.category.name))
    .map((sub) => sub.entity);
export const anySubcategoriesBallpark = (state: RootState) =>
  selectAllSubcategories(state.subcategories).some((subcategory) =>
    subcategory.entity.name.includes("ballpark")
  );

