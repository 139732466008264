import React, {useEffect, useState} from "react";

export const useTextOverflowHook = <T extends  HTMLElement>(element: React.RefObject<T>): boolean => {
    const [isOverflow, setIsOverflow] = useState(false);

    useEffect(() => {
        if (element.current)
            if (element.current.scrollWidth > element.current.clientWidth) {
                setIsOverflow(true);
            } else {
                setIsOverflow(false);
            }
    }, [element.current?.clientWidth,element]);

    return isOverflow;
};
