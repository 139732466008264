import { RootState } from "app/store";
import Badge from "core/badge/badge";
import { Localizer } from "infrastructure/localization/localizer";
import { FinancialReportType } from "models/enums/financialReportType";
import { useSelector } from "react-redux";
import { BudgetType, Subcategory, ValueResponseModel } from "services/api/responseModels/budgetPeriodResponseModel";
import { singleFinancialReportDifference, differenceBetweenYears, findComparerType, selectCellsInCategory } from "./cellSlice";
import { GrantConstraints, selectGrantConstraints } from "./grantConstraintSlice";
import { selectAllYears, selectColumnTypesForYear } from "./yearSlice";
import { VersionState } from "./versionSlice";

interface IComparerCellProps {
	yearId: string;
	columnType: BudgetType;
	currency: string;
	sumCell?: boolean;
	readonly: boolean;
	subcategories: Subcategory[];
}

export function ComparerCell(props: IComparerCellProps) {
	const columnTypes = useSelector<RootState>(state => selectColumnTypesForYear(state.years, props.yearId)) as BudgetType[];
	const comparerType = findComparerType(props.columnType, columnTypes);
	const { budgetType } = useSelector<RootState>(state => selectGrantConstraints(state)) as GrantConstraints;
	const cellsInSubcategory = useSelector<RootState>(state => selectCellsInCategory(state.cells, props.subcategories.map(sub => sub.id))) as ValueResponseModel[];
	const { maxSubcategoryDeviation, financialReportType } = useSelector<RootState>(state => selectGrantConstraints(state)) as GrantConstraints;
	const { difference, differenceInPercent, differenceInPercentFormatted } = useSelector<RootState>(state => {
		if (props.columnType === BudgetType.financial && financialReportType === FinancialReportType.Single) {
			// calculate difference against total
			const yearsToCalculateFrom = selectAllYears(state.years).filter(y => !y.entity.removed).map(y => y.entity.id);
			return singleFinancialReportDifference(state, [comparerType], props.subcategories, yearsToCalculateFrom, props.yearId);
		}

		// calculate total against matching column
		return differenceBetweenYears(props.yearId, props.columnType, comparerType, cellsInSubcategory, props.subcategories);
	}) as { difference: string, differenceInPercent: number | undefined, differenceInPercentFormatted: string };

	const showConstraintMarker = shouldShowMarker(differenceInPercent, maxSubcategoryDeviation);
	const diffPercentFormatted = differenceInPercentFormatted === "+∞" ? Localizer.new() : `${differenceInPercentFormatted}%`

	return (
		<div className={`category-row-cell-content padding-right-m padding-xs content-align-right comparer-cell ${showConstraintMarker && "justify-content-between"} ${props.sumCell && "sum-cell"} ${props.readonly && "readonly"}`}>
			{differenceInPercent !== undefined && <>
				{showConstraintMarker && <Badge text={diffPercentFormatted} type={'pill'} styling={"badge-warning"} classes={"margin-right-s"} />}
				{difference}
			</>}
		</div>
	);
}
export default ComparerCell;

const shouldShowMarker = (actualDifference: number | undefined, constraint: number) => {
	if (actualDifference && constraint)
		return Math.abs(actualDifference) > constraint;
	else
		return false;
}
