import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { Tag } from "services/api/responseModels/budgetPeriodResponseModel";
import { subcategoryRemoved } from '../actions';

const tagAdapter = createEntityAdapter({
    selectId: (tag: Tag) => tag.id
})

export const tagSlice = createSlice({
    name: "tags",
    initialState: tagAdapter.getInitialState(),
    reducers: {
        tagsAdded: (state, action: PayloadAction<Tag[]>) => {
            tagAdapter.addMany(state, action.payload);
        },
        tagAddedToSubcategory: (state, action: PayloadAction<Tag>) => {
            tagAdapter.addOne(state, action.payload);
        },
        tagRemovedFromSubcategory: (state, action: PayloadAction<{tagId: string, subcategoryId: string}>) => {
            tagAdapter.removeOne(state, action.payload.tagId);
        }
    },
    extraReducers: (builder) => {
        builder.addCase(subcategoryRemoved, (state, action) => {
            const tagsToRemove = selectTagsForSubcategory(state, action.payload);
            tagAdapter.removeMany(state, tagsToRemove.map(t => t.id));
        })
    }
});

export const { tagAddedToSubcategory, tagRemovedFromSubcategory, tagsAdded } = tagSlice.actions;
export const tagState = (state: RootState) => state.tags;
export default tagSlice.reducer;
export const { selectAll: selectAllTags } = tagAdapter.getSelectors();
export const selectTagsForSubcategory = (state: EntityState<Tag, string>, subcategoryId: string) => selectAllTags(state).filter(tag => tag.subcategoryId === subcategoryId);
