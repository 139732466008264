interface IStatusPillProps {
    text: string
}

export const StatusPill = (props: IStatusPillProps) => {
    return (
        <div className="badge badge-pill status-pill">
            <h4 className="status-pill-text">
                {props.text}
            </h4>
        </div>
    )
}
