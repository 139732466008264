import React, {useEffect, useMemo, useRef, useState} from "react";
import { CellType } from "models/enums/cellType";
import {useToggle} from "app/hooks";
import { NumericInputModule } from "ditmer-embla";
import { selectTabIndex, TabIndex, tabIndexChanged } from "infrastructure/tabIndexSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/store";
import {GrantConstraints, selectGrantConstraints} from "./grantConstraintSlice";
import classNames from "classnames";
import {Tooltip} from "core/tooltip/tooltip";
import {useTextOverflowHook} from "hooks/useTextOverflowHook";
import {TextAreaInput} from "../../ExpandableTextAraeInput/textAreaInput";

interface ICellProps {
  value: string;
  type: CellType;
  onBlurSave: (value: string) => Promise<boolean>;
  readModeValueFormatter?: (value: string) => string;
  readonly: boolean;
  required?: boolean;
  decimalCount?: number;
  columnIndex: number;
  verticalIndex: number;
  requiredFormat?: 'small' | 'default';
  hasFocus?: boolean;
  focusStatusCallback?: (value: boolean) => void;
  hasNoFteSibling?: boolean;
  maxLength?: number;
  pattern?: string;
}

export function Cell(props: ICellProps) {
  const [isWriteMode, toggleWriteMode] = useToggle(props.hasFocus ?? false);
  const [currentValue, setCurrentValue] = useState(props.value);
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const tabIndexWithFocus = useSelector<RootState>(state => selectTabIndex(state)) as TabIndex;
  const containerRef = useRef<HTMLDivElement>(null);
  const { isMultiline } = useSelector<RootState>(state => selectGrantConstraints(state)) as GrantConstraints;
  const textElementRef = useRef<HTMLDivElement>(null);
  const isOverflow = useTextOverflowHook(textElementRef);

  useEffect( () => {
    if(props.hasFocus && props.hasFocus !== isWriteMode) {
      toggleWriteMode();
    }
  }, [props.hasFocus])

  useEffect(() => {
    if(props.focusStatusCallback) {
      props.focusStatusCallback(isWriteMode);
    }
  }, [isWriteMode])

  useEffect(() => {
    if(props.readonly) return;

    if(tabIndexWithFocus.horisontalTabIndex === props.columnIndex
      && tabIndexWithFocus.verticalTabIndex === props.verticalIndex) {
        if(!isWriteMode && containerRef.current) {
          onFocus(false);
        }
      }
  }, [tabIndexWithFocus.horisontalTabIndex, tabIndexWithFocus.verticalTabIndex, props.readonly])

  useEffect(() => {
    if(inputRef.current) {
      if(props.type === CellType.Number || props.type === CellType.Fte) {
        new NumericInputModule(inputRef.current, {
          positiveOnly: true,
          thousandSeparatorMark: ".",
          decimalMark: ",",
          decimalCount: props.decimalCount ?? 0
      });
      }
    }

  }, [props.type, props.decimalCount, inputRef.current, isWriteMode])

  const onFocus = (tabIndexChanges : boolean = true) => {
    if (isWriteMode) return;
    toggleWriteMode();

    if (tabIndexChanges) {
      dispatch(tabIndexChanged({ horisontalIndex: props.columnIndex, verticalIndex: props.verticalIndex }));
    }
  }

  const handleBlur = async (value: string) => {
    setCurrentValue(value);
    toggleWriteMode();

    if(value === props.value) {
      return;
    }

    const didSave = await props.onBlurSave(value);
    if(!didSave) {
      setCurrentValue(props.value);
    }
  }

  const renderInput = () => <input ref={inputRef} type="text" maxLength={props.maxLength} pattern={props.pattern} defaultValue={props.value} onBlur={(e) => handleBlur(e.target.value)} autoFocus />

  const renderText = () => {
    return (

      props.required && !props.readonly ? ""
        :
        (isOverflow && !isMultiline ? <Tooltip message={currentValue} placement="top" ref={textElementRef}>
          <div ref={textElementRef} className={classNames(isMultiline ?
              "multi-line-description-style":"single-line-description-style",
            "cell-content-value")} >
            {props.readModeValueFormatter ? props.readModeValueFormatter(currentValue) : currentValue}
          </div>
        </Tooltip>
        :
          <div ref={textElementRef} className={classNames(isMultiline ?
            "multi-line-description-style": "single-line-description-style",
            "cell-content-value")} >
            {props.readModeValueFormatter ? props.readModeValueFormatter(currentValue) : currentValue}
          </div>
        )
      )
  }

  const dynamicStylingPassedDownToPopperTextArea = useMemo(()=> {
    const noStyling = !props.readonly && props.hasFocus && (props.type === CellType.Number || props.type === CellType.Fte) || (isMultiline && props.hasFocus);
    return noStyling ? "" : "padding-xs padding-left-m padding-right-m";
    }, [props.type,props.hasFocus,isMultiline])

  let classes = "text-cell";

  if(props.type === CellType.Number) {
    classes = "content-align-right ";
  }

  if(props.type === CellType.Fte) {
    classes = "content-align-center ";
  }

  if(props.readonly)
    classes += " readonly";

    return (
    <div ref={containerRef} onFocus={() => onFocus()} className={classNames("category-row-cell-content", dynamicStylingPassedDownToPopperTextArea, classes)} onClick={() => onFocus()}>
      {!props.readonly && isWriteMode?
        (props.type === CellType.Number || props.type === CellType.Fte ?
          renderInput()
          :
          <TextAreaInput textInput={props.value} onBlurCallback={handleBlur} active={props.hasFocus} element={containerRef.current} isMultiline={isMultiline}/>
        )
        : renderText()
      }
    </div>
  );
}
export default Cell;


