import {Localizer} from "infrastructure/localization/localizer";

export const LogoutPage = () => {

    return (
        <div className="d-flex flex-column justify-content-center align-items-center margin-top-l">
            <h1 className="margin-bottom-m">{Localizer.loggedOut()}</h1>
            <p>{Localizer.loginUsingNorma()}</p>
        </div>
    );
}
