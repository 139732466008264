import { useEffect, useRef, useState } from "react";
import {BudgetType, Category, Subcategory} from "services/api/responseModels/budgetPeriodResponseModel";
import Icon from "core/icon/icon";
import Button from "core/button/button";
import { ConfirmationModalModule } from "ditmer-embla";
import { Localizer } from "infrastructure/localization/localizer";
import { useDeleteSubcategoryMutation, useMoveSubcategoryMutation } from "services/api/budgetPeriodApi";
import { useDispatch, useSelector } from "react-redux";
import { TagResponseModel } from "../../../../../services/api/responseModels/tagResponseModel";
import { mapToOption, useGetTagsQuery } from "../../../../../services/api/tagApi";
import { subcategoryRemoved } from "../actions";
import { GrantConstraints, selectGrantConstraints} from "./grantConstraintSlice";
import { RootState } from "app/store";
import { DropTargetMonitor, useDrag, useDrop } from "react-dnd";
import { subcategoryMoved } from "./subcategorySlice";
import { Status } from "models/enums/status";
import classNames from "classnames";
import {useTextOverflowHook} from "hooks/useTextOverflowHook";
import {Tooltip} from "../../../../../core/tooltip/tooltip";

interface subcategoryHeaderProps {
  subcategory: Subcategory;
  category: Category;
  categoryNumber: number;
  subcategoryNumber: number;
}

const DragableItemType = {
  SUBCATEGORY: 'SUBCATEGORY'
}

export function SubcategoryHeader(props: subcategoryHeaderProps) {
  const listNumber = `${props.categoryNumber}.${props.subcategoryNumber}`;
  const dispatch = useDispatch();
  const [deleteSubcategory] = useDeleteSubcategoryMutation();
  const [moveSubcategory] = useMoveSubcategoryMutation();
  const { isReadonly, budgetType, budgetPeriodStatus,isMultiline } = useSelector<RootState>(state => selectGrantConstraints(state)) as GrantConstraints;
  const [hover, setHover] = useState(false);
  const textElementRef = useRef<HTMLDivElement>(null);
  const isOverflow = useTextOverflowHook(textElementRef);
  const { data: tags } = useGetTagsQuery("");

  const deleteSubcategoryConfirmed = async () => {
    await deleteSubcategory(props.subcategory.id).unwrap();
    dispatch(subcategoryRemoved(props.subcategory.id));
  }

  const [{ isDragging }, dragRef] = useDrag({
    type: DragableItemType.SUBCATEGORY,
    item: () => props.subcategory,
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    }),
  })

  const [spec, dropRef] = useDrop<Subcategory, void, { handlerId: string | null }>({
    accept: DragableItemType.SUBCATEGORY,
    hover: async (item, monitor) => {
      if (!canDrop(item, monitor)) return;

      dispatch(subcategoryMoved({ draggedSubcategoryId: item.id, replacesSubcategoryId: props.subcategory.id }));
      moveSubcategory({ subcategoryToMoveId: item.id, subcategoryToReplaceId: props.subcategory.id });
    }
  })

  const canDrop = (item: Subcategory, monitor: DropTargetMonitor<Subcategory, void>) => {
    if (item.category.id !== props.subcategory.category.id
      || item.id === props.subcategory.id) {
      return false;
    }

    const dragIndex = item.order;
    const hoverIndex = props.subcategory.order;

    const hoverBoundingRect = reference.current?.getBoundingClientRect();
    const clientOffset = monitor.getClientOffset();

    if (hoverBoundingRect === undefined || !clientOffset) return false;

    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
    const hoverActualY = clientOffset.y - hoverBoundingRect.top

    // if dragging down, continue only when hover is smaller than middle Y
    if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) {
      return false;
    }
    // if dragging up, continue only when hover is bigger than middle Y
    if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) {
      return false;
    }

    return true;
  }

  const renderDelete = (visible?: boolean) =>
    <Button classes={visible ? "":"hide-element"} id={`deleteSubcategory-${props.subcategory.id}`}  type="button" styling="btn-icon" onClick={() => confirmDelete(deleteSubcategoryConfirmed)}>
      <Icon icon={"delete"} extraClasses="primary-color action-icon hoverItem"/>
    </Button>

  const renderScopenote = (tags : TagResponseModel[] | undefined) =>
    <>
      {tags && tags.length > 0 &&
        <Tooltip message={tags.map(tag => tag.text).join(", ")}>
          <span>
              <Icon icon={"tag"} extraClasses="action-icon tag-icon-hover" />
          </span>
        </Tooltip>
      }
    </>


  const reference = useRef<HTMLDivElement>(null)
  const canDrag = budgetType === BudgetType.budget && !isReadonly && budgetPeriodStatus === Status.Draft;
  const dragDropRef = canDrag ? dragRef(dropRef(reference)) : null;
  const opacity = canDrag && isDragging ? 0 : 1;
  const isProjectSupplement = props.category.name === "Project Supplement";

  return (
    <div ref={dragDropRef as any} className={`category-row-cell border-bottom-left sidebar-element justify-content-between subcategory-header-background-color padding-right-xs ${canDrag && "dragable"}`} style={{ opacity: opacity }} onMouseEnter={()=>setHover(true)} onMouseLeave={()=>setHover(false)}>
      <div className={classNames(isReadonly ? "subcategory-header-readOnly-mode" : "subcategory-header-write-mode", "d-flex subcategory-header padding-xs align-items-center")}>
          {canDrag && <Icon icon="sort" extraClasses={classNames("reorder-icon", "margin-right-s", hover ? "":"hide-element","action-icon","hoverItem" )} />}
        {isOverflow ?
          <Tooltip message={props.subcategory.name} placement={"top"} ref={textElementRef}>
            <h5 ref={textElementRef} className={classNames(isMultiline ? "":"single-line-sidebar-header-style")}>{listNumber} {props.subcategory.name}</h5>
          </Tooltip>
          :
          <h5 ref={textElementRef} className={classNames(isMultiline ? "":"single-line-sidebar-header-style")}>{listNumber} {props.subcategory.name}</h5>
        }
      </div>
      <div className="subcategory-actions align-items-center">
        {!isReadonly && !isProjectSupplement && renderDelete(hover)}
          {renderScopenote(tags?.filter(t => t.subcategoryIds.includes(props.subcategory.id)))}
      </div>
    </div>
  );
}

export default SubcategoryHeader;

const confirmDelete = (okCallback: () => void) => {
  const hideAndDeleteModal = () => {
    modal.hide();

    setTimeout(() => {
      modal.delete();
    }, 500);
  }

  const modal = new ConfirmationModalModule({
    title: Localizer.areYouSure(),
    body: Localizer.deleteSubcategoryBodyText(),
    okButtonText: Localizer.yesDelete(),
    cancelButtonText: Localizer.cancel(),
    deleteOnHide: true,
    modalType: 2,
    okCallback: () => {
      okCallback();
      hideAndDeleteModal();
    },
    cancelCallback: hideAndDeleteModal
  })

  modal.show();
}
