import {useLocation, useNavigate} from 'react-router-dom';
import { Routes } from './routes';
import { useAuth } from './auth';
import {PageWrapper} from "../../layout/pageWrapper/pageWrapper";
import { useEffect } from 'react';

export function PrivateRoute({children}: {[p: string]: any, children: any}): JSX.Element {
    const navigate = useNavigate();
    const location = useLocation();
    const { user, isLoadingUser } = useAuth();
    const authenticated = user && user.authenticated;
    
    useEffect(() => {
        if(!authenticated && !isLoadingUser) navigate(Routes.LoggedOut.path, {state: { from: location }});
    }, [authenticated, navigate, location, isLoadingUser]);
    
    return (
        isLoadingUser ? <></> : <PageWrapper children={children} />        
    );
}
