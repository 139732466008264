import {
    BrowserRouter,
    Routes,
    Route} from "react-router-dom";
import { DashboardPage} from "pages/dashboard/dashboard";
import { PrivateRoute } from "./privateRoute";
import { Routes as mainRoutes } from "./routes";
import { ErrorBoundary } from "infrastructure/errors/errorBoundary";
import { LogoutPage } from "pages/logoutPage/logoutPage";
import { AuthPage } from "pages/authPage/authPage";
import { NotFoundPage } from "infrastructure/errors/notFoundPage";
import { ProvideAuth } from "./auth";

export default function MainRouting() {
    return (
        <BrowserRouter>
            <ProvideAuth>
                <ErrorBoundary>
                    <Routes>                        
                        <Route index path={mainRoutes.Index.path} element={<PrivateRoute children={<DashboardPage />} />} />                        
                        <Route path={mainRoutes.Auth.path} element={<AuthPage />} />
                        <Route path={mainRoutes.LoggedOut.path} element={<LogoutPage />} />
                        <Route path="*" element={<NotFoundPage />} />                                                
                    </Routes>
                </ErrorBoundary>
            </ProvideAuth>
        </BrowserRouter>
    );
}
