import sprite from 'content/icons/sprite.symbol.svg'
import { ReactComponent as Logo } from 'assets/images/logo.svg';
import { ActionButtons } from "../../core/actionButtons/actionButtons";
import { StatusPill } from "../titleBar/Components/statusPill";
import Icon from "../../core/icon/icon";
import { Localizer } from "../../infrastructure/localization/localizer";
import { Tooltip } from "../../core/tooltip/tooltip";
import { useMediaQuery } from "react-responsive";

interface ITopMenuProps {
	showActionButtons: boolean;
	projectTitle?: string;
	status?: string;
	instructions?: string;
	budgetPeriod_Currency?: string;
	projectSupplementEnabled: boolean;
	supplementChoiceEnabled: boolean;
}

export const TopMenu = (props: ITopMenuProps) => {
	const isSmallScreen = useMediaQuery({ query: '(min-width: 1565px)' });
	const projectTitle = props.projectTitle;

	return (
		<nav className="navbar navbar-default navbar-expand-md fixed-top">
			<button className="navbar-toggler ml-auto" type="button" data-toggle="collapse"
				data-target="#navbar-responsive-collapse" aria-controls="navbar-responsive-collapse"
				aria-expanded="false" aria-label="Toggle navigation">
				<svg className="embla-icon" aria-hidden="true" version="1.1" xmlns="http://www.w3.org/2000/svg">
					<use href={sprite + "#menu"} />
				</svg>
			</button>
			<div className="collapse navbar-collapse" id="navbar-responsive-collapse">
				<div className="logo-container margin-right-m margin-left-m">
					<Logo />
				</div>
				{props.showActionButtons &&
					<>
						<div className="margin-right-s project-title-container">
							{projectTitle &&
								<Tooltip message={projectTitle}>
									<h3 className={"project-title margin-right-m"}>{projectTitle}</h3>
								</Tooltip>
							}

							{props.status &&
								<div className="title-bar-status">
									<StatusPill text={props.status} />
								</div>
							}

							<div className="margin-right-s margin-left-s">
								<Tooltip message={props.instructions ?? ""} placement={"top"}>
									<div className={"instructions-container"}>
										<Icon icon={"info"} />
										<span className="instruction-text"> {isSmallScreen ? Localizer.instructions() : ""}</span>
									</div>
								</Tooltip>
							</div>
						</div>

						<div className="action-buttons-container">
							{props.projectTitle &&
								<ActionButtons projectTitle={props.projectTitle} budgetPeriod_Currency={props.budgetPeriod_Currency == null ? "DKK" : props.budgetPeriod_Currency} supplementChoiceEnabled={props.supplementChoiceEnabled} projectSupplementEnabled={props.projectSupplementEnabled} />
							}
						</div>
					</>
				}
			</div>
		</nav>
	);
}
